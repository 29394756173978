
export interface SoporteG {
    IdSoporte: String,
    Descripcion: String,
    Latitud: String,
    Longitud: String,
    IdTipo: String,
    Tipo: String,
    FechaCreacion: any,
    FechaModificacion: any,
    Eliminado:number,
    IdMunicipio: String,
    Entorno:String,
    Titulo:String,
    estadoDialogo:String,
    Modificar:boolean,
    DatosOriginales:any,
    IdCalle:String,
    calle:String,
    Foto:String,
    totalRegistros?: number,
    fileToUpload?: File,
    isReloadData?: boolean,
    isChangeFile?: boolean
}
export interface TipoSoporte {
    IdTipo: any,
    Nombre: any
}
export interface Soporte {
    IdSoporte: any,
    Descripcion: any
}

export interface MapaVisor2 {
    idSoporte: any,
    id_camara: any,
    Latitud: any,
    Longitud: any,
    Descripcion: any,
    IdMunicipio: any,
    Ip: any,
    Altura: any,
    GradosOrientacion: any,
    url: string;
    EntityType: any,
    geometry: any,
    properties: any,
    type: any,
  }
  
  export interface MapaVisor {
    Latitud: string,
    Longitud: string,
    Descripcion: string,
    Cantidad_Camaras: number,
    camaras: any,
    Calle: string
  }
