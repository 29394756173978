import { FormControl } from "@angular/forms";

export interface ConfigurationTourismPanel {
    id_configuracion_panel_turismo: number;
    id_municipio: number;
    veces_dia: number;
    dias_exceso: number;
    veces_mes: number;
    min_tiempo_salida: number;
    fecha_alta: string;
    fecha_modificacion: string | null;
    fecha_instalacion: string | null;
    fecha_ultima_ejecucion: string | null;
    id_usuario: number | null;
    nombre_usuario: string | null;
}

export type OmitDataInConfigurationTourismPanel = Omit<
    ConfigurationTourismPanel, 'fecha_alta' | 'fecha_modificacion' | 'fecha_ultima_ejecucion' | 'id_usuario' | 'nombre_usuario'
>;

export type ConfigurationTourismPanelForm = {
    [field in keyof Pick<ConfigurationTourismPanel, 'id_municipio' | 'veces_dia' | 'dias_exceso' | 'veces_mes' | 'min_tiempo_salida' | 'fecha_instalacion'>]
    : FormControl<ConfigurationTourismPanel[field] | null>;
}

export interface GroupCamerasInConfigurationTourismPanel {
    nombre: string;
    id_camara_entrada: number;
    id_camara_salida: number;
    id_configuracion_panel_turismo?: number;
    nombre_camara_entrada?: string;
    nombre_camara_salida?: string;
    id_grupo_camara?: number;
}

export interface processedTourismPanelData {
    pais: string;
    matricula: number;
    id_camara: string;
    nombre: string;
    Fecha_imagen_utc: string;
    Fecha_imagen_local: string;
    tipo: string;
    pegatina: string;
}