import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FsessionService } from 'src/app/servicios/session/fsession.service';
import { ValidRoles } from 'src/app/utils/directives/validateRoles/validate-roles.directive';

@Injectable({
  providedIn: 'root'
})
export class HasRoleGuard implements CanLoad {
  constructor( private fse: FsessionService, private router: Router){}

  /**
   * Funcion para implementar un canLoad en las rutas
   * @param route Ruta a la que se le efectua el canload
   * @returns 
   */
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    let modulos = this.fse.optenerValor('paquetes');
    const idRolUser = +this.fse.optenerValor('dato40')
    if(modulos){
      modulos = modulos.split(',');
      let hasAccess = modulos.includes(route.data.moduleId);

      let isSuperAdmin = Number(idRolUser) == ValidRoles.SUPER_ADMINISTRADOR;

      console.log(route.data.moduleId === '8', isSuperAdmin)
      // Si es el módulo Developer y el usuario es SuperAdmin se salta el permiso por Municipio
      if (route.data.moduleId === '8' && isSuperAdmin) {
        return true;
      }

      if (!hasAccess) {
        this.router.navigate(['/']);
      }
      
      return hasAccess;
    }
    this.router.navigateByUrl('/');
    return false;
  }

}
