import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';
import { catchError, retryWhen, scan, delay } from 'rxjs/operators';

export class RetryTranslateLoaderService extends TranslateHttpLoader {
  constructor(http: HttpClient, prefix: string, suffix: string) {
    super(http, prefix, suffix);
  }

  getTranslation(lang: string): Observable<any> {
    return super.getTranslation(lang).pipe(
      retryWhen(errors => 
        errors.pipe(
          scan((attempt) => {
            console.warn(`Error en el intento #${attempt + 1} para el idioma: ${lang}`);
            if (attempt >= 2) {
              throw errors; // Lanza el error después de 3 intentos fallidos
            }
            return attempt + 1;
          }, 0),
          delay(1000) // Retraso opcional entre intentos
        )
      ),
      catchError(() => {
        return of({});
      })
    );
  }
}
