import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  buttonType, 
  CenterInputSchemaTable, 
  HandleActionSchemaTable, 
  LeftRightButtonSchemaTable 
} from 'src/app/models';

@Component({
  selector: 'app-generic-table-header',
  templateUrl: './generic-table-header.component.html',
  styleUrls: ['./generic-table-header.component.scss']
})
export class GenericTableHeaderComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<any>();

  /** Botones que se mostraran a la izquierda */
  @Input() leftButtons: LeftRightButtonSchemaTable[] = [];
  /** Botones que se mostraran a la derecha */
  @Input() rightButtons: LeftRightButtonSchemaTable[] = [];
  /** Inputs que se mostraran en el centro */
  @Input() centerInputs: CenterInputSchemaTable[] = [];

  /**
   * Identifica que accion se origino y envia al componente padre esa notificacion
   */
  @Output() handleAction = new EventEmitter<HandleActionSchemaTable>();

  buttonTypeBasic: buttonType = 'basic';
  buttonTypeRaised: buttonType = 'raised';
  buttonTypeStroked: buttonType = 'stroked';
  buttonTypeFlat: buttonType = 'flat';
  buttonTypeIcon: buttonType = 'icon';
  buttonTypeFab: buttonType = 'fab';
  buttonTypeMiniFab: buttonType = 'mini-fab';
  buttonTypeSlideToggle: buttonType = 'slide-toggle';
  isValidLeftButtons = false;
  isValidRightButtons = false;
  isValidCenterInputs = false;

  /** Controladores de los inputs (se puedo recibir uno o ninguno y maximo tres) */
  formControlInputOne = new FormControl();
  formControlInputTwo = new FormControl();
  formControlInputThree = new FormControl();

  constructor() { }

  ngOnInit(): void {

    if ( this.leftButtons && this.leftButtons.length <= 3 ) {
      this.isValidLeftButtons = true;
    } else {
      console.error('Excediste la cantidad de elementos a mostrar: "leftButtons"');
    }

    if ( this.rightButtons && this.rightButtons.length <= 3 ) {
      this.isValidRightButtons = true;
    } else {
      console.error('Excediste la cantidad de elementos a mostrar: "rightButtons"');
    }

    if ( this.centerInputs && this.centerInputs.length <= 3 ) {
      this.isValidCenterInputs = true;

      // Se adiciona validaciones, siempre que vengan
      for ( let i = 0; i < this.centerInputs.length; i++ ) {
        if ( this.centerInputs[i].validators ) {
          if ( i === 0 ) this.formControlInputOne.setValidators(this.centerInputs[i].validators);
          if ( i === 1 ) this.formControlInputTwo.setValidators(this.centerInputs[i].validators);
          if ( i === 2 ) this.formControlInputThree.setValidators(this.centerInputs[i].validators);
        }
      }
    } else {
      console.error('Excediste la cantidad de elementos a mostrar: "centerInputs"');
    }

    // Escucha cambios en el primer input
    this.formControlInputOne.valueChanges
    .pipe( takeUntil( this.destroyed$ ) )
    .subscribe(value => this.emitHandleAction( this.centerInputs[0].name, {...this.centerInputs[0], value} ));

    // Escucha cambios en el segundo input
    this.formControlInputTwo.valueChanges
    .pipe( takeUntil( this.destroyed$ ) )
    .subscribe(value => this.emitHandleAction( this.centerInputs[1].name, {...this.centerInputs[0], value} ));

    // Escucha cambios en el tercer input
    this.formControlInputThree.valueChanges
    .pipe( takeUntil( this.destroyed$ ) )
    .subscribe(value => this.emitHandleAction( this.centerInputs[2].name, {...this.centerInputs[0], value} ));

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Emite la accion al componente padre
   * @param actionName nombre de la accion (nombre del elemento)
   * @param data datos que se envian relacionados con el origen del evento (acciones)
   */
  emitHandleAction( actionName: string, data: any ) {

    this.handleAction.emit({
      name: actionName,
      pressed: true,
      data
    });

  }

}
