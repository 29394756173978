import { Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, NgZone  } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { findIana } from 'windows-iana';
import { MatSelectionList } from '@angular/material/list';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';

import { PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Subject, timer } from 'rxjs';
import { debounceTime, filter, first, startWith, takeUntil } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

import { DatabaseService } from '../../servicios/database/database.service';
import { DatosUsuario, Municipio } from 'src/app/models';
import { PerfilUsarioComponent } from 'src/app/dialogs/perfil-usario/perfil-usario.component';
import { ConfMunicipioComponent } from 'src/app/dialogs/conf-municipio/conf-municipio.component';
import { FsessionService } from '../../servicios/session/fsession.service';
import { configuracion } from 'src/configuracion';
import { environment } from 'src/environments/environment';
import { InicializadorAppsComponent } from 'src/app/dialogs/inicializador-apps/inicializador-apps.component';
import { ListenDOMChange } from 'src/app/servicios/listen-DOM-change/listen-DOM-change.service';
import { AcercaDeComponent } from 'src/app/dialogs/acerca-de/acerca-de.component';
import { NotificationService } from 'src/app/servicios/notification/notification.service';
import { LoaderService } from '../loader-spinner/loader.service';
import { MessageService } from 'src/app/servicios/message/message.service';
import { UpdateService } from 'src/app/servicios/update/update.service';

export interface ModeloAlerta {
  IdAlerta: number;
  Origen: String;
  Mesaje: String;
  Contador: String;
  FechaUltimaIncidencia: String;
  FechaAlerta: String;
  IdMunicipio: number;
  Municipio: String;
  Ip: String;
}

export interface MenuHeader {
  type: number;
  routerLink: string;
  name: string;
  permission: string;
  hasAccess?: boolean;
}

export interface SubheaderRoute {
  id_rol: number;
  nombre: string;
  id_ruta: number;
  ruta: string;
  tipo: number;
  fecha_creacion: string;
  activo: boolean;
  lectura_escritura: boolean;
  descripcion?: string;
  hasAccess?: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  
  @ViewChild('manualUrl', { static: false }) manualAnchor: ElementRef;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild(MatSelectionList, { static: false }) matSelectionList: MatSelectionList;

  @ViewChild(AcercaDeComponent, { static: false }) acercaDe: AcercaDeComponent;

  private destroyed$ = new Subject();
  nameUser: string;
  language: string;
  mail: string;
  rol: string = this.fse.optenerValor('dato40');
  ListMun: Municipio[];
  ListDataUSer: DatosUsuario;
  Munselect: Municipio;
  MunselectSaved: Municipio;

  ListAlert: ModeloAlerta[];
  RoutesAvaliable;
  ChildRoutes = [];
  headerSelect = 1;
  isMobile: boolean;
  enableAdm = new FormControl(false);

  headers: MenuHeader[] = [
    { routerLink: 'traffic', type: 1, name: 'Trafico', permission: this.fse.optenerValor('paquetes'), hasAccess: false},
    { routerLink: 'management', type: 2, name: 'Gestion', permission: this.fse.optenerValor('paquetes'), hasAccess: false },
    { routerLink: 'telemetry', type: 4, name: 'Telemetría', permission: this.fse.optenerValor('paquetes'), hasAccess: false},
    { routerLink: 'solar-charger', type: 5,  name: 'telemetria-cargador-solar', permission: this.fse.optenerValor('paquetes'), hasAccess: false },
    { routerLink: 'weather', type: 6, name: 'Clima', permission: this.fse.optenerValor('paquetes'), hasAccess: false},
  ];

  text = '';
  results = [];
  citySearchControl = new FormControl();
  searchedCity = '';
  showAdmin = false;
  appState: boolean;
  showOrHide: boolean;

  supportedLangs = ['es', 'en', 'fr', 'ca', 'pv'];
  translateKeys = [
    'establecer-como-predeterminado',
    'idioma-se-usara-como-predeterminado',
    'no-guardar',
    'si-usar-predeterminado',
    'mensaje_confirmacion',
    'error_contacto',
  ]
  variables: any;

  isMultipleSelection: boolean = false;
  selectedMunicipios: any[] = [];
  maxMunicipiosSelected = 50;
  showCounterMunicipioVirtual = false;
  municipioUserSlider: boolean;

  @ViewChild('menu') menu: MatMenu;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger; 

  constructor( 
    private translate: TranslateService,
    public fse: FsessionService,
    private db: DatabaseService,
    private dialog: MatDialog,
    private router: Router,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private _listenDOMChange: ListenDOMChange,
    private _notificationService: NotificationService,
    private _loaderService: LoaderService,
    private _messageService: MessageService,
    private _updateService: UpdateService,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef
  ) {
    this.validateDialogterminosENS();
    
    this.language = this.fse.optenerValor('language');
    this.nameUser = fse.optenerValor('dato2');
    this.mail = fse.optenerValor('dato3');
    this.getCitys();
    this.checkRoute();
    this.setLanguage();
    this.translate.onLangChange
      .pipe(startWith(this.language))
      .subscribe(() => {
        this.translate.get(this.translateKeys)
          .subscribe((variables) => this.variables = variables);
      })
      this.setHeaders();

    this.municipioUserSlider = this.fse.optenerValor('multimunicipio') === 'true';
  }

  ngOnInit(): void {

    this.detectNavigationWithBrowserArrows();

    if( this.router.url === "/" ) {
      this.router.navigate([ this.fse.optenerValor('defaulRoute')]).then(()=>{
        this.checkRoute();
      });
    }

    /** Escuchamos la propiedad isMobile que devuelve un boolean que determina si estamos en un dispositivo con dimensiones moviles */
    this._listenDOMChange.isMobile
    .pipe( takeUntil( this.destroyed$ ) )
    .subscribe( isMobile => this.isMobile = isMobile );

    this.citySearchControl.valueChanges
    .pipe(
      startWith(''),
      debounceTime(200)
    )
    .subscribe(
      (value: string) => {
        if(value && value.length >= 2) {
          this.results = this.ListMun.filter(mun =>
            mun.Nombre.toLowerCase().includes(
              value.toLowerCase()
            ));
        } else {
          this.results = this.ListMun;
        }
      }
    )

    this.fse.getAppState().subscribe((res)=>{
      this.appState = res;
    });

    const showMultimunicipio = this.fse.optenerValor('showMultimunicipio') === 'true';
    if (showMultimunicipio) {
      this.selectedMunicipios = this.fse
        .optenerValor('municipiosVirtuales')
        .split(',')
        .map((id) => parseInt(id.trim(), 10));
      this.toggleAllSelection();
    }

    // Se guarda la ruta actual
    this.fse.agregarActualizar('recharge', window.location.pathname);

  }

    
  /**
   * Verifica si es la primera vez que se accede a la plataforma y asigna el idioma por defecto
   * de lo contrario usa el idioma que se encuentra en la caché
   */
  setLanguage() {
    const cacheLang = this.fse.optenerValor('language');

    if (cacheLang === false || !this.supportedLangs.includes(cacheLang)) {
      this.translate.setDefaultLang("es");
      if( this.supportedLangs.indexOf(this.translate.getBrowserLang())!=-1) {
        this.changeLanguage(this.translate.getBrowserLang());
      } else {
        this.changeLanguage("es");
      }
    } else {
      this.translate.setDefaultLang(cacheLang);
      this.changeLanguage(cacheLang)
    }
  }

  ngAfterViewInit(): void {
    this.presetRelease();
  }

  presetRelease() {
    const newUpdateMessage = this._updateService.shouldShowMessage();;
    
    if (newUpdateMessage && Boolean(newUpdateMessage)) {
      this.acercaDe.getCurrentVersionChanges();
    }

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Detecta la navegacion por medio de las flechas del navegador o las del mouse (en caso de que las tenga) */
  detectNavigationWithBrowserArrows() {

    this.router.events
    .pipe( filter( event => event instanceof NavigationStart || event instanceof NavigationEnd ) )
    .subscribe((event: NavigationStart) => {

      /** Al navegar se guarda la ruta para usarse al recargar */
      if (event instanceof NavigationEnd) {
        if ( this.msalService?.instance?.getAllAccounts().length > 0 ) {
          this.fse.agregarActualizar('recharge', this.router.url);
        }
      }

      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          if ( event.url === '/' || event.url === '' || window.location.href.includes('auth#state') ) {
              this.router.navigate(['/' + this.router.url]);
          } else if ( event.url.includes('/traffic') && this.headerSelect !== 1 ) {
              this.fse.agregarActualizar('recharge', event.url);
              this.changeRouteParent( 1 );
          } else if ( event.url.includes('/management') && this.headerSelect !== 2 ) {
              this.fse.agregarActualizar('recharge', event.url);
              this.changeRouteParent( 2 );
          } else if ( event.url.includes('/admin') && this.headerSelect !== 3 ) {
              this.fse.agregarActualizar('recharge', event.url);
              this.changeRouteParent( 3 );
          } else if ( event.url.includes('/telemetry') && this.headerSelect !== 4 ) {
              this.fse.agregarActualizar('recharge', event.url);
              this.changeRouteParent( 4 );  
          } else if ( event.url.includes('/solar-charger') && this.headerSelect !== 5 ) {
              this.fse.agregarActualizar('recharge', event.url);
              this.changeRouteParent( 5 );
          } else if ( event.url.includes('/weather') && this.headerSelect !== 6 ) {
              this.fse.agregarActualizar('recharge', event.url);
              this.changeRouteParent( 6 );
          } else if ( event.url.includes('/developer') && this.headerSelect !== 8 ) {
            this.fse.agregarActualizar('recharge', event.url);
            this.changeRouteParent( 8 );
        }
          
        }
      }
    });

  }

  /**
   * Refrescar lista de municipios
   */
  refreshCityList() {
    this.results = this.ListMun;
  }

  /**
   * Inicializar lista municipios
   */
  initCity() {
    this.results = this.ListMun;
  }

  /**
   * Busqueda de municipios
   */
  search(value) {
    if (value.length >= 2) {
      this.results = [];
      for (var i = 0; i < this.ListMun.length; i++) {
        if (
          this.ListMun[i]['Nombre']
            .toLowerCase()
            .indexOf(value.toLowerCase()) != -1
        ) {
          this.results.push(this.ListMun[i]);
        }
      }
    } else {
      this.results = this.ListMun;
    }
  }

  /**
   * Cambiar municipio
   * @param event parametro de busqueda
   */
  onChangeEvent(event: any) {
    this.search(event.target.value);
  }

  /**
   * Configura los módulos que puede ver el municipio
   */
  setHeaders() {
    const modules = this.fse.optenerValor('paquetes');
    let routePermissions = this.fse.optenerValor('dato43');

    this.headers = [
      { routerLink: 'traffic', type: 1, name: 'Trafico', permission: this.fse.optenerValor('paquetes') },
      { routerLink: 'management', type: 2, name: 'Gestion', permission: this.fse.optenerValor('paquetes')},
      { routerLink: 'telemetry', type: 4, name: 'Telemetría', permission: this.fse.optenerValor('paquetes')},
      { routerLink: 'solar-charger', type: 5,  name: 'telemetria-cargador-solar', permission: this.fse.optenerValor('paquetes') },
      { routerLink: 'weather', type: 6, name: 'Clima', permission: this.fse.optenerValor('paquetes')},
    ];

    // Precarga las rutas del módulo
    this.headers.forEach((header) => {
      import(`../../modules/${header.routerLink}/${header.routerLink}-routing.module`).then(x => {
        const availableRoutes = x.routes.filter(route => route.path != '' && routePermissions.includes(route.data.permission.toString()));
        header.hasAccess = modules.split(',').includes(header.type.toString()) && availableRoutes.length >= 1;
      });

    })
  }

  /**
   * Activa el header principal y los subheader dependiendo de la ruta actual
   */
  checkRoute() {
    if(this.router.url === '/') return

    if (this.router.url.includes('traffic')) {
      this.headerSelect = 1;
    } else if (this.router.url.includes('management')) {
      this.headerSelect = 2;
    } else if (this.router.url.includes('admin')) {
      this.headerSelect = 3;
    } else if (this.router.url.includes('solar-charger')) {
      this.headerSelect = 5;
    } else if (this.router.url.includes('telemetry')) {
      this.headerSelect = 4;
    } else if (this.router.url.includes('developer')) {
      this.headerSelect = 8;
    } else {
      this.headerSelect = 6;
    }
    this.changeRouteParent(this.headerSelect);
  }

  /**
   * Trae los municipios disponibles
   */

  getCitys() {

    this.db.Municipios('').subscribe(({municipios, userInfo }:any) => {

      this.ListMun = municipios.filter((mun: any) => mun.IdMunicipio !== 329);
      this.ListDataUSer = userInfo;
      
      this.fse.agregarActualizar('dato1', this.ListDataUSer.accessToken);
      this.fse.agregarActualizar('multimunicipio', `${this.ListDataUSer.busquedaMultimunicipio ?? false}`);
      this.fse.agregarActualizar('ValoresDegradadoMapa', `${this.ListDataUSer.ValoresDegradadoMapa ?? false}`);

      this.fse.setUserCities(municipios);

      if(!this.fse.optenerValor('dato2')) {
        this.getDataUser();
      }
      this.ListMun.forEach(mun => {
        if (mun.IdMunicipio == Number(this.fse.optenerValor('dato4'))) {
          this.Munselect = mun;

          if(!this.isMultipleSelection) {
            this.fse.agregarActualizar('defaultBeforeMuniVirtual', JSON.stringify(mun));
            this.MunselectSaved = mun;
          }

          this.fse.agregarActualizar('dato5', this.Munselect.Nombre);
          this.fse.agregarActualizar('dato7', this.Munselect.latitud);
          this.fse.agregarActualizar('dato8', this.Munselect.longitud);
          this.fse.agregarActualizar('dato36', this.Munselect.Usuario);
          this.fse.agregarActualizar('dato37', this.Munselect.Clave);
          this.fse.agregarActualizar('dato41', this.Munselect.zona_horaria);
          this.fse.agregarActualizar('utc_offset', this.Munselect.utc_offset);
          
          if (this.Munselect.ParametrosDegradado) {
            this.fse.agregarActualizar('ValoresDegradadoMapa', this.Munselect.ParametrosDegradado);
          }

          this.fse.agregarActualizar('zona_js', findIana(this.Munselect.zona_horaria)[0]);
        }
      });
      this.fse.setAppState(true);

      this.listenNotificationService(this.Munselect.IdMunicipio);

      this.municipioUserSlider = this.fse.optenerValor('multimunicipio') === 'true';
    });

  }

  /** Establecer y escuchar comunicacion via a sockets con la api de notificaciones */
  listenNotificationService( idMunicipality: number ) {
    if (!this.isMultipleSelection && idMunicipality){
      this._notificationService.listenNotification(idMunicipality);
    }
  }

  /**
   * Obtener datos del usuario
   * @returns 
   */
  getDataUser(){
    this.nameUser = this.ListDataUSer.dato2;
    this.mail = this.ListDataUSer.dato3;

    this.fse.agregarActualizar('configuracion', this.ListDataUSer.Configuracion);

    this.fse.agregarActualizar('dato2', this.ListDataUSer.dato2);
    this.fse.agregarActualizar('dato3',this.ListDataUSer.dato3 + '');

    this.fse.agregarActualizar('dato38', ''+ this.ListDataUSer.CantCamaras);
    this.fse.agregarActualizar('dato39',''+ this.ListDataUSer.CantDescargas);
    this.fse.agregarActualizar('manualUsuario',''+ this.ListDataUSer.ManualUsuario);

    this.fse.agregarActualizar('dato42', this.ListDataUSer.nuevo +'');
    this.fse.agregarActualizar('dato4', this.ListDataUSer.munDefault.toString());
    this.fse.agregarActualizar('dato7', this.ListDataUSer.lat);
    this.fse.agregarActualizar('dato8', this.ListDataUSer.lng);
    this.fse.agregarActualizar('configMatr', this.ListDataUSer.configuracion_matr);

    return true;
  }

  /**
   * Abre el dialogo de perfil de usuario que permite cambiar la informacion del perfil o contraseña
   */
  OpenProfile() {
    let userInfo = {
      name: this.fse.optenerValor('dato2'),
      mail: this.fse.optenerValor('dato3'),
      username: this.fse.optenerValor('dato44'),
    };
    this.dialog.open(PerfilUsarioComponent, {
      width: this.isMobile ? '100%' : '40%',
      autoFocus: false,
      data: userInfo,
    });
  }

  /**
   * Muestra la version actual de la plataforma( version establecida en package.json)
   */
  showAbout() {
    this.acercaDe.aboutSwal.fire();
  }

  /**
   * Cierra la sesion actual del usuario
   */
  closeSession() {
    this.fse.closeSession();
    this.msalService.logoutRedirect().subscribe(() => {
      this.router.navigate(["/"]);
    });
  }

  /**
   * Abre el diaglo ConfMunicipioComponent para visualizar la informacion del municipio seleccionado
   */
  openDialgCity() {
    this.dialog.open(ConfMunicipioComponent, {
      width: this.isMobile ? '100%' : '40%',
      autoFocus: false,
      data: { IdMunicipio: this.Munselect.IdMunicipio },
    });
  }

  selectLanguage(language: string) {
    this._messageService.dialogMessage('question', this.variables['establecer-como-predeterminado'], { 
      text: this.variables['idioma-se-usara-como-predeterminado'],
      denyButtonText: this.variables['no-guardar'],
      confirmButtonText: this.variables['si-usar-predeterminado'],
      showDenyButton: true,
      heightAuto: false,
      preConfirm: () => {
        this._loaderService.showSpinner();
        this.db.ConfigurarIdiomaDefault(language)
        .subscribe({
          next: () => {
            this.changeLanguage(language)
            setTimeout(() => {
              this._loaderService.hideSpinner();
              this._messageService.SwalMessage('success', this.variables['mensaje_confirmacion']);
            }, 500)
          },
          error: () => {
            this._loaderService.hideSpinner();
            this._messageService.SwalMessage('error', this.variables['error_contacto']);
          }
        })
      },
      preDeny: () => {
        this.changeLanguage(language);
      }
     })
  }

  /**
   * Cambia el lenguaje de la plataforma
   * @param language Codigo del lenguaje
   */
  changeLanguage(language: string) {
    this.translate.use(language);
    this.fse.setLanguage(language);
    this.language = language;
  }

  /**
   * Cambia la ruta de la plataforma segun el valor de urlRouting, si executeChangeRoutes es true, tambien cambia las rutas hijas.
   * @param urlRouting El valor de la ruta que se quiere cambiar, los valores posibles son:
   * - 1: /traffic
   * - 2: /management
   * - 3: /admin
   * - 4: /telemetry
   * - 5: /solar-charger
   * - 6: /weather
   * - 8: /developer
   * @param executeChangeRoutes Si es true, se cambian las rutas hijas, si es false, solo se cambia la ruta principal. Por defecto es true.
   * @returns Un promise que se resuelve con las rutas hijas que se han configurado.
   */
  changeRouteParent(urlRouting: number, executeChangeRoutes = true): Promise<any> {
    let permission = this.fse.optenerValor('dato43');

    permission = permission.split(',');

    return new Promise((resolve) => {
      switch(urlRouting){
        case 1:
          this.headerSelect = 1;
          import('../../modules/traffic/traffic-routing.module').then(x => {
            this.ChildRoutes = x.routes.filter(route => route.path != '' && permission.includes(route.data.permission.toString()));
            if ( executeChangeRoutes ) this.changeRoutes('traffic', this.ChildRoutes);
            resolve(this.ChildRoutes);
          });
          break;
        case 2:
          this.headerSelect = 2;
          import('../../modules/management/management-routing.module').then(x => {
            this.ChildRoutes = x.routes.filter(route => route.path != '' && permission.includes(route.data.permission.toString()));
            if ( executeChangeRoutes ) this.changeRoutes('management', this.ChildRoutes);
            resolve(this.ChildRoutes);
          });
          break;
        case 3:
          this.headerSelect = 3;
          import('../../modules/administrate/administrate-routing.module').then(x => {
            this.ChildRoutes = x.routes.filter(route => route.path != '' && permission.includes(route.data.permission.toString()));
            if ( executeChangeRoutes ) this.changeRoutes('admin', this.ChildRoutes);
            resolve(this.ChildRoutes);
          });
          break;
        case 4:
          this.headerSelect = 4;
          import('../../modules/telemetry/telemetry-routing.module').then(x => {
            this.ChildRoutes = x.routes.filter(route => route.path != '' && permission.includes(route.data.permission.toString()));
            if ( executeChangeRoutes ) this.changeRoutes('telemetry', this.ChildRoutes);
            resolve(this.ChildRoutes);
          });
          break;
        case 5:
          this.headerSelect = 5;
          import('../../modules/solar-charger/solar-charger-routing.module').then(x => {
            this.ChildRoutes = x.routes.filter(route => route.path != '' && permission.includes(route.data.permission.toString()));
            if ( executeChangeRoutes ) this.changeRoutes('solar-charger', this.ChildRoutes);
            resolve(this.ChildRoutes);
          });
          break;
        case 6:
          this.headerSelect = 6;
          import('../../modules/weather/weather-routing.module').then(x => {
            this.ChildRoutes = x.routes.filter(route => route.path != '' && permission.includes(route.data.permission.toString()));
            if ( executeChangeRoutes ) this.changeRoutes('weather', this.ChildRoutes);
            resolve(this.ChildRoutes);
          });
          break;
        case 8:
          this.headerSelect = 8;
          import('../../modules/developer/developer-routing.module').then(x => {
            this.ChildRoutes = x.routes.filter(route => route.path != '' && permission.includes(route.data.permission.toString()));
            if ( executeChangeRoutes ) this.changeRoutes('developer', this.ChildRoutes);
            resolve(this.ChildRoutes);
          });
        break;
        default:
          resolve(undefined);
          break;
      }
    });
  }
  
  /**
   * Obtener rutas
   * @param routeChild parametro de la ruta
   * @returns 
   */
  getRoute(routeChild: string) {
    switch (this.headerSelect) {
      case 1:
        return '/traffic/' + routeChild;
      case 2:
        return '/management/' + routeChild;
      case 4:
        return '/telemetry/' + routeChild;
      case 5:
        return '/solar-charger/' + routeChild;
      case 6:
        return '/weather/' + routeChild;
      case 8:
        return '/developer/' + routeChild;
      default:
        return '/admin/' + routeChild;
    }
  }

  /**
   * Manual de usuario
   */
  downloadUserManual() {
    this.db
      .obtenerUrlManual()
      .pipe(first())
      .subscribe(({ url }: { url: string }) => {
        this.manualAnchor.nativeElement.setAttribute('href', url);
        this.manualAnchor.nativeElement.click();
      });
  }

  /**
   * Activa o desactiva la selección múltiple al seleccionar "Todos".
   */
  toggleAllSelection(): void {
    this.isMultipleSelection = !this.isMultipleSelection;
    this.showCounterMunicipioVirtual = this.isMultipleSelection;

    if (!this.isMultipleSelection) {
        this.selectedMunicipios = [];
        this.fse.agregarActualizar('municipiosVirtuales', '');
        this.fse.agregarActualizar('showMultimunicipio', '');    
        const muni = JSON.parse(this.fse.optenerValor('defaultBeforeMuniVirtual'));
        const municipioSeleccionado = muni ?? this.Munselect;
        this.emitValueCity(municipioSeleccionado);
        this.citySearchControl.reset();
    } else {
      if (this.Munselect && this.Munselect.IdMunicipio) {
        this.selectedMunicipios.push(this.Munselect.IdMunicipio);
        setTimeout(() => {
          this.emitValueCity(this.Munselect);
        }, 500);
      }
    }

    if (this.menuTrigger && this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
      setTimeout(() => {
          this.menuTrigger.openMenu();
      }, 500);
    } 
  }

  /**
   * Selecciona "Todos" los municipios o el municipio por defecto junto con otros hasta el máximo permitido.
   */
  toggleAllSelectionAll(): void {
    if (this.selectedMunicipios.length === this.maxMunicipiosSelected) {
      const muni = JSON.parse(this.fse.optenerValor('isAllSelectionMunicipio') || this.fse.optenerValor('defaultBeforeMuniVirtual'));
      let municipioSeleccionado = muni?.[0] ?? null;
  
      if (municipioSeleccionado && !Array.isArray(municipioSeleccionado)) {
        municipioSeleccionado = this.ListMun.filter(mun => mun.IdMunicipio === municipioSeleccionado);
      }

      if (municipioSeleccionado) {
        this.selectedMunicipios = Array.isArray(municipioSeleccionado) ? municipioSeleccionado.map(mun => mun.IdMunicipio) : [municipioSeleccionado.IdMunicipio];
        this.emitValueCity(municipioSeleccionado[0], true);
      }

    } else {
      const municipiosAdicionales = this.ListMun
        .filter((mun) => !this.selectedMunicipios.includes(mun.IdMunicipio))
        .slice(0, this.maxMunicipiosSelected - this.selectedMunicipios.length);

      this.selectedMunicipios.push(...municipiosAdicionales.map((mun) => mun.IdMunicipio));
      this.emitValueCity(this.Munselect, true);
    }

    if (this.menuTrigger && this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
      setTimeout(() => {
          this.menuTrigger.openMenu();
      }, 500);
    } 
  }

  /**
   * Maneja el clic sobre un municipio en modo sin checkboxes.
   * @param mun Municipio seleccionado.
   */
  onMunicipioClick(mun: Municipio): void {
    if (!this.showCounterMunicipioVirtual) {
      this.emitValueCity(mun);
    }
  }

  /**
   * Envía la actualización del municipio seleccionado a todos los suscriptores, cambio de permisos.
   * @param munSelect Municipio seleccionado.
   */
  emitValueCity(munSelect: Municipio, isAllSelection: boolean = false): void {

    if (this.isMultipleSelection) {
      const index = this.selectedMunicipios.indexOf(munSelect.IdMunicipio);
  
      if (index > -1) {
        if (this.selectedMunicipios.length > 1) {
          if(!isAllSelection) this.selectedMunicipios.splice(index, 1);
        }
      } else if (this.selectedMunicipios.length < this.maxMunicipiosSelected) {
        this.selectedMunicipios.push(munSelect.IdMunicipio);
      } else {
        this.selectedMunicipios = [munSelect.IdMunicipio];
      }

      if (this.selectedMunicipios.length === 1) {
        this.fse.agregarActualizar('isAllSelectionMunicipio', JSON.stringify(this.selectedMunicipios));
      }
    }

    if (!this.showCounterMunicipioVirtual) {
        this.menuTrigger.closeMenu();
    }

    const id = CryptoJS.AES.decrypt(this.fse.optenerValor('dato46'), configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);
    this._loaderService.showSpinner();
    this.appState = false;

    const idMunicipioValidatedMultimunicipio = this.isMultipleSelection ? 329 : munSelect.IdMunicipio;

    this.db.getModulosPermisosRol(id, idMunicipioValidatedMultimunicipio).subscribe(
      (data) => {

        if (this.isMultipleSelection) {
          if (data[0]?.permisos) {
            const permisosArray = data[0].permisos.split(',');
            const permisosFiltrados = permisosArray.filter((permiso: string) =>
              ['1', '2','3', '6', '5', '7',  '46'].includes(permiso.trim())
            );
            data[0].permisos = permisosFiltrados.join(',');
          }
        }

        this.citySearchControl.reset();
        if(!this.isMultipleSelection) {
          this.fse.agregarActualizar('defaultBeforeMuniVirtual', JSON.stringify(munSelect));
          this.MunselectSaved = munSelect;
        }
        this.Munselect = munSelect;

        this.fse.agregarActualizar('dato7', munSelect.latitud);
        this.fse.agregarActualizar('dato8', munSelect.longitud);
        this.fse.setCity(this.Munselect.IdMunicipio.toString());
        this.fse.agregarActualizar('utc_offset', munSelect.utc_offset);
        this.fse.agregarActualizar('dato41', this.Munselect.zona_horaria);
        this.fse.agregarActualizar('zona_js', findIana(this.Munselect.zona_horaria)[0]);
        this.fse.agregarActualizar('dato5', munSelect.Nombre);
        this.fse.agregarActualizar('dato36', munSelect.Usuario);
        this.fse.agregarActualizar('dato37', munSelect.Clave);
        this.fse.agregarActualizar('municipiosVirtuales', this.selectedMunicipios.toString());
        this.fse.agregarActualizar('showMultimunicipio', this.showCounterMunicipioVirtual.toString());

        if (this.Munselect.ParametrosDegradado) {
          this.fse.agregarActualizar('ValoresDegradadoMapa', this.Munselect.ParametrosDegradado);
        } else {
          this.fse.agregarActualizar('ValoresDegradadoMapa', this.ListDataUSer.ValoresDegradadoMapa);
        }

        const id_rol = data[0].id_rol;
        const permisos = data[0].permisos;
        const modulos = data[0].modulos;

        this.fse.agregarActualizar('paquetes', modulos.toString());
        this.fse.agregarActualizar('dato43', permisos.toString());
        this.fse.agregarActualizar('dato40', id_rol.toString());

        this.rol = id_rol.toString();

        this.setRoute();

        this.appState = true;
        
        this.listenNotificationService(this.Munselect.IdMunicipio);

        this._loaderService.hideSpinner();
      },
      () => {
        this.appState = true;
        this._notificationService.closeConnection();
        this._loaderService.hideSpinner();
      }
    );
  }

  /**
   * Validar rutas a las que puede acceder el usuario
   */
  setRoute(){
    this.setHeaders();

    const firstModule = this.headers.find((res)=>{
      let permission = res.permission;
      (permission as any) = permission.toString().split(',').includes(res.type.toString());
      return (permission as any) == true;
    });

    this.changeRouteParent(firstModule.type).then(childRoutes => {

      const permission = this.fse.optenerValor('dato43').split(',');
      const permissionRol = childRoutes.find((res)=>{
        return permission.includes(res.data.permission.toString());
      });
  
      const navigate = `${firstModule.routerLink}/${permissionRol.path}`;
      this.fse.agregarActualizar('defaulRoute', firstModule.routerLink);
       this.router.navigate([navigate]).then(()=>{
        this.checkRoute();
       })
    });
  }

  /**
   * Metodo que realiza correctamente la navegacion cuando se cambia de ruta o cuando se invoca
   * @param firstModule string que representa el nombre de un modulo
   * @param childRoutes arreglo de rutas hijas
   */
  async changeRoutes(firstModule: string, childRoutes: any[]) {

    const permission = this.fse.optenerValor("dato43").split(",");

    const permissionRol = childRoutes.find((res) => {
      return permission.includes(res.data.permission.toString());
    });

    const navigate = permissionRol !== undefined ? `${firstModule}/${permissionRol.path}` : `${firstModule}`;

    const routeSavedSession = this.fse.optenerValor('recharge');

    /**
     * Valida si la propiedad "routeSavedSession" contiene algo
     * Si lo tiene determina a que ruta debe ir y elimina la variable de sesión "recharge"
     * Si esta vacia realiza la navegacion a la ruta por defecto
     */

    if ( routeSavedSession != '' ) {

      routeSavedSession === this.router.url
      ? await this.router.navigate([this.fse.optenerValor('recharge')]) 
      : await this.router.navigateByUrl(window.location.pathname); 
      this.fse.deleteVariable('recharge');
      this.fse.deleteVariable('routeInit');


    } else {

      const routeInit = this.fse.optenerValor( 'routeInit' );
      if ( routeInit ) {

        // comprobamos a que ruta se quiere ir y cambiamos el menu de las subrutas
        if ( routeInit.includes('/management') && this.headerSelect !== 2 ) {
          this.changeRouteParent( 2, false );
        } else if ( routeInit.includes('/admin') && this.headerSelect !== 3 ) {
          this.changeRouteParent( 3, false );
        } else if ( routeInit.includes('/telemetry') && this.headerSelect !== 4 ) {
          this.changeRouteParent( 4, false );
        } else if ( routeInit.includes('/solar-charger') && this.headerSelect !== 5 ) {
          this.changeRouteParent( 5, false );
        } else if ( routeInit.includes('/weather') && this.headerSelect !== 6 ) {
          this.changeRouteParent( 6, false );
        } else if ( routeInit.includes('/developer') && this.headerSelect !== 8 ) {
          this.changeRouteParent( 8, false ); 
        }
        this.router.navigate([ routeInit ]);
        this.fse.deleteVariable('routeInit');

      } else {
        this.router.navigate([ navigate ]);
      }

    }
  }

 /**
 * Redirecciona a la primera ruta disponible
 */
  redirectToFirstRoute() {
    const firstModule = this.headers.find((res)=>{
      let permission = res.permission;
      (permission as any) = permission.toString().split(',').includes(res.type.toString());
      return (permission as any) == true;
    });

    this.changeRouteParent(firstModule.type).then(childRoutes => {

      const permission = this.fse.optenerValor('dato43').split(',');
      const permissionRol = childRoutes.find((res)=>{
        return permission.includes(res.data.permission.toString());
      });
  
      const navigate = `${firstModule.routerLink}/${permissionRol.path}`;

      this.fse.agregarActualizar('defaulRoute', firstModule.routerLink);
        this.router.navigate([navigate]).then(()=>{
        this.checkRoute();
        })

    });
  }

  /**
   * Metodo para el cambio de contraseña de usuario
   */
  changePassword(){
    let changePasswordFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.changePassword.authority,
      scopes: environment.apiConfig.scopes
    };
    this.fse.agregarActualizar('defaulRoute', this.router.url);
    this.redirectAzure(changePasswordFlowRequest);
  }

  /**
   * Redireccionar a la modificacion de usuario de azure
   * @param userFlowRequest parametro de validacion de autenticacion
   */
  redirectAzure(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
        this.authService.loginRedirect(userFlowRequest);
    }
  }

  /**
   * Abre el dialogo de apliciones
   */
  viewApplications() {
    this.dialog.open(InicializadorAppsComponent,{ 
      panelClass: 'width-max-mat-dialog',
      width: '40%', 
      autoFocus: false
    });
  }

  /** Cierra la navegación lateral */
   closeSidenav() {
    if ( this.sidenav.opened ) this.sidenav.close();
  }

  /**
   * Mostrar u ocultar menu headers en dispositivo movil
   */
  showHeaders(){
    if(this.showOrHide) {
      this.showOrHide = false;
    }
    else{
      this.showOrHide = true;
    }
  }

  /**
   * Posiciona el foco en la ciudad selecionada por el usuario (hace scroll automatico), en el menu de ciudades 
   */
  positionCityInTheCitiesMenu() {
    if (!this.showCounterMunicipioVirtual) {
      timer(0)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          for (let index = 0; index < this.matSelectionList.options.length; index++) {
            const option = this.matSelectionList.options.get(index);
            if (option.selected) {
              option.focus();
              break;
            }
          }
        });
    }
  }

  // positionCityInTheCitiesMenu() {
  //   if (!this.showCounterMunicipioVirtual) {
  //     timer(0)
  //       .pipe(takeUntil(this.destroyed$))
  //       .subscribe(() => {
  //         for (let index = 0; index < this.matSelectionList.options.length; index++) {
  //           const option = this.matSelectionList.options.get(index);
  //           if (option.selected) {
  //             option.focus();
  //             break;
  //           }
  //         }
  
  //         // Realizar scroll automático al municipio seleccionado
  //         if (this.Munselect) {
  //           const selectedMunicipioElement = document.getElementById('municipio-' + this.Munselect.IdMunicipio);
  //           if (selectedMunicipioElement) {
  //             selectedMunicipioElement.scrollIntoView({
  //               behavior: 'smooth',
  //               block: 'center', // Centra el elemento en la vista
  //             });
  //           }
  //         }
  //       });
  //   }
  // }
  

  validateDialogterminosENS(){
    if(this.fse.optenerValor('terminos') !== "true"){
      this.db.getTerminoEnsUsuario().subscribe((res)=>{
        const validTerminos = res[0].terminoEns;
        if(!validTerminos){
          window.history.replaceState({}, '', '/');
          setTimeout(() => {
            this.acercaDe.terminos.fire();
          }, 500); 
        }
      })
    }
  }

  /**
   * Cierra y abre el menu de listado de municipios, cada vez que se
   * selecciona un nuevo municipio, para que el menu se recargue con el
   * nuevo listado de municipios.
   */
  changeStateMenuTriggerListMunicipalities() {

    if (this.menuTrigger && this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
      timer(500).pipe( takeUntil(this.destroyed$) ).subscribe( () => this.menuTrigger.openMenu() );
    } 

  }

}


