import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";

import * as CRIPTOJS from "crypto-js";
import { EMPTY, fromEvent, merge, Observable, Observer } from "rxjs";
import { expand, map } from "rxjs/operators";
import { Moment } from "moment";

import { configuracion } from "../../../configuracion";
import { FsessionService } from "../session/fsession.service";
import { SocketService } from "../socket/socket.service";
import { SeguimientoService } from "../seguimiento/seguimiento.service";
import { Perfil } from "src/app/modules/management/components/perfiles/perfiles.component";
import { MunicipalityConfigurationByUser, UsersByMunicipality, Usuario, UsuarioChangePassword } from "../../models/usuario";
import { Traffic, TrafficPedestrian } from "src/app/modules/traffic/components/map/map.component";
import { RespuestaSimulacionPasoCebra } from "src/app/models/paso-cebra";
import { 
  calleG, CamaraG, SoporteG, VehiculoListaInteres, Group, CamerasFilteredByGroup, SettingsViewerRotating, 
  Color, VehicleCountry, RouterConsumption, RoutersDataPowerAndBattery, LicensePlateDetails, EventosCam, 
  LicensePlateInformation, NotificationConnection, NotificationTestBody, ResponseNotificationTest, 
  VehicleDetection,
  Brand,
  SupportedLanguages,
  ConfigurationTourismPanel,
  OmitDataInConfigurationTourismPanel,
  Ticket,
  EnumStateTicket,
  GroupCamerasInConfigurationTourismPanel,
  InfoCameraAndSupportLocation,
  processedTourismPanelData,
  Tracking,
  InterestPoint,
  TrackingCategory,
  InventoryTracking,
  RouterTrackingDisabled,
  ColorByLanguage,
  PedestrianZone
} from '../../models/index';
import { Parking } from "src/app/models/parking.model";
import { Regularizacion } from "src/app/modules/management/components/parking/dialogs/regularizacion/regularizacion.component";
import { InfoCamerasParking } from "src/app/modules/management/components/parking/dialogs/edit-cameras/edit-cameras.component";
import { Sort } from "@angular/material/sort";
import { ConfiguracionParking } from "src/app/modules/management/components/control-acceso-parking/control-acceso-parking.component";

@Injectable({
  providedIn: "root",
})
export class DatabaseService {
  httpOptions: any = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  IdUsuario: number;
  IpPublica: any = "";
  serverIpPublica = configuracion.apiIpPublica;

  baseUrl = `${configuracion.servidor_api}/api`;
  UrlserviciosImg = configuracion.servidor_Img;
  UrlserviciosStream = configuracion.servidor_Stream;
  rangeEnd = configuracion.partialContentSize;
  rangeStart = 0;
  rangeString = `bytes=0-${configuracion.partialContentSize}`;
  constructor(
    private http: HttpClient,
    private fsession: FsessionService,
    private socketService: SocketService,
    private seguimiento: SeguimientoService
  ) {}

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  supplierLogin(usuario: string, clave: string) {
    const data = { usuario, clave };
    return this.http.post(`${this.baseUrl}/login`, data, {
      headers: { Authorization: "pass" },
    });
  }

  refreshToken(token: string, idUsuario: string) {
    const data = { token, idUsuario };
    return this.http.post(`${this.baseUrl}/refresh`, data, {
      headers: { Authorization: "pass" },
    });
  }

  Logout(token: string) {
    const data = { token };
    return this.http.post(`${this.baseUrl}/logout`, data);
  }

  updateTerms() {
    let infNav = this.getBrowserInfo();
    let ip_publica = this.IpPublica.ip;
    let current_datetime = new Date();
    return this.http.post(`${this.baseUrl}/updateTerms`, {
      infNav,
      ip_publica,
      current_datetime,
    });
  }

  TraerUsuarios(busqueda) {
    const data = {
      busqueda: busqueda,
    };
    return this.http.post(`${this.baseUrl}/usuarios`, data);
  }

  TraerUsuariosConRol(busqueda) {
    const data = {
      busqueda: busqueda,
    };
    return this.http.post(`${this.baseUrl}/usuarioConRol`, data);
  }

  /**
   * Trae una lista de usuarios
   * @param nombre nombre del usuario
   * @param municipio nombre del municipio
   * @param activo 0 para inactivos, 1 para activos, o 2 para todos
   * @param pageIndex pagina actual, desde 0
   * @param pageSize cantidad de items por pagina
   * @param sort  objeto para especificar la direccion de la ordenacion
   * @param skipPagination si es verdadero, no se aplica paginacion y se traen todos los usuarios
   * @returns Observable con la respuesta del servidor
   */
  getUsers(
    nombre: string,
    municipio: string,
    activo: number,
    pageIndex: number,
    pageSize: number,
    sort: { active: string, direction: string } = null,
    skipPagination = false
  ) {
    const data = {
      nombre,
      municipio,
      activo,
      pageIndex,
      pageSize,
      sort,
      skipPagination
    };
    return this.http.post(`${this.baseUrl}/TraerUsuarios`, data);
  }

  TraerUsuariosPorMunicipio(idmunicipio) {
    const data = {
      IdMunicipio: idmunicipio,
    };
    return this.http.post(`${this.baseUrl}/usuariosPorMunicipio`, data);
  }

  TraerDetalleMunicipio(IdMunicipio) {
    const data = {
      IdMunicipio: IdMunicipio,
    };
    return this.http.post(`${this.baseUrl}/DetalleMunicipio`, data);
  } 

  TraerDetalleUsusario() {
    const data = {};
    return this.http.post(`${this.baseUrl}/DetalleUsusario`, data);
  }

  validarClave(clave: any) {
    const data = {
      Clave: clave,
    };
    return this.http.post(`${this.baseUrl}/validarClave`, data);
  }

  ActualizarUsuario(supplier: any) {
    return this.http.post(`${this.baseUrl}/actualizarUsuario`, supplier);
  }

  ConfigurarIdiomaDefault(lang: string) {
    return this.http.post(`${this.baseUrl}/ConfigurarIdiomaDefault`, { lang });
  }

  generateTicketPdf(data: any) {
    const options = {
      headers: {
        Accept: "application/pdf",
      },
    };

    return this.http.post(`${this.baseUrl}/generarPdf`, data, options);
  }

  Municipios(val) {
    const data = {
      PalabraClave: val,
    };
    return this.http.post(`${this.baseUrl}/Municipios`, data);
  } 

  /**
   * Trae los municipio que tienen habilitado el campo "Detecciones compartidas"
   * @param val Término de búsqueda
   * @returns Observable
   */
  MunicipiosDetecciones(val) {
    const data = {
      PalabraClave: val,
    };
    return this.http.post(`${this.baseUrl}/MunicipiosDetecciones`, data);
  } 

  TMunicipios(val, cantidad) {
    const data = {
      PalabraClave: val,
      cantidadRegistros: cantidad,
    };
    return this.http.post(`${this.baseUrl}/TodosLosMunicipios`, data);
  } 

  MunicipiosUsuario(IdUsuario: any = null) {
    const data = {
      IdUsuario: IdUsuario,
      PalabraClave: ''
    };

    return this.http.post(`${this.baseUrl}/MunicipiosUsuario`, data);
  } 

  ActualizarAsignacionUsuario(IdsAeliminar, IdsAagregar, IdPadre, Tipo) {
    /*
        IdPadre=puede ser el id del usuario en general si se trabaja desde el panel de usuario o el id de la instalacion en general si se trabaja desde el panel de instalaciones

        Tipo=es a aquien se va a afechar puede se USUARIO o INSTALACION(defecto)

        IdsAeliminar Y IdsAagregar +los valores van separados por comas (,)
        */
    const data = {
      IdsAeliminar: IdsAeliminar,
      IdsAagregar: IdsAagregar,
      IdPadre: IdPadre,
      Tipo: Tipo,
    };
    return this.http.post(`${this.baseUrl}/ActualizarAsignacionUsuario`, data);
  } 

  UsuarioInstalaciones(IdInstalacion: any) {
    const data = {
      IdInstalacion: IdInstalacion,
    };

    return this.http.post(`${this.baseUrl}/UsuarioInstalaciones`, data);
  }

  /**
   * Metodo para guardar un nuevo usuario
   * @param Nombre nombre del usuario
   * @param tipo tipo de usuario
   * @param Usuario nombre unico
   * @param Activo bandera para determinar si esta activo o no
   * @param Email correo
   * @param IdRol id del rol
   * @param tiempo_sesion tiempo de session
   * @param municipiosagregar municipio por defecto
   * @param isSuperAdmin bandera que determina si es un superadmin o no
   * @param {string} [nameRol=''] nombre del rol a asignar
   * @returns Observable con la respuesta
   */
  GuardarUsuario(
    Nombre: any,
    tipo: number,
    Usuario: any,
    dni: number,
    Activo: any,
    Email: any,
    IdRol: any,
    tiempo_sesion: any,
    municipiosagregar: any,
    isSuperAdmin: 1 | 0,
    busquedaMultimunicipio: 1 | 0,
    nameRol: string = ''
  ) {
    const data = {
      tipo,
      Nombre: Nombre,
      Usuario: Usuario,
      dni: dni,
      Activo: Activo,
      Email: Email,
      IdRol: IdRol,
      tiempo_sesion,
      municipiosagregar,
      isSuperAdmin,
      busquedaMultimunicipio,
      nameRol
    };
    return this.http.post(`${this.baseUrl}/GuardarUsuario`, data);
  } 

  ModificarUsuario(data) {
    data.MunicipiosTmp = null;
    return this.http.post(`${this.baseUrl}/ModificarUsuario`, data);
  } 

  /**
   * Cambia la contraseña de un usuario
   * @param data interface que contiene (IdUsuario, Clave)
   * @returns un observable
   */
  ClaveUsuario(data: UsuarioChangePassword): Observable<{ mensaje: string }> {
    return this.http.post<{ mensaje: string }>(
      `${this.baseUrl}/ClaveUsuario`,
      data
    );
  } 

  VerificarUsuario(usuario, mail) {
    const data = {
      Usuario: usuario,
      Mail: mail
    };

    return this.http.post(`${this.baseUrl}/VerificarUsuario`, data);
  } 

  /**
   * Trae los municipos dependiendo del rol del usuario 
   * @param val valor a buscar, hacer match
   * @param idUsuario id del usuario 
   * @param desde fecha inicial
   * @param hasta fecha final 
   * @param esBusquedaPorPermisoPorUsuario 
   * bandera que determina si la busqueda se hace teniendo en cuenta los permisos (municipios) que tiene el usuario 
   * @returns callback
   */
  TraerMunicipiosGenerales(
    val: any, idUsuario: number, desde: string = null, hasta: string = null, esBusquedaPorPermisoPorUsuario = false
  ) {
    const data = {
      PalabraClave: val,
      idUsuario,
      desde,
      hasta,
      esBusquedaPorPermisoPorUsuario
    };
    return this.http.post(`${this.baseUrl}/InfoMunicipios`, data);
  } 

  TraerMunicipiosGeneralesPorFecha(desde, hasta) {
    const data = {
      desde: desde,
      hasta: hasta,
    };
    return this.http.post(`${this.baseUrl}/InfoMunicipiosPorFecha`, data);
  } 

  /**
   * Guardar nuevo municipio
   * @param Nombre nombre del municipio
   * @param Direccion direccion del municipio
   * @param Provincia privincia del municipio
   * @param CodPostal cod postal del municipio
   * @param Poblacion poblacion del municipio
   * @param Contacto contacto del municipio
   * @param Contacto2 contacto2 del municipio
   * @param Contacto3 contacto3 del municipio
   * @param Email Correo del municipio
   * @param Telefono telefono del municipio
   * @param Telefono2 telefono2 del municipio
   * @param Telefono3 telefono3 del municipio
   * @param fechaIF Fecha de instalacion
   * @param fechaIAF Fecha de instalacion acometida
   * @param CUPS CUPS
   * @param IdTarifa tarifa del municipio
   * @param Latitud latitud del municipio
   * @param Longitud longitud del municipio
   * @param usuariosAgregar Usuario que se le asigna
   * @returns 
   */
  GuardarMunicipio(
    Nombre: any,
    Direccion: any,
    Provincia: any,
    CodPostal: any,
    Poblacion: any,
    Contacto: any,
    Contacto2: any,
    Contacto3: any,
    Email: any,
    Telefono: any,
    Telefono2: any,
    Telefono3: any,
    fechaIF: any,
    fechaIAF: any,
    CUPS: any,
    IdTarifa: any,
    Latitud: any,
    Longitud: any,
    deteccionesCompartidas: any,
    Zona: any,
    usuariosAgregar: any
  ) {
    const data = {
      Nombre: Nombre,
      Direccion: Direccion,
      Provincia: Provincia,
      CodPostal: CodPostal,
      Poblacion: Poblacion,
      Contacto: Contacto,
      Contacto2: Contacto2,
      Contacto3: Contacto3,
      Email: Email,
      Telefono: Telefono,
      Telefono2: Telefono2,
      Telefono3: Telefono3,
      fechaIF: fechaIF,
      fechaIAF: fechaIAF,
      CUPS: CUPS,
      IdTarifa: IdTarifa,
      Latitud: Latitud,
      Longitud: Longitud,
      deteccionesCompartidas,
      Zona: Zona,
      usuariosAgregar: usuariosAgregar,
    };
    return this.http.post(`${this.baseUrl}/GuardarMunicipio`, data);
  } 

  /**
   * Modifica un municipio
   * @param Datos datos del municipio
   * @returns Observable
   */
  ModificarMunicipio(Datos: any) {
    const idUser = this.fsession.optenerValor('idusuario');
    const data = Datos;
    data.idUser = idUser;
    return this.http.post(`${this.baseUrl}/ModificarMunicipio`, data);
  }

  getZonaHoraria(filter){
    const data = {
      filter: filter,
    };
    return this.http.post(`${this.baseUrl}/getZonaHoraria`, data);
  }

  TraerMunicipiosPorUsuarios(IdUsuario) {
    const data = {
      IdUsuario: IdUsuario,
    };
    return this.http.post(`${this.baseUrl}/MunicipioGporUsuario`, data);
  } 

  /**
   * Trae listado de soportes
   * @param desde fecha de inicio de soportes
   * @param hasta fecha final de soportes
   * @param Tipo tipo de soporte
   * @param IdMunicipio id de municipio
   * @param Descripcion descripcion
   * @param pageIndex indice de la pagina actual 
   * @param pageSize numero de resultados a obtener en la busqueda
   * @returns Observable
   */
  TraerSoportesGenerales(
    desde: string, 
    hasta: string, 
    Tipo: any, 
    IdMunicipio: number, 
    Descripcion: string, 
    activo: boolean,
    pageIndex: number, 
    pageSize: number,
    sort: Sort,
  ): Observable<SoporteG[]> {
    const data = {
      desde,
      hasta,
      Tipo,
      activo,
      IdMunicipio,
      Descripcion,
      pageIndex,
      pageSize,
      sort
    };
    return this.http.post<SoporteG[]>(`${this.baseUrl}/InfoSoportes`, data);
  } 

  TraerTiposSoporte(nombre, Activo, validateName) {
    const data = {nombre: nombre, Activo: Activo, validateName: validateName}
    return this.http.post(`${this.baseUrl}/TraerTiposSoporte`, data);
  } 

  AlmacenarTiposSoporte(Nombre, Descripcion) {
    const data = {
      Nombre: Nombre,
      Descripcion: Descripcion
    };
    return this.http.post(`${this.baseUrl}/AgregarTipoSoporte`, data);
  }

  ModificarTipoSoporte(IdTipo, Nombre, Descripcion, Activo) {
    const data = {
      IdTipo: IdTipo,
      Nombre: Nombre,
      Descripcion: Descripcion,
      Activo: Activo,
    };
    return this.http.post(`${this.baseUrl}/ModificarTipoSoporte`, data);
  } 

  VerificarTipoSoporte(IdTipo) {
    const data = {
      IdTipo: IdTipo,
    };
    return this.http.post(`${this.baseUrl}/VerificarTipoSoporte`, data);
  } 

  EliminarTipoSoporte(IdTipo) {
    const data = {
      IdTipo: IdTipo,
    };
    return this.http.post(`${this.baseUrl}/EliminarTipoSoporte`, data);
  } 

  GuardarSoporte(
    Descripcion,
    Latitud,
    Longitud,
    IdTipo,
    IdMunicipio,
    IdCalle,
    Foto
  ) {
    const data = {
      Descripcion: Descripcion,
      Latitud: Latitud,
      Longitud: Longitud,
      IdTipo: IdTipo,
      IdMunicipio: IdMunicipio,
      IdCalle: IdCalle,
      Foto: Foto,
    };
    return this.http.post(`${this.baseUrl}/GuardarSoporte`, data);
  } 

  ModificarSoporte(
    IdSoporte,
    Descripcion,
    Latitud,
    Longitud,
    IdTipo,
    IdMunicipio,
    Eliminado,
    IdCalle,
    Foto
  ) {
    const data = {
      IdSoporte: IdSoporte,
      Descripcion: Descripcion,
      Latitud: Latitud,
      Longitud: Longitud,
      IdTipo: IdTipo,
      IdMunicipio: IdMunicipio,
      Eliminado: Eliminado,
      IdCalle: IdCalle,
      Foto: Foto,
    };
    return this.http.post(`${this.baseUrl}/ModificarSoporte`, data);
  } 

  ModificarFotoSoporte(FotoNueva, FotoVieja, IdSoporte) {
    let form: FormData = new FormData();
    form.append("Archivo", FotoNueva, FotoNueva.name);
    form.append("FotoVieja", FotoVieja);
    form.append("IdSoporte", IdSoporte);
    return this.http.post(`${this.baseUrl}/ModificarFotoSoporte`, form);
  } 

  EliminarFotoSoporte(FotoVieja, IdSoporte) {
    return this.http.post(`${this.baseUrl}/EliminarFotoSoporte`, {
      FotoVieja,
      IdSoporte,
    });
  } 

  /**
   * Sube imagen de soporte
   * @param supportImage imagen a subir 
   * @returns Observable
   */
  SubirFotoSoporte(supportImage: File): Observable<any> {
    let form: FormData = new FormData();
    form.append("Archivo", supportImage, supportImage.name);
    return this.http.post(`${this.baseUrl}/SubirFotoSoporte`, form);
  } 

  /**
   * Traer listado de camaras
   * @param Soporte id de soporte
   * @param IdMunicipio id de municipio 
   * @param Valor valor a buscar
   * @param Activa bandera que representa si desea buscar por elementos activos o no 
   * @param Idioma idioma
   * @param pageIndex indice de la pagina actual 
   * @param pageSize numero de resultados a obtener en la busqueda
   * @returns Observable
   */
  TraerCamarasGenerales(
    Soporte: any, 
    IdMunicipio: number, 
    Valor: string, 
    Activa: boolean, 
    Idioma: string, 
    pageIndex: number, 
    pageSize: number,
    sort: {active: string, direction: '' | 'asc' | 'desc'}
  ): Observable<CamaraG[]> {
    const data = {
      Soporte: Soporte,
      IdMunicipio: IdMunicipio,
      Valor: Valor,
      Activa: Activa,
      Idioma: Idioma,
      pageIndex,
      pageSize,
      sort
    };
    return this.http.post<CamaraG[]>(`${this.baseUrl}/InfoCamaras`, data);
  } 

  TraerSoporte(activado, IdMunicipio) {
    const data = {
      activado: activado,
      IdMunicipio: IdMunicipio,
    };
    return this.http.post(`${this.baseUrl}/TraerSoporte`, data);
  } 

  GuardarCamara(
    id_camara,
    IdSoporte,
    Altura,
    GradosOrientacion,
    Ip,
    marca,
    modelo,
    posicion,
    nombre,
    IdTipoCamara,
    alcanceCoordenadas?,
    parking?,
    ahorro_consumo?: number,
    peatones_lineas?: boolean,
  ) {
    const data = {
      id_camara: id_camara,
      IdSoporte: IdSoporte,
      Altura: Altura,
      GradosOrientacion: GradosOrientacion,
      Ip: Ip,
      modelo: modelo,
      marca: marca,
      posicion: posicion,
      nombre: nombre,
      IdTipoCamara: IdTipoCamara,
      alcanceCoordenadas: alcanceCoordenadas,
      parking: parking,
      ahorro_consumo: ahorro_consumo,
      peatones_lineas: peatones_lineas
    };
    return this.http.post(`${this.baseUrl}/GuardarCamara`, data);
  } 

  ModificarCamara(
    Id,
    id_camara,
    IdSoporte,
    Altura,
    GradosOrientacion,
    Ip,
    marca,
    modelo,
    posicion,
    nombre,
    IdTipoCamara,
    tipoDeteccion,
    Activa,
    alcanceCoordenadas,
    parking,
    ahorro_consumo: number,
    peatones_lineas: boolean
  ) {
    const data = {
      Id: Id,
      id_camara: id_camara,
      IdSoporte: IdSoporte,
      Altura: Altura,
      GradosOrientacion: GradosOrientacion,
      Ip: Ip,
      modelo: modelo,
      marca: marca,
      posicion: posicion,
      nombre: nombre,
      IdTipoCamara: IdTipoCamara,
      tipoDeteccion: tipoDeteccion,
      Activa: Activa,
      alcanceCoordenadas: alcanceCoordenadas,
      parking: parking,
      ahorro_consumo: ahorro_consumo,
      peatones_lineas: peatones_lineas
    };
    return this.http.post(`${this.baseUrl}/ModificarCamara`, data);
  } 

  ValidarPosicion(IdSoporte, posicion) {
    const data = {
      IdSoporte: IdSoporte,
      posicion: posicion,
    };
    return this.http.post(`${this.baseUrl}/ValidarPosicion`, data);
  } 

  ultimaImagenCamara(idCamara, municipio) {
    const data = {
      IdCamara: idCamara,
      municipio,
    };
    return this.http.post(`${this.baseUrl}/TraerUltimaImagenProcesada`, data);
  } 

  listadoImagenes(idCamara, cantidad) {
    const data = {
      IdCamara: idCamara,
      Cantidad: cantidad,
    };
    return this.http.post(`${this.baseUrl}/ListadoImagenes`, data);
  } 

  listadoImagenesFiltrado(data) {
    return this.http.post(`${this.baseUrl}/ListadoImagenesFiltrado`, data);
  } 

  TraerDetalleSoporte(IdSoporte, IdMunicipio) {
    const data = {
      IdSoporte: IdSoporte,
      IdMunicipio: IdMunicipio,
    };
    return this.http.post(`${this.baseUrl}/DetalleSoporte`, data);
  } 

  TraerImagen(Archivo) {
    const data = {
      Archivo: Archivo,
    };
    return this.http.post(`${this.UrlserviciosImg}/UltimaProcesada`, data);
  } 

  soporteMapa(idMunicipio) {
    const data = {
      idMunicipio: idMunicipio,
    };
    return this.http.post(`${this.baseUrl}/Soportes`, data);
  } 

  TraerCallesPorMunicipio(cantidad, IdMunicipio) {
    const data = {
      Cantidad: cantidad,
      IdMunicipio: IdMunicipio,
    };
    return this.http.post(`${this.baseUrl}/TraerCallesPorMunicipio`, data);
  } 

  AlmacenarCalle(Nombre, IdMunicipio) {
    const data = {Nombre, IdMunicipio};
    return this.http.post(`${this.baseUrl}/AlmacenarCalle`, data);
  } 

  ModificarCalle(IdCalle, Nombre, IdMunicipio, Eliminado) {
    const data = {
      IdCalle: IdCalle,
      Nombre: Nombre,
      IdMunicipio: IdMunicipio,
      Eliminado: Eliminado,
    };
    return this.http.post(`${this.baseUrl}/ModificarCalle`, data);
  } 

  /**
   * Traer calles de un municipio
   * @param Nombre nombre de la calle
   * @param IdMunicipio id del municipio
   * @param pageIndex Index actual de la paginacion
   * @param pageSize cantidad de registro por paginas
   * @returns observable
   */
  TraerCallesMunicipio(Nombre: string, IdMunicipio: string | number, Activa: boolean, pageIndex: number, pageSize: number, sort): Observable<calleG[]> {
    const data = {
      Nombre: Nombre,
      IdMunicipio: IdMunicipio,
      pageIndex, 
      pageSize,
      Activa,
      sort
    };
    return this.http.post<calleG[]>(`${this.baseUrl}/TraerCallesMunicipio`, data);
  } 

  getListCameras(IdMunicipio, OcultarMatricula) {
    return this.http.post(`${this.baseUrl}/ListCamara`, {
      IdMunicipio,
      OcultarMatricula,
    });
  } 

  actualizarConfiguracion(Configuracion) {
    return this.http.post(
      `${this.baseUrl}/ActualizarConfiguracion`,
      Configuracion
    );
  } 

  videoStream(ip, profile, user, pass) {
    return this.http.post(`${this.UrlserviciosStream}/api/stream`, {
      ip,
      user,
      pass,
    });
  } 

  profilesStream(ip) {
    return this.http.post(`${this.UrlserviciosStream}/profiles`, { ip });
  } 

  /**
   * Detiene el stream de datos
   * @param ip ip
   * @returns Observable
   */
  videoStreamStop(ip: string) {
    const token = this.fsession.optenerValor("dato1");
    return this.store(
      "stream/stop",
      { ip },
      { Authorization: token }
    );
  } 

  TraerCamara(idCamara, municipio) {
    const data = {
      IdCamara: idCamara,
      municipio,
    };
    return this.http.post(`${this.baseUrl}/TraerUltimaImagenProcesada`, data);
  } 

  TraerTiposCamara(cantidad, valor, activo) {
    const data = {
      Cantidad: cantidad,
      Valor: valor,
      activo: activo
    };
    return this.http.post(`${this.baseUrl}/TraerTiposCamara`, data);
  }

  AlmacenarTipoCamara(TipoCamara, Descripcion, Peatones) {
    const data = {
      TipoCamara: TipoCamara,
      Descripcion: Descripcion,
      Peatones: Peatones,
    };
    return this.http.post(`${this.baseUrl}/AgregarTipoCamara`, data);
  } 

  ModificarTipoCamara(IdTipoCamara, TipoCamara, Descripcion, Activo, Peatones) {
    const data = {
      IdTipoCamara: IdTipoCamara,
      TipoCamara: TipoCamara,
      Descripcion: Descripcion,
      Activo: Activo,
      Peatones: Peatones,
    };
    return this.http.post(`${this.baseUrl}/ModificarTipoCamara`, data);
  } 

  /**
   * Obtener routers
   * @param valor valor a comparar
   * @param IdMunicipio id de municipio
   * @param activo bandera
   * @param clima bandera
   * @param pageIndex indice de la pagina actual
   * @param pageSize cantidad de registros a obetener
   * @returns Observable
   */
  TraerRouters( 
    valor: string, 
    IdMunicipio: string | number, 
    activo: any, 
    clima = false, 
    pageIndex: number = null,
    pageSize: number = null,
    sort = { active: 'idRouter', direction: '' }
  ) {
    const data = {
      Valor: valor,
      IdMunicipio: IdMunicipio,
      activo,
      clima,
      pageIndex,
      pageSize,
      sort
    };

    return this.http.post(`${this.baseUrl}/TraerRouters`, data);
  }

  TraerTelemetriaRouters(Idrouter, IdMunicipio, FechaI, FechaF) {
    const data = {
      Idrouter: Idrouter,
      IdMunicipio: IdMunicipio,
      FechaI: FechaI,
      FechaF: FechaF,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    };

    return this.http.post(`${this.baseUrl}/TraerTelemetriaRouters`, data);
  } 

  TraerTelemetriaAlimentacion(Idrouter, IdMunicipio, FechaI, FechaF) {
    const data = {
      Idrouter: Idrouter,
      IdMunicipio: IdMunicipio,
      FechaI: FechaI,
      FechaF: FechaF,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    };

    return this.http.post(`${this.baseUrl}/TraerTelemetriaAlimentacion`, data);
  } 

  TraerTelemetriaAlarmas(Idrouter, IdMunicipio, FechaI, FechaF) {
    const data = {
      Idrouter: Idrouter,
      IdMunicipio: IdMunicipio,
      FechaI: FechaI,
      FechaF: FechaF,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    };

    return this.http.post(`${this.baseUrl}/TraerTelemetriaAlarmas`, data);
  } 

  AlmacenarRouter(
    SSID,
    Ip,
    IdSoporte,
    CDI,
    Telefono,
    SerieRouter,
    MacRouter,
    Imei,
    IpRed,
    Ndispositivos,
    tracking,
    tiene_clima
  ) {
    const data = {
      SSID: SSID,
      Ip: Ip,
      IdSoporte: IdSoporte,
      CDI: CDI,
      Telefono: Telefono,
      SerieRouter: SerieRouter,
      MacRouter: MacRouter,
      Imei: Imei,
      IpRed: IpRed,
      Ndispositivos: Ndispositivos,
      tracking: tracking,
      tiene_clima: tiene_clima
    };
    return this.http.post(`${this.baseUrl}/AgregarRouter`, data);
  } 

  ModificarRouter(
    IdRouter,
    SSID,
    Ip,
    IdSoporte,
    CDI,
    Telefono,
    SerieRouter,
    MacRouter,
    Imei,
    IpRed,
    Ndispositivos,
    Activo,
    tracking,
    tiene_clima
  ) {
    const data = {
      IdRouter: IdRouter,
      SSID: SSID,
      Ip: Ip,
      IdSoporte: IdSoporte,
      CDI: CDI,
      Telefono: Telefono,
      SerieRouter: SerieRouter,
      MacRouter: MacRouter,
      Imei: Imei,
      IpRed: IpRed,
      Ndispositivos: Ndispositivos,
      Activo: Activo,
      tracking: tracking,
      tiene_clima: tiene_clima
    };

    return this.http.post(`${this.baseUrl}/ModificarRouter`, data);
  } 

  listFile( dates: any ) {
    const token = this.fsession.optenerValor("dato1");
    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${token}`
    );

    if (dates.user) {
      dates.user = CRIPTOJS.AES.encrypt(
        dates.user.toString(),
        configuracion.claveEncriptacion
      ).toString();
    }
    if (dates.pass) {
      dates.pass = CRIPTOJS.AES.encrypt(
        dates.pass.toString(),
        configuracion.claveEncriptacion
      ).toString();
    }
    if (dates.camera) {
      dates.camera = CRIPTOJS.AES.encrypt(
        dates.camera.toString(),
        configuracion.claveEncriptacion
      ).toString();
    }

    return this.http.post(`${this.UrlserviciosStream}/api/search`, dates, {
      headers,
    });
  }

  downloadFile(playbackuri) {
    return this.http.post(
      `${this.UrlserviciosStream}/api/download`,
      playbackuri,
      { observe: "response" }
    );
  }

  downloadFileParts(start: any, end: any, name: any) {
    const token = this.fsession.optenerValor("dato1");
    const headers = new HttpHeaders()
      .set("Authorization", `Bearer ${token}`)
      .set("range", `bytes=${start}-${end}`);
    return new Promise((resolve, reject) => {
      try {
        this.http
          .get(this.UrlserviciosStream + `/api/download/${name}`, {
            headers,
            responseType: "arraybuffer",
          })
          .subscribe(
            () => {
            },
            () => {
              reject();
            }
          );
      } catch (error) {
        reject(error);
        throw error;
      }
    });
  }

  getAllItems(totalSize: any, name: any): Observable<any[]> {
    const token = this.fsession.optenerValor("dato1");
    const getRange = (rangeParams, nameParams): Observable<any> => {
      let headers: HttpHeaders;
      headers = new HttpHeaders()
        .set("Authorization", `Bearer ${token}`)
        .set("range", rangeParams)
        .set("Accept-Ranges", "bytes")
        .set("ContentType", "application/octet-stream")
        .set(`Content-Disposition`, `attachment; filename="${name}"`);
      return this.http.get(
        this.UrlserviciosStream + `/api/download/${nameParams}`,
        {
          headers,
          responseType: "arraybuffer",
          observe: "response" as "body",
        }
      );
    };
    const range = this.rangeString;

    return getRange(range, name).pipe(
      expand((res: Response) => {
        this.rangeStart = this.rangeEnd + 1;
        this.rangeEnd = this.nextRange(this.rangeEnd, totalSize);
        this.rangeString = `bytes=${this.rangeStart}-${this.rangeEnd}`;
        if (res.status === 206) {
          return getRange(this.rangeString, name);
        } else {
          this.rangeString = `bytes=0-${configuracion.partialContentSize}`;
          this.rangeStart = 0;
          this.rangeEnd = configuracion.partialContentSize;
          return EMPTY;
        }
      })
    );
  } 

  nextRange(parts, total) {
    let result;
    if (parts < total) {
      result = parts + configuracion.partialContentSize;
      if (result < total) {
        return result;
      } else {
        return total;
      }
    } else {
      return total;
    }
  } 

  store(endpoint, object, headers = {}): Observable<any> {
    for (const prop in headers) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        prop,
        headers[prop]
      );
    }

    return this.http.post(
      `${this.UrlserviciosStream}/api/${endpoint}`,
      object,
      this.httpOptions
    );
  } 

  streamCameras(ip: string) {
    const token = this.fsession.optenerValor("dato1");
    var ip = CRIPTOJS.AES.encrypt(
      ip.toString(),
      configuracion.claveEncriptacion
    ).toString();
    return this.store(
      "stream",
      { ip},
      { Authorization: token }
    );
  }

  /**
   * Reproduce stream
   * @param ip ip
   * @param channel canal 
   * @returns Observable
   */
  streamCameras2(ip: string, channel: number) {
    const token = this.fsession.optenerValor("dato1");
    var channel = channel;
    var ip = CRIPTOJS.AES.encrypt((ip).toString(), configuracion.claveEncriptacion).toString();
    return this.store('stream2', { ip }, { Authorization: token });
  }

  /**
   * Obtener estado camara PTZ
   * @param ip ip
   * @param user user cifrado
   * @param pass pass cifrado
   * @returns Observable
   */
  obtenerEstadoPTZ( ip: string, user: string, pass: string ) {
    const token = this.fsession.optenerValor("dato1");
    user = CRIPTOJS.AES.encrypt((user).toString(), configuracion.claveEncriptacion).toString();
    pass = CRIPTOJS.AES.encrypt((pass).toString(), configuracion.claveEncriptacion).toString();
    ip = CRIPTOJS.AES.encrypt((ip).toString(), configuracion.claveEncriptacion).toString();

    return this.store('ptz/status', { ip, user, pass }, { Authorization: token });
  }

  /**
   * Envia comando countinuo camara PTZ
   * @param ip ip   
   * @param user usuario cifrado
   * @param pass pass cifrado
   * @param position position
   * @returns Observable
   */
  enviarComandoContinous(ip: string, user: string, pass: string, position: any) {
    user = CRIPTOJS.AES.encrypt((user).toString(), configuracion.claveEncriptacion).toString();
    pass = CRIPTOJS.AES.encrypt((pass).toString(), configuracion.claveEncriptacion).toString();
    ip = CRIPTOJS.AES.encrypt((ip).toString(), configuracion.claveEncriptacion).toString();

    return this.store('ptz/continuous', { ip, user, pass, position });
  }

 /**
   * Envia comando absoluto camara PTZ
   * @param ip ip   
   * @param user usuario cifrado
   * @param pass pass cifrado
   * @param position position
   * @returns Observable
   */
  enviarComandoAbsolute(ip: string, user: string, pass: string, position: any) {
    const token = this.fsession.optenerValor("dato1");
    user = CRIPTOJS.AES.encrypt((user).toString(), configuracion.claveEncriptacion).toString();
    pass = CRIPTOJS.AES.encrypt((pass).toString(), configuracion.claveEncriptacion).toString();
    ip = CRIPTOJS.AES.encrypt((ip).toString(), configuracion.claveEncriptacion).toString();
    return this.store('ptz/absolute', { ip, user, pass, position }, { Authorization: token });
  }

  presetPTZ(ip, user, pass, method: 'SAVE'|'CALL'|'DELETE', presetId?: number ) {
    user = CRIPTOJS.AES.encrypt((user).toString(), configuracion.claveEncriptacion).toString();
    pass = CRIPTOJS.AES.encrypt((pass).toString(), configuracion.claveEncriptacion).toString();
    ip = CRIPTOJS.AES.encrypt((ip).toString(), configuracion.claveEncriptacion).toString();
    return this.http.post(`${this.UrlserviciosStream}/api/ptz/presets`, {
        ip,
        user,
        pass,
        preset: {
            method,
            id: presetId
        }
    });
  }

  focusPTZ(ip, user, pass, focus ) {
      user = CRIPTOJS.AES.encrypt((user).toString(), configuracion.claveEncriptacion).toString();
      pass = CRIPTOJS.AES.encrypt((pass).toString(), configuracion.claveEncriptacion).toString();
      ip = CRIPTOJS.AES.encrypt((ip).toString(), configuracion.claveEncriptacion).toString();
      return this.http.post(`${this.UrlserviciosStream}/api/ptz/focus`, {
          ip,
          user,
          pass,
          focus
      });
  }

  irisPTZ(ip, user, pass, iris) {
      user = CRIPTOJS.AES.encrypt((user).toString(), configuracion.claveEncriptacion).toString();
      pass = CRIPTOJS.AES.encrypt((pass).toString(), configuracion.claveEncriptacion).toString();
      ip = CRIPTOJS.AES.encrypt((ip).toString(), configuracion.claveEncriptacion).toString();
      return this.http.post(`${this.UrlserviciosStream}/api/ptz/iris`, {
          ip,
          user,
          pass,
          iris
      });
  }

  TraerCamara2(IdCamaras: any[], fecha: string, tiempo: number, idmunicipio: number) {
    const data = {
      zonaHoraria: this.fsession.optenerValor("dato41"),
      IdCamaras: IdCamaras,
      Fecha: fecha,
      Tiempo: tiempo,
      idMunicipio: idmunicipio,
    };
  
    return this.http.post(`${this.baseUrl}/TraerImagenProcesadaPorFecha`, data);
  }

  videoStreamRecord(data) {
    return this.http.post(`${this.UrlserviciosStream}/api/stream/record`, data);
  } 

  Validarserierepetida(id_camara) {
    const data = {
      id_camara: id_camara,
    };
    return this.http.post(`${this.baseUrl}/Validarserierepetida`, data);
  } 

  TraerUltimgCamara(IdCamaras) {
    const data = {
      IdCamaras: IdCamaras,
    };

    return this.http.post(`${this.baseUrl}/TraerUltimgCamaras`, data);
  } 

  descargasPendientes(data) {
    return this.http.post(`${this.baseUrl}/DescargasPendientes`, data);
  } 

  traerUrlVideo(data) {
    return this.http.post(`${this.baseUrl}/traerUrlVideo`, data);
  } 

  downloadFileBackground(data) {
    let Fechalimite = new Date();
    Fechalimite.setDate(Fechalimite.getDate() + 7);
    return this.http.post(`${this.baseUrl}/AgregarDescargaPendiente`, {
      data,
      Fechalimite,
    });
  } 

  obtenerLimiteDescarga(idUsuario, fechaActual) {
    return this.http.post(
      `${this.baseUrl}/obtenerCantidadDescargasPendientes`,
      { idUsuario, fechaActual }
    );
  }

  obtenerUsuario() {
    let t = this.fsession.optenerValor("dato1");
    const h = new JwtHelperService();
    const dT = h.decodeToken(t);
    return Number(
      CRIPTOJS.AES.decrypt(dT.fviu, configuracion.claveEncriptacion).toString(
        CRIPTOJS.enc.Utf8
      )
    );
  }

  ModificarMatricula(data) {
    return this.http.post(`${this.baseUrl}/ModificarMatricula`, data);
  }

  mapaTrafico(data) {
    return this.http.post(`${this.baseUrl}/mapaTrafico`, data);
  } 

  traerUltImagenes(cantidad, Municipio) {
    return this.http.post(`${this.baseUrl}/TraerUltImagenes`, {
      cantidad,
      Municipio,
    });
  } 

  /**
   * Obtiene las ultimas imagenes de las camaras seleccionadas
   * @param cantidad numero de grid
   * @param Municipio id de municipio
   * @param ArrayCamaras arreglo de ids de las camaras seleccionadas
   * @returns Observable
   */
  traerUltImagenesNuevoVisor(cantidad: number, Municipio: string, ArrayCamaras: any[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/TraerUltImagenesNuevoVisor`, {
      cantidad,
      Municipio,
      ArrayCamaras,
    });
  } 

  
  /**
   * Traer informacion de matriculas
   * @param municipio Municipio
   * @param FechaI Fecha de inicio de las detecciones
   * @param FechaF Fecha de fin de las detecciones
   * @param CamSel Cámaras de detecciones
   * @param idioma Idioma a mostrar
   * @returns
   */
  TraerInfPersonasBD( 
    municipio: string | number, 
    FechaI: string, 
    FechaF: string, 
    CamSel: any[], 
    idioma: string,
    prefilters?: any, 
  ): Observable<{matricula:LicensePlateInformation[]}> {
    const data = {
      municipio: municipio,
      FechaI: FechaI,
      FechaF: FechaF,
      CamSel: CamSel,
      idioma: idioma,
      zonaHoraria: this.fsession.optenerValor("dato41"),
      prefiltros: prefilters
    };
    return this.http.post<any>(`${this.baseUrl}/TraerPersonasBd`, data);
  } 

  /**
   * Traer informacion de matriculas
   * @param matricula filtro por matricula del vehiculo
   * @param municipio Municipio
   * @param FechaI Fecha de inicio de las detecciones
   * @param FechaF Fecha de fin de las detecciones
   * @param CamSel Cámaras de detecciones
   * @param idioma Idioma a mostrar
   * @param soloFotoRojo Si se muestran solo las infracciones
   * @param color filtro por color
   * @param TipoDeteccion Filtro por deteccion
   * @param marca filtro por marca del vehiculo
   * @param velocidadInicial filtro de por velocidad del vehiculo
   * @param velocidadFinal filtro de por velocidad del vehiculo
   * @returns
   */
  TraerInfMatriculasBD( 
    matricula: string, 
    municipio: string | number, 
    FechaI: string, 
    FechaF: string, 
    CamSel: any[], 
    idioma: string, 
    soloFotoRojo: boolean, 
    color: string, 
    TipoDeteccion: string, 
    marca: string, 
    velocidadInicial: number, 
    velocidadFinal: number, 
    noplate: boolean,
    listVehicleInterests: string 
  ): Observable<{matricula:LicensePlateInformation[]}> {
    const data = {
      matricula,
      municipio: municipio,
      FechaI: FechaI,
      FechaF: FechaF,
      CamSel: CamSel,
      idioma: idioma,
      zonaHoraria: this.fsession.optenerValor("dato41"),
      soloFotoRojo: soloFotoRojo,
      color: color,
      TipoDeteccion: TipoDeteccion,
      marca: marca,
      velocidadInicial : velocidadInicial,
      velocidadFinal : velocidadFinal,
      noplate : noplate,
      listVehicleInterests: listVehicleInterests
    };
    return this.http.post<any>(`${this.baseUrl}/TraerMatriculasBd2`, data);
  } 

  TraerImageneseventoscam(Idseventoscam) {
    const data = {
      Idseventoscam: Idseventoscam,
    };
    return this.http.post(`${this.baseUrl}/TraerImageneseventoscam`, data);
  } 

  obtenerUrlAvanzadaInfraccion(archivo, sin_OCR, tipoInfraccion, confirmada) {
    const data = {
      archivo,
      sin_OCR,
      tipoInfraccion,
      confirmada,
    };
    return this.http.post(`${this.baseUrl}/obtenerUrlAvanzadaInfraccion`, data);
  } 

  /**
   * Agregar descarga pendiente de evento
   * @param FechaEventoInicio fecha inicio del evento
   * @param FechaEventoFin fecha final del evento
   * @param FechaEventoInicioUtc fecha inicio del evento en UTC
   * @param FechaEventoFinUtc fecha final del evento en UTC
   * @param Municipio id de municipio
   * @param Camara id de la camara selecionada del evento
   * @param playbackURI playbackURI
   * @param nombre nombre de la camara
   * @param duracion duracion del evento
   * @param Fechalimite fecha limite del evento
   * @returns Observable
   */
  DescargaPendienteEvento(
    FechaEventoInicio: string,
    FechaEventoFin: string,
    FechaEventoInicioUtc: string,
    FechaEventoFinUtc: string,
    Municipio: string | number,
    Camara: string,
    playbackURI: string,
    nombre: string,
    duracion: number,
    Fechalimite: any
  ) {

    var data = {
      FechaEventoInicio,
      FechaEventoFin,
      FechaEventoInicioUtc,
      FechaEventoFinUtc,
      Municipio,
      Camara,
      playbackURI,
      nombre,
      duracion,
      Fechalimite,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    };

    return this.http.post(
      `${this.baseUrl}/AgregarDescargaPendienteEvento`,
      data
    );
  } 

  ActualizarEstadoPendienteDesc(IdPendiente) {
    var data = {
      IdPendiente,
    };
    return this.http.post(
      `${this.baseUrl}/ActualizarEstadoPendienteDesc`,
      data
    );
  }

  TraerCamaraMatricula(municipio) {
    var data = {
      municipio,
    };
    return this.http.post(`${this.baseUrl}/TraerCamarasMatriculas`, data);
  }

  TraerConfigUsuario() {
    var data = null;
    return this.http.post(`${this.baseUrl}/TraerConfigUsuario`, data);
  }

  BuquedaAvanzada(Fecha, intervalo, id_camara, futuro) {
    var data = {
      Fecha,
      intervalo,
      id_camara,
      futuro,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    };
    return this.http.post(`${this.baseUrl}/BuquedaAvanzada`, data);
  }

  TraerAlerta() {
    return this.http.post(`${this.baseUrl}/Alerta`, null);
  }

  /**
   * Traer listado de alertas
   * @param origin Origen de la alerta
   * @param estado Estado de la alerta
   * @param clave Clave de la alerta
   * @param Idioma Idioma a buscar
   * @param pageIndex Indice de la pagina actual
   * @param pageSize Numero de resultados a obtener en la busqueda
   * @param sort Ordenamiento de los resultados
   * @returns Observable
   */
  AlertaBusqueda(origin: number, estado: number, clave: any, Idioma: any, pageIndex: number, pageSize: number, sort: Sort) {
    return this.http.post(`${this.baseUrl}/AlertaBusqueda`, {
      origin,
      estado,
      clave,
      Idioma,
      pageIndex,
      pageSize,
      sort
    });
  }

  AlertaBusqueda2(origin, estado, clave, Idioma, IdMunicipio) {
    return this.http.post(`${this.baseUrl}/AlertaBusqueda2`, {
      origin,
      estado,
      clave,
      Idioma,
      IdMunicipio,
    });
  }

  ActualizarAlerta(Fecha, IdAlerta, estado) {
    return this.http.post(`${this.baseUrl}/ActualizarAlerta`, {
      Fecha,
      IdAlerta,
      estado,
    });
  }

  traerUltimaImgArray(cant, idMun) {
    return this.http.post(`${this.baseUrl}/traerUltimaImgArray`, {
      cant,
      idMun,
    });
  }
  
  getListCameras2(
    Nombre,
    TipoCamara,
    Soporte,
    IdMunicipio,
    OcultarMatricula,
    Activa,
    m,
    filter
  ) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/ListCamara2`, {
      Nombre,
      TipoCamara,
      Soporte,
      IdMunicipio,
      OcultarMatricula,
      Activa,
      m,
      idioma,
      filter
    });
  }

  traerAuditoria(Suceso, Fecha1, Fecha2, Usuario) {
    return this.http.post(`${this.baseUrl}/auditoriaUsuario`, {
      Suceso,
      Fecha1,
      Fecha2,
      Usuario,
    });
  }

  traerUsuariosAuditoria() {
    return this.http.post(`${this.baseUrl}/traerUsuariosAuditoria`, null);
  }

  obtenerIp() {
    return this.http.get(this.serverIpPublica);
  }

  public asignarIpPublica() {
    this.obtenerIp().subscribe((res: any) => {
      this.IpPublica = res.ip;
      this.fsession.agregarActualizar("user-ip", this.IpPublica);
    });
  }

  crearSuceso(r, b, o: number) {
    let t = this.fsession.optenerValor("dato1");
    const h = new JwtHelperService();
    const dT = h.decodeToken(t);
    let suceso = "";

    if (o == 1) {
      suceso = "Ha ingresado al sistema";
    } else {
      if (o == 2) {
        suceso = "Ha salido del sistema";
      } else if (o == 3) {
        suceso = `Ha hecho una petición hacia la ruta ${r}, y estos fueron los datos enviados ${b}`;
      }
    }

    this.IdUsuario = Number(
      CRIPTOJS.AES.decrypt(dT.fviu, configuracion.claveEncriptacion).toString(
        CRIPTOJS.enc.Utf8
      )
    );

    let id_usuario = this.IdUsuario;
    let infNav = this.getBrowserInfo();
    let ip_publica = this.IpPublica.ip;
    let current_datetime = new Date();

    let fecha_auditoria =
      current_datetime.getFullYear() +
      "-" +
      (current_datetime.getMonth() + 1) +
      "-" +
      current_datetime.getDate() +
      " " +
      current_datetime.getHours() +
      ":" +
      current_datetime.getMinutes() +
      ":" +
      current_datetime.getSeconds();

    if (!this.seguimiento.contenedorRutasLlamadas.includes(suceso)) {
      this.seguimiento.contenedorRutasLlamadas.push(suceso);
      return this.http.post(`${this.baseUrl}/Sucesos`, {
        id_usuario,
        fecha_auditoria,
        ip_publica,
        suceso,
        infNav,
      });
    }
  }

  getBrowserInfo = function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  };

  traerBuzon(filtro) {
    return this.http.post(`${this.baseUrl}/Buzon`, { filtro });
  }

  traerModemBuzon() {
    return this.http.post(`${this.baseUrl}/BuzonModems`, null);
  }

  crearBuzon(IdModem, Mensaje, Fecha) {
    return this.http.post(`${this.baseUrl}/crearBuzon`, {
      IdModem,
      Mensaje,
      Fecha,
    });
  }

  EliminarPendienteDesc(IdPendiente, opcion) {
    let Fechalimite = new Date();
    var data = {
      IdPendiente,
      Fechalimite,
      opcion,
    };
    return this.http.post(`${this.baseUrl}/EliminarPendienteDesc`, data);
  }

  DetallesAlerta(idAlerta) {
    return this.http.post(`${this.baseUrl}/DetallesAlerta`, { idAlerta });
  }

  InsertarDetallesAlerta(idAlerta, idUsuario, comentario, fecha) {
    return this.http.post(`${this.baseUrl}/GuardarDetallesAlerta`, {
      idAlerta,
      idUsuario,
      comentario,
      fecha,
    });
  }
  
  GuardarParametrosGenerales(CantCamaras = null, CantDescargas = null, ManualUsuario = null, ValoresDegradadoMapa = null) {
    return this.http.post(`${this.baseUrl}/GuardarParametrosGenerales`, {
      CantCamaras,
      CantDescargas,
      ManualUsuario,
      ValoresDegradadoMapa
    });
  }


  GuardarParametrosMunicipio(ValoresDegradadoMapa,idMunicipio) {
    return this.http.post(`${this.baseUrl}/GuardarParametrosMunicipio`, {
      idMunicipio,
      ValoresDegradadoMapa,
    });
  }


  TraerParametrosMunicipio(idMunicipio) {
    return this.http.post(`${this.baseUrl}/TraerParametrosMunicipio`, {
      idMunicipio
    });
  }

  traerInfoVelocidad(
    soporte1,
    soporte2,
    fechaI,
    fechaF,
    page,
    cantidad,
    infractor
  ) {
    return this.http.post(`${this.baseUrl}/TraerInfoVelocidad`, {
      soporte1,
      soporte2,
      fechaI,
      fechaF,
      page,
      cantidad,
      infractor,
    });
  }

  traerInfoCantidadVelocidad(soporte1, soporte2, fechaI, fechaF, matriculas) {
    return this.http.post(`${this.baseUrl}/traerInfoCantidadVelocidad`, {
      soporte1,
      soporte2,
      fechaI,
      fechaF,
      matriculas,
    });
  }

  traerInfoVelocidadTotalDatos(soporte1, soporte2, fechaI, fechaF) {
    return this.http.post(`${this.baseUrl}/TraerInfoVelocidadTotalDatos`, {
      soporte1,
      soporte2,
      fechaI,
      fechaF,
    });
  }
  traerInfoVelocidadMunicipiosHabilitados(id) {
    return this.http.post(
      `${this.baseUrl}/TraerInfoVelocidadMunicipiosHabilitados`,
      { id }
    );
  }


  soporteMapa3(idMunicipio) {
    const data = {
      idMunicipio: idMunicipio,
    };
    return this.http.post(`${this.baseUrl}/Soportes3`, data);
  }

  traerIdCamarasMunicipio(idMunicipio) {
    return this.http.post(`${this.baseUrl}/TraerIdCamarasMunicipio`, {
      idMunicipio,
    });
  }
  TraerGraficoMatricula(camaras, tipo, fecha, titulo) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/TraerGraficoMatricula`, {
      camaras,
      tipo,
      fecha,
      idioma,
      titulo,
    });
  }
  TraerGraficoMatricula2(camaras, tipo, fecha, titulo) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/TraerGraficoMatricula2`, {
      camaras,
      tipo,
      fecha,
      idioma,
      titulo,
    });
  }
  TraerGraficoVehiculos(camaras, tipo, fecha, titulo) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/TraerGraficoVehiculos`, {
      camaras,
      tipo,
      fecha,
      idioma,
      titulo,
    });
  }
  TraerGraficoVH(camaras, tipo, fecha, titulo) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/TraerGraficoVH`, {
      camaras,
      tipo,
      fecha,
      idioma,
      titulo,
    });
  }
  TraerTopMatricula(camaras, tipo, fecha) {
    return this.http.post(`${this.baseUrl}/TraerTopMatriculas`, {
      camaras,
      tipo,
      fecha,
    });
  }
  TraerResumenMatricula(camaras, tipo, fecha) {
    return this.http.post(`${this.baseUrl}/TraerResumenMatricula`, {
      camaras,
      tipo,
      fecha,
    });
  }
  traerInfoVelocidadTramos(municipio) {
    return this.http.post(`${this.baseUrl}/TraerInfoVelocidadTramos`, {
      municipio,
    });
  }

  traerInfoVelocidadEventosMatricula(
    soporte1,
    soporte2,
    fechaI,
    fechaF,
    matricula
  ) {
    return this.http.post(
      `${this.baseUrl}/TraerInfoVelocidadEventosMatricula`,
      { soporte1, soporte2, fechaI, fechaF, matricula }
    );
  }

  TraerGraficoEfectividad(FechaI, FechaF, Municipio, Tipo) {
    return this.http.post(`${this.baseUrl}/TraerGraficoReporteCamaras`, {
      FechaI,
      FechaF,
      Municipio,
      Tipo,
    });
  }

  traerSoportesMunicipio(municipio) {
    return this.http.post(`${this.baseUrl}/TraerSoportesMunicipio`, {
      municipio,
    });
  }

  guardarTramo(data: any) {
    return this.http.post(`${this.baseUrl}/GuardarTramo`, data);
  }

  /**
   * Traer detalle de matricula
   * @param matricula matricula del vehiculo
   * @param camaras camaras
   * @param fecha fecha
   * @param tipo tipo
   * @returns Observable
   */
  DetalleMatricula( matricula: string, camaras: string, fecha: string, tipo: number ): Observable<LicensePlateDetails[]> {
    return this.http.post<any>(`${this.baseUrl}/DetalleMatricula`, {
      matricula,
      camaras,
      fecha,
      tipo,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }

  traerInfoGeneralAuditoria(fechaI, fechaF) {
    return this.http.post(`${this.baseUrl}/TraerInfoGeneralAuditoria`, {
      fechaI,
      fechaF,
    });
  }

  traerInfoAuditoriaUsuario(usuario, fechaI, fechaF) {
    return this.http.post(`${this.baseUrl}/TraerInfoAuditoriaUsuario`, {
      usuario,
      fechaI,
      fechaF,
    });
  }
  ObtenerMatriculas(camaras, tipo, fecha, offset, pageSize, calculateCount) {
    return this.http.post(`${this.baseUrl}/ObtenerMatriculas`, {
      camaras,
      tipo,
      fecha,
      offset,
      pageSize,
      calculateCount,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }

  TraerImagenSoporte(Archivo) {
    const data = {
      Archivo: Archivo,
    };
    return this.http.post(`${this.baseUrl}/TraerImagenSoporte`, data);
  } 

  TraerGraficoVehiculosTramo(tramo, tipo, fecha, titulo) {
    var idioma = this.fsession._currentLang.value;

    return this.http.post(`${this.baseUrl}/TraerGraficoVehiculosTramo`, {
      tramo,
      tipo,
      fecha,
      idioma,
      titulo,
    });
  }
  ObtenerMatriculasTramo(tramo, tipo, fecha) {
    return this.http.post(`${this.baseUrl}/ObtenerMatriculasTramo`, {
      tramo,
      tipo,
      fecha,
    });
  }
  TraerDetMatriculaTramo(Fecha, CategoriaConsulta, Tramo, Matricula) {
    return this.http.post(`${this.baseUrl}/TraerDetMatriculaTramo`, {
      Fecha,
      CategoriaConsulta,
      Tramo,
      Matricula,
    });
  }

  /**
   * Traer informacion velocidad tramos 2
   * @param municipio id de muncipio
   * @param activo boolean
   * @param todos numero puede ser 0 || 1
   * @param filter filtro para realizar la busqueda por la descripcion del tramo
   * @param pageIndex indice actual de la paginacion
   * @param pageSize cantidad de registro por paginas
   * @returns Observable
   */
  traerInfoVelocidadTramos2(municipio: string | number, activo: any, todos: number, filter = '', pageIndex = 0, pageSize = 10, sort = {active: 'descripcion', direction: ''}) {
    return this.http.post(`${this.baseUrl}/TraerInfoVelocidadTramos2`, {
      municipio,
      activo,
      todos,
      filter,
      pageIndex,
      pageSize,
      sort
    });
  }

  guardarTramo2(data: any) {
    return this.http.post(`${this.baseUrl}/GuardarTramo2`, data);
  }

  SubirFotoTramo(FotoNueva, name) {
    let form: FormData = new FormData();
    form.append("Archivo", FotoNueva, name);
    return this.http.post(`${this.baseUrl}/SubirFotoTramo`, form);
  } 

  TraerImagenTramo(Archivo) {
    const data = {
      Archivo: Archivo,
    };
    return this.http.post(`${this.baseUrl}/TraerImagenTramo`, data);
  } 

  ElimininarImagenTramo(Archivo) {
    const data = {
      Archivo: Archivo,
    };
    return this.http.post(`${this.baseUrl}/EliminarFotoTramo`, data);
  } 

  obtenerParametrosTramo() {
    return this.http.post(`${this.baseUrl}/obtenerParametrosTramo`, {});
  } 

  TraerParaMetrosVelocidades(velMax) {
    const data = { velMax };

    return this.http.post(`${this.baseUrl}/ParaMetrosVelocidades`, data);
  } 

  GuardarParaMetrosVelocidades(velMax, UmMin, UmMax) {
    const data = { velMax, UmMin, UmMax };

    return this.http.post(`${this.baseUrl}/AgregarParaMetrosVelocidades`, data);
  } 

  ModificarParaMetrosVelocidades(Id, velMax, UmMin, UmMax) {
    const data = { Id, velMax, UmMin, UmMax };

    return this.http.post(
      `${this.baseUrl}/ModificarParaMetrosVelocidades`,
      data
    );
  } 

  EliminarParaMetrosVelocidades(Id) {
    const data = { Id };

    return this.http.post(`${this.baseUrl}/EliminarParametroVelocidad`, data);
  } 

  traerInfoMainMap(municipio, fecha) {
    const data = { municipio, fecha };

    return this.http.post(`${this.baseUrl}/traerInfoMainMap`, data);
  } 

  /** 
   * Obtener informacion de tramos en el mapa
   * @param fecha fecha del evento
   * @param agrupacion number que representa la agrupacion
   * @param camaras array de camaras
   * @returns obervable con la informacion
   */
  obtenerInfoTramoMainMap( fecha: string, agrupacion: number, camaras: any[], idTramo: number = null ): Observable<[{ ID_CAMARA: string, VMAX: number, VMEDIA: number, EXCESOS: number }]> 
  {
    const data = { fecha, agrupacion, camaras, idTramo };
    return this.http.post<any>(`${this.baseUrl}/obtenerInfoTramoMainMap`, data);
  } 

  /**
   * Trae informacion de camaras por fecha
   * @param fecha fecha del evento
   * @param camaras arreglo con los ids de las camras 
   * @param tiempo numero
   * @returns Observable
   */
  traerInfoEventoCamaraPorFecha(fecha: string, camaras: [{id_camara: string}], tiempo: number, zonaHoraria): Observable<{Camaras: any[], mensaje: string}> 
  {
    const data = { fecha, camaras, tiempo, zonaHoraria };
    return this.http.post<any>( `${this.baseUrl}/traerInfoEventoCamaraPorFecha`,data);
  }   

  /**
   * Obtener informacion de tramos en el mapa (vehiculos y flujo de velocidad)
   * @param camaras array de camaras
   * @param tipo number
   * @param fecha fecha del evento
   * @returns Observab con la informacion
   */
  traerInfoTraficoMainMap(camaras: any[], tipo: number, fecha: string): Observable<Traffic[]> {
    const data = { camaras, tipo, fecha };
    return this.http.post<Traffic[]>(`${this.baseUrl}/traerInfoTraficoMainMap`, data);
  } 

  /**
   * Obtiene las listas de interés asociadas a un municipio
   * @param municipio id de municipio
   * @param activo estado
   * @param filter filtro que busca por descripcion de la lista
   * @param pageIndex indice de la pagina actual
   * @param pageSize cantidad de resgitros a obtener
   * @returns Observable
   */
  obtenerListaVehiculos(municipio: string | number, activo: boolean, filter = '', pageIndex: number, pageSize: number, sort = { active: 'descripcion', direction: '' }) {
    const data = { 
      municipio, 
      activo, 
      filter,
      pageIndex,
      pageSize,
      sort
    };
    return this.http.post(`${this.baseUrl}/obtenerListaVehiculos`, data);
  } 

  agregarListaVehiculos(
    descripcion,
    municipio,
    tipo,
    prioridad,
    notificacion_pantalla,
    notificacion_telegram,
    registrar,
    municipios_aliados,
    contactos_notificados,
    fecha_vigencia_inicial,
    fecha_vigencia_final,
    con_vigencia,
    propietario_lista,
  ) {
    const data = {
      descripcion,
      municipio,
      tipo,
      prioridad,
      notificacion_pantalla,
      notificacion_telegram,
      registrar,
      municipios_aliados,
      contactos_notificados,
      fecha_vigencia_inicial,
      fecha_vigencia_final,
      con_vigencia,
      propietario_lista,
    };

    return this.http.post(`${this.baseUrl}/agregarListaVehiculos`, data);
  } 

  actualizarListaVehiculos(
    descripcion,
    activo,
    tipo,
    prioridad,
    id,
    notificacion_pantalla,
    notificacion_telegram,
    registrar,
    municipios_aliados,
    contactos_notificados,
    fecha_vigencia_inicial,
    fecha_vigencia_final,
    con_vigencia,
  ) {
    const data = {
      descripcion,
      activo,
      tipo,
      prioridad,
      id,
      notificacion_pantalla,
      notificacion_telegram,
      registrar,
      municipios_aliados,
      contactos_notificados,
      fecha_vigencia_inicial,
      fecha_vigencia_final,
      con_vigencia,
    };

    return this.http.post(`${this.baseUrl}/actualizarListaVehiculos`, data);
  } 

  /**
   * Obtiene la lista de vehículos relacionados por conjunto (id) con paginación
   * @param id Número del conjunto
   * @param active Booleano para filtrar por estado activo
   * @param pageIndex Índice de la página para la paginación
   * @param pageSize Tamaño de la página para la paginación
   * @param fetchAll Booleano para obtener todos los datos
   * @returns Observable
   */
  obtenerListaVehiculosConjunto(id: number, active: boolean, pageIndex: number, pageSize: number, fetchAll: boolean): Observable<any> {
    const data = { id, active, pageIndex, pageSize, fetchAll };

    return this.http.post(`${this.baseUrl}/obtenerListaVehiculosConjunto`, data);
  }

  /**
   * Actualiza matricula de la lista de vehiculos conjunto
   * @param matricula matricula del coche
   * @param color color del coche
   * @param modelo modelo del coche
   * @param observaciones observaciones extra
   * @param idLista id de la lista
   * @param id id del registro
   * @param activo bandera que representa si esta activa o no la matricula en la lista
   * @returns Observable
   */
  actualizarListaVehiculosConjunto(
    matricula: string,
    color: string,
    modelo: string,
    observaciones: string,
    idLista: number,
    id: number,
    activo: boolean,
    fecha_vigencia_inicial: string,
    fecha_vigencia_final: string,
    con_vigencia: boolean,
  ) {
    const data = { matricula, color, modelo, observaciones, idLista, id, activo, fecha_vigencia_inicial, fecha_vigencia_final, con_vigencia};

    return this.http.post(
      `${this.baseUrl}/actualizarListaVehiculosConjunto`,
      data
    );
  } 

  /**
   * Agrega un nuevo registro de matricula a una lista de interés
   * @param matriculas matricula del coche
   * @param color color del coche
   * @param modelo modelo del coche
   * @param observaciones observaciones extra
   * @param idLista id de la lista
   * @returns Observable
   */
  agregarMatriculaListaVehiculosConjunto(
    matriculas: string,
    color: string,
    modelo: string,
    observaciones: string,
    idLista: number,
    activo: boolean,
    fecha_vigencia_inicial: any,
    fecha_vigencia_final: any,
    con_vigencia: boolean,
  ) {
    const data = { matriculas, color, modelo, observaciones, idLista, activo, fecha_vigencia_inicial, fecha_vigencia_final, con_vigencia};
    return this.http.post(`${this.baseUrl}/agregarMatriculaListaVehiculosConjunto`,data);
  }
  
  /**
   * Verifica si una matricula ya existe en una determinada lista de interes
   * @param plate matricula 
   * @param idList id de la lista de interes
   * @returns Observable
   */
  getRegistrationPlateVehicleListSet( plate: string, idList: number ): Observable<{ plate: VehiculoListaInteres[] }> {
    return this.http.get<any>(`${this.baseUrl}/getRegistrationPlateVehicleListSet/${plate}/${idList}`);
  }

  agregarListaVehiculosConjunto(matriculas, idLista) {
    const data = { matriculas, idLista };
    return this.http.post(
      `${this.baseUrl}/importarListaVehiculosConjunto`,
      data
    );
  } 

  obtenerListaMayorPrioridad(id_municipio) {
    const data = { id_municipio };
    return this.http.post(`${this.baseUrl}/obtenerListaMayorPrioridad`, data);
  } 

  obtenerMatriculaPorListas(idListas) {
    const data = { idListas };
    return this.http.post(`${this.baseUrl}/obtenerMatriculaPorListas`, data);
  } 

  obtenerListasPorMatricula(matricula) {
    const data = { matricula };
    return this.http.post(`${this.baseUrl}/obtenerListasPorMatricula`, data);
  } 

  obtenerSoportesMunicipioCamaraMatricula(municipio) {
    const data = { municipio };


    return this.http.post(
      `${this.baseUrl}/obtenerSoportesMunicipioCamaraMatricula`,
      data
    );
  } 

  /**
   * Obtiene zbe por municipio
   * @param municipio id de municipio
   * @param activo boolean
   * @param filter filtro de busqueda por nombre
   * @param pageIndex Indice de la pagina actual
   * @param pageSize Cantidad de registros a obtener
   * @returns observable
   */
  ObtenerZbePorMunicipio( municipio: string | number, activo: boolean, filter: string, pageIndex: number, pageSize: number, sort = { active: 'z.nombre', direction: '' } ) {
    const data = { 
      municipio, 
      activo, 
      filter, 
      pageIndex, 
      pageSize,
      sort
    };
    return this.http.post(`${this.baseUrl}/obtenerZbePorMunicipio`, data);
  } 

  /**
   * Crea una ZBE asociada a un municipio 
   * @param municipio id del municipio
   * @param nombre nombre del municipio
   * @param notificacion_pantalla tiene notificaciones por pantalla
   * @param notificacion_telegram tiene notificaciones por telegram
   * @param distintivos_ambientales distintivos ambientale seleccionados para esta zona
   * @param control_horario control de horario
   * @param comunicacion_infracciones comunicacion de infracciones
   * @returns Observable
   */
  crearZonaZbe(
    municipio: any,
    nombre: string,
    notificacion_pantalla: boolean,
    notificacion_telegram: boolean,
    distintivos_ambientales: string,
    control_horario: string,
    comunicacion_infracciones: string
  ) {
    const data = {
      municipio,
      nombre,
      notificacion_pantalla,
      notificacion_telegram,
      distintivos_ambientales,
      control_horario,
      comunicacion_infracciones
    };
    return this.http.post(`${this.baseUrl}/crearZonaZbe`, data);
  } 

  /**
   * Actualiza una ZBE
   * @param nombre Nombre del zbe
   * @param activo Estado del zbe
   * @param id id de ZBE
   * @param notificacion_pantalla tiene notificaciones por pantalla
   * @param notificacion_telegram tiene notificaciones por telegram
   * @param distintivos_ambientales distintivos ambientale seleccionados para esta zona
   * @param control_horario control de horario
   * @param comunicacion_infracciones comunicacion de infracciones
   * @returns Observable
   */
  actualizarZonaZbe(
    nombre: string,
    activo: boolean,
    id: number,
    notificacion_pantalla: boolean,
    notificacion_telegram: boolean,
    distintivos_ambientales: string,
    control_horario: string,
    comunicacion_infracciones: string
  ) {
    const data = {
      nombre,
      activo,
      id,
      notificacion_pantalla,
      notificacion_telegram,
      distintivos_ambientales,
      control_horario,
      comunicacion_infracciones
    };
    return this.http.post(`${this.baseUrl}/actualizarZonaZbe`, data);
  } 

  agregarCamarasZbe(soportes, idZbe) {
    const data = { soportes, idZbe };
    return this.http.post(`${this.baseUrl}/agregarCamarasZbe`, data);
  } 

  agregarListasZbe(listas, idZbe) {
    const data = { listas, idZbe };
    return this.http.post(`${this.baseUrl}/agregarListasZbe`, data);
  } 

  obtenerCamarasZbe(idZbe) {
    const data = { idZbe };
    return this.http.post(`${this.baseUrl}/obtenerCamarasZbe`, data);
  }

  obtenerListasZbe(idZbe) {
    const data = { idZbe };
    return this.http.post(`${this.baseUrl}/obtenerListasZbe`, data);
  }

  obtenerRutas(activo) {
    var data = { activo };

    return this.http.post(`${this.baseUrl}/obtenerRutas`, data);
  }

  crearRuta(nombre, ruta, tipo) {
    var data = { nombre, ruta, tipo };

    return this.http.post(`${this.baseUrl}/crearRuta`, data);
  }

  actualizarRuta(nombre, ruta, tipo, activo, id) {
    var data = { nombre, ruta, tipo, activo, id };

    return this.http.post(`${this.baseUrl}/actualizarRuta`, data);
  }

  obtenerRoles(activo, nombre) {
    var data;
    if(nombre && nombre != null) data = { activo, nombre };
    else data = { activo };
  
    return this.http.post(`${this.baseUrl}/obtenerRoles`, data);
  }

  crearRol(nombre, descripcion, permiso) {
    var data = { nombre, descripcion, permiso };

    return this.http.post(`${this.baseUrl}/crearRol`, data);
  }

  actualizarRol(nombre, activo, permiso, global, ocultar, id) {
    var data = { nombre, activo, permiso, global, ocultar, id };

    return this.http.post(`${this.baseUrl}/actualizarRol`, data);
  }

  obtenerTiposUsuario(activo = 1) {
    var data = { activo };
    return this.http.post(`${this.baseUrl}/obtenerTiposUsuario`, data);
  }

  crearTipoUsuario(nombre) {
    var data = { nombre };
    return this.http.post(`${this.baseUrl}/crearTipoUsuario`, data);
  }

  actualizarTipoUsuario(nombre, activo, id) {
    var data = { nombre, activo, id };
    return this.http.post(`${this.baseUrl}/actualizarTipoUsuario`, data);
  }

  obtenerRutasPorRol(id_rol) {
    var data = { id_rol };

    return this.http.post(`${this.baseUrl}/obtenerRutasPorRol`, data);
  }

  crearRutasPorRol(id_rol, id_ruta) {
    var data = { id_rol, id_ruta };

    return this.http.post(`${this.baseUrl}/crearRutasPorRol`, data);
  }

  eliminarRutasPorRol(id_rol, id_ruta) {
    var data = { id_rol, id_ruta };

    return this.http.post(`${this.baseUrl}/eliminarRutasPorRol`, data);
  }

  traerUltImagenesVisorAdmin(cantidad, ArrayCamaras) {
    return this.http.post(`${this.baseUrl}/TraerUltImagenesVisorAdmin`, {
      cantidad,
      ArrayCamaras,
    });
  } 

  /**
   * Trae lista de fotor rojo
   * @param municipio id de municipio
   * @param activo estado
   * @param nombre nombre
   * @param pageIndex indice de pagina actual
   * @param pageSize cantidad de registros a obtener
   * @returns observable
   */
  traerFotoRojo( municipio, activo, nombre, pageIndex: number, pageSize: number, sort = { active: '', direction: '' } ) {
    let data = { municipio, activo, nombre, pageIndex, pageSize, sort};

    return this.http.post(`${this.baseUrl}/traerFotoRojo`, data);
  }

  crearFotoRojo(
    nombre,
    camara,
    router,
    municipio,
    segundos_deteccion_ini,
    segundos_deteccion_fin
  ) {
    let data = {
      nombre,
      camara,
      router,
      municipio,
      segundos_deteccion_ini,
      segundos_deteccion_fin,
    };

    return this.http.post(`${this.baseUrl}/crearFotoRojo`, data);
  }

  actualizarFotoRojo(
    id,
    nombre,
    camara,
    router,
    activo,
    segundos_deteccion_ini,
    segundos_deteccion_fin
  ) {
    let data = {
      id,
      nombre,
      camara,
      router,
      activo,
      segundos_deteccion_ini,
      segundos_deteccion_fin,
    };

    return this.http.post(`${this.baseUrl}/actualizarFotoRojo`, data);
  }

  /**
   * Obtiene las cámaras de paso cebra asociadas a un municipio
   * @param municipio id del Municipio
   * @param activo Estado
   * @param pageIndex indice de la pagina actual
   * @param pageSize cantidad de registros a obtener
   * @param search coincidencia por nombre
   * @returns Observable
   */
  traerFotoCebra(municipio: string | number, activo: boolean, pageIndex: number, pageSize: number, search: string, sort = { active: '', direction: '' }) {
    let data = { municipio, activo, pageIndex, pageSize, search, sort };
    return this.http.post(`${this.baseUrl}/traerFotoCebra`, data);
  }

  crearFotoCebra(
    nombre,
    camara_1,
    router_1,
    camara_2,
    router_2,
    municipio,
    segundos_deteccion_ini_1,
    segundos_deteccion_fin_1,
    segundos_deteccion_ini_2,
    segundos_deteccion_fin_2,
    carril_1,
    carril_2,
    entrada_1,
    entrada_2
  ) {
    let data = {
      nombre,
      camara_1,
      router_1,
      camara_2,
      router_2,
      municipio,
      segundos_deteccion_ini_1,
      segundos_deteccion_fin_1,
      segundos_deteccion_ini_2,
      segundos_deteccion_fin_2,
      carril_1,
      carril_2,
      entrada_1,
      entrada_2
    };
    return this.http.post(`${this.baseUrl}/crearFotoCebra`, data);
  }

  actualizarFotoCebra(
    id,
    nombre,
    camara_1,
    router_1,
    camara_2,
    router_2,
    activo,
    segundos_deteccion_ini_1,
    segundos_deteccion_fin_1,
    segundos_deteccion_ini_2,
    segundos_deteccion_fin_2,
    carril_1,
    carril_2,
    entrada_1,
    entrada_2
  ) {
    let data = {
      id,
      nombre,
      camara_1,
      router_1,
      camara_2,
      router_2,
      activo,
      segundos_deteccion_ini_1,
      segundos_deteccion_fin_1,
      segundos_deteccion_ini_2,
      segundos_deteccion_fin_2,
      carril_1,
      carril_2,
      entrada_1,
      entrada_2
    };
    return this.http.post(`${this.baseUrl}/actualizarFotoCebra`, data);
  }

  /**
   * Obtiene url de conexion
   * @param idMunicipality id del municipio
   * @returns Observable
   */
  getURLConnection( idMunicipality: number ): Observable<NotificationConnection> {
    return this.http.post<any>(`${this.baseUrl}/obtenerURLConexion`, { idMunicipality });
  }

  TraerArchivoAlerta(Archivo) {
    const data = {
      Archivo: Archivo,
    };
    return this.http.post(`${this.baseUrl}/TraerArchivoAlerta`, data);
  }

  /**
   * Trae el historial no notificaciones enviado al municipio en un rango específico.
   * @param fechaIni Fecha inicio del rango
   * @param fechaFin Fecha fin del rango
   * @param municipio id del Municipio
   * @param pageIndex indice de pagina actual
   * @param pageSize cantidad de regitros a obtener
   * @returns observable
   */
  traerRegistroNotificacionesMunicipio( 
    fechaIni: string, 
    fechaFin: string, 
    municipio: string | number, 
    pageIndex: number, 
    pageSize: number,
    sort = { active: 'z.nombre', direction: '' }
  ) {
    let data = { fechaIni, fechaFin, municipio, pageIndex, pageSize, sort };
    return this.http.post(`${this.baseUrl}/registroNotificaciones`, data);
  }

  obtenerUrlInfraccion(archivo_ZIP, confirmada) {
    return this.http.post(`${this.baseUrl}/obtenerUrlInfraccion`, {
      archivo_ZIP,
      confirmada
    });
  }

  marcarFalsoRojo(id_eventoscam, falso_rojo, tipo_infraccion) {
    let data = { id_eventoscam, falso_rojo, tipo_infraccion };

    return this.http.post(`${this.baseUrl}/marcarFalsoRojo`, data);
  }

  traerVariablesTelemetria() {
    return this.http.post(`${this.baseUrl}/traerVariablesTelemetria`, {});
  }

  traerTelemetriaRouter(router, filtroMedicion, fechaIni, fechaFin, municipio) {
    let data = { router, filtroMedicion, fechaIni, fechaFin, municipio };

    return this.http.post(`${this.baseUrl}/traerTelemetriaRouter`, data);
  }

  traerEtiquetaMedioambiental(matricula) {
    let data = { matricula };
    return this.http.post<{ url: string; nombre: string }>(
      `${this.baseUrl}/traerEtiquetaMedioambiental`,
      data
    );
  }

  crearPerfil(perfil: any) {
    let data = { perfil, idMunicipio: this.fsession.optenerValor("dato4") };
    return this.http.post(`${this.baseUrl}/crearPerfil`, data);
  }

  cargarPerfilesPorMunicipio(termino, activo) {
    let data = {
      idMunicipio: this.fsession.optenerValor("dato4"),
      termino,
      activo,
    };
    return this.http.post<Perfil[]>(
      `${this.baseUrl}/cargarPerfilesPorMunicipio`,
      data
    );
  }

  modificarPerfil(perfil: any) {
    let data = { perfil };
    return this.http.post<Perfil[]>(`${this.baseUrl}/modificarPerfil`, data);
  }

  crearPaquete(paquete: any) {
    const data = { paquete, idMunicipio: this.fsession.optenerValor("dato4") };
    return this.http.post(`${this.baseUrl}/crearPaquete`, data);
  }

  cargarPaquetesPorMunicipio(termino, activo) {
    const data = {
      idMunicipio: this.fsession.optenerValor("dato4"),
      termino,
      activo,
    };
    return this.http.post<any[]>(
      `${this.baseUrl}/cargarPaquetesPorMunicipio`,
      data
    );
  }

  modificarPaquete(paquete: any) {
    const data = { paquete };
    return this.http.post<any>(`${this.baseUrl}/modificarPaquete`, data);
  }

  traerFirmwaresBooloaders() {
    return this.http.get<any>(`${this.baseUrl}/traerFirmwaresBooloaders`);
  }

  agregarFirmwareBootloader(data, archivo) {
    return this.http.post<any>(`${this.baseUrl}/agregarFirmwareBootloader`, {
      ...data,
      archivo,
    });
  }

  cargarFirmwareBootloader(formData) {
    return this.http.post<any>(
      `${this.baseUrl}/cargarFirmwareBootloader`,
      formData
    );
  }

  lecturasBruto(routerId) {
    return this.http.post(`${this.baseUrl}/lecturasBrutoClima`, { routerId });
  }

  lecturasCuartaHoraria(routerId) {
    return this.http.post(`${this.baseUrl}/lecturasCuartaHorariaClima`, {
      routerId,
    });
  }

  lecturasHoraClima(routerId, fecha, tipoLectura = "avg") {
    var idioma = this.fsession.optenerValor("language");
    return this.http.post(`${this.baseUrl}/lecturasHoraClima`, {
      routerId,
      fecha,
      tipoLectura,
      idioma,
    });
  }

  traerTelemetriaCargaSolar(
    router,
    filtroMedicion,
    fechaIni,
    fechaFin,
    municipio
  ) {
    return this.http.post(`${this.baseUrl}/lecturasCargaSolar`, {
      idRouter: router,
      filtroMedicion,
      fechaIni,
      fechaFin,
      municipio,
    });
  }

  traerTelemetriaClima(router, filtroMedicion, fechaIni, fechaFin, municipio) {
    let data = { 
      router,
      filtroMedicion,
      fechaIni,
      fechaFin,
      municipio,
      zonaHoraria: this.fsession.optenerValor("dato41"),
      };
    return this.http.post(`${this.baseUrl}/traerTelemetriaClima`, data);
  }

  obtenerModulos() {
    return this.http.get(`${this.baseUrl}/obtenerPaqueteRutas`);
  }

  obtenerModulosMunicipio(IdMunicipio) {
    var data = { IdMunicipio };
    return this.http.post(`${this.baseUrl}/obtenerModulosMunicipio`, data);
  }

  actualizarModuloMunicipio(id_municipio, permission) {
    var data = { id_municipio, permission };
    return this.http.post(`${this.baseUrl}/actualizarModuloMunicipio`, data);
  }

  getRolePermission() {
    return this.http.get(`${this.baseUrl}/getRolePermission`);
  }

  getModules() {
    return this.http.get(`${this.baseUrl}/getModules`);
  }

  updateRolePermission(id_rol, permission) {
    var data = { id_rol, permission };
    return this.http.post(`${this.baseUrl}/updateRolePermission`, data);
  }

  updateRoles(id, nombre, descripcion) {
    var data = { id, nombre, descripcion };
    return this.http.post(`${this.baseUrl}/updateRoles`, data);
  }

  /**
   * Obtiene los cálculos de detecciones de matrículas de cámaras con paginación.
   * @param idMunicipio Identificador del municipio.
   * @param fechaI Fecha de inicio.
   * @param fechaF Fecha de fin.
   * @param camarasMatricula Lista de cámaras.
   * @param pageIndex Índice de la página actual para la paginación.
   * @param pageSize Cantidad de registros por página.
   * @returns Observable con los datos de detecciones.
   */
  traerCalculosDetecciones(idMunicipio, fechaI, fechaF, camarasMatricula, pageIndex, pageSize) {
    const data = {
      idMunicipio,
      fechaI,
      fechaF,
      camarasMatricula,
      zonaHoraria: this.fsession.optenerValor("dato41"),
      pageIndex,
      pageSize
    };
    return this.http.post<any>(`${this.baseUrl}/traerCalculosDetecciones`, data);
  }

  obtenerUrlManual() {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/obtenerUrlManualUsuario`, {
      idioma,
    });
  }

  subirManuales(formData) {
    return this.http.post(`${this.baseUrl}/cargarManualesUsuario`, formData);
  }

  importarUsuarioAzure(usuario) {
    return this.http.post(`${this.baseUrl}/importarUsuarioAzure`, {
      ...usuario,
    });
  }

  getModulosPermisosRol(IdUsuario: number, IdMunicipio: number) {
    const data = { IdUsuario, IdMunicipio };
    return this.http.post(`${this.baseUrl}/getModulosPermisosRol`, data);
  }

  getPermisosXrol(id_rol: number){
    const data = {id_rol}
    return this.http.post(`${this.baseUrl}/getPermisosXrol`, data);
  }

  getNamesUserRolPorMunicipio(idMunicipio){
    const data = {idMunicipio}
    return this.http.post(`${this.baseUrl}/getNamesUserRolPorMunicipio`, data);
  }

  getEstadoMatricula(){
    return this.http.get(`${this.baseUrl}/getEstadoMatricula`);
  }

   /**
   * Retorna si un usuario es superadmin o no
   * @param idUsuario id del usuario
   * @returns observable
   */
  getUserIsSuperAdmin(idUsuario: number): Observable<{ isSuperAdmin: boolean }> {
    return this.http.get<any>(`${this.baseUrl}/getUserIsSuperAdmin/${idUsuario}`)
  }

  /**
   * Determina si un usuario es administrador de un municipio
   * @param idUsuario id de usuario
   * @param idMunicipio id de municipio
   * @returns observable
   */
  getIsMunicipalityAdministratorUser(idUsuario: number, idMunicipio: number): Observable<{ isAdmin: boolean }> {
    return this.http.get<any>(`${this.baseUrl}/getIsMunicipalityAdministratorUser/${idUsuario}/${idMunicipio}`);
  }

  /**
   * Trae las detecciones para una matrícula en un rango determinado en todo el municipio
   * @param matricula Matricula
   * @param fecha Fecha a Consultar
   * @param tipo Rango de fecha
   * @returns observable
   */
  TrazabilidadMatricula(matricula, fecha, tipo, idmunicipio) {
    return this.http.post(`${this.baseUrl}/TrazabilidadMatricula`, {
      matricula,
      fecha,
      tipo,
      zonaHoraria: this.fsession.optenerValor("dato41"),
      idmunicipio
    });
  }

  ObtenerDetecciones( IdMunicipio: number, id_router: number, Filtro: any, StartDate: string, EndDate: string) {
    const data = { IdMunicipio, id_router, Filtro, StartDate, EndDate, zonaHoraria: this.fsession.optenerValor("dato41"),};
    return this.http.post(`${this.baseUrl}/ObtenerDetecciones`, data);
  }
  
  traerGraficosAforo(acceso, tipo, fecha, titulo, fechaFin = null, pais, vehiculo, pegatina) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/traerGraficosAforo`, {
      acceso,
      tipo,
      fecha,
      idioma,
      titulo,
      fechaFin,
      pais,
      vehiculo,
      pegatina,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }

  /**
   * Trae los graficos de aforo para Zona ZBE Estadística
   * @param acceso Acceso a la grafica
   * @param tipo Tipo de grafico
   * @param fecha Fecha a consultar
   * @param titulo Titulo de la grafica
   * @param fechaFin Fecha fin para el rango de fechas
   * @param pais Pais
   * @param vehiculo Vehiculo
   * @param pegatina Pegatina
   * @returns observable
   */
  traerGraficosAforoZBE(acceso, tipo, fecha, titulo, fechaFin = null, pais, vehiculo, pegatina) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/traerGraficosAforoZBE`, {
      acceso,
      tipo,
      fecha,
      idioma,
      titulo,
      fechaFin,
      pais,
      vehiculo,
      pegatina,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }
  
  traerGraficosPersonas(origen, agregacion, fecha, tituloTooltips, fechaFin = null, zoneId, groupName) {
    const idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/traerGraficosPersonas`, {
      origen,
      agregacion,
      fecha,
      idioma,
      tituloTooltips,
      fechaFin,
      zoneId,
      groupName,
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }

  traerGraficosRemolques(acceso, tipo, fecha, titulo, fechaFin = null) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/traerGraficosRemolques`, {
      acceso,
      tipo,
      fecha,
      idioma,
      titulo,
      fechaFin,
      idMunicipio: this.fsession.optenerValor("dato4"),
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }

  traerDeteccionesRemolque(matricula, fecha, fechaFin = null, agregacion, tipo) {
    return this.http.post(`${this.baseUrl}/traerDeteccionesRemolque`, {
      matricula,
      fecha,
      fechaFin,
      idMunicipio: this.fsession.optenerValor("dato4"),
      zonaHoraria: this.fsession.optenerValor("dato41"),
      agregacion,
      tipo,
    });
  }

  traerDeteccionesParking(matricula, fecha, fechaFin = null, agregacion, id_parking) {
    return this.http.post(`${this.baseUrl}/traerDeteccionesParking`, {
      matricula,
      fecha,
      fechaFin,
      idMunicipio: this.fsession.optenerValor("dato4"),
      zonaHoraria: this.fsession.optenerValor("dato41"),
      agregacion,
      id_parking,
    });
  }

  exportarDatosAforo(acceso, tipo, fecha, titulo, fechaFin = null) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/exportarDatosAforo`, {
      acceso,
      tipo,
      fecha,
      idioma,
      titulo,
      fechaFin,
      idMunicipio: this.fsession.optenerValor("dato4"),
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }

  refrescarEstadisticas() {
    return this.http.post(`${this.baseUrl}/refrescarEstadisticas`, {});
  }

  traerMatriculasAforo(acceso, tipo, fecha) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/traerMatriculasAforo`, {
      acceso,
      tipo,
      fecha,
      idioma,
      idMunicipio: this.fsession.optenerValor("dato4"),
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }

  /**
   * 
   * @param id_rol identificado de rol
   * @param pageIndex Index actual de la paginacion
   * @param pageSize cantidad de registro por paginas
   * @returns observable
   */
  getUsersXmunicipioXrol(id_rol, pageIndex, pageSize){
    const data = {id_rol, pageIndex, pageSize}
    return this.http.post<any>(`${this.baseUrl}/getUsersXmunicipioXrol`, data);
  }
  
  /**
   * Obtiene la informacion de las matriculas de paso cebra 
   * @param dateInit tiempo inicial
   * @param idZebraCrossing id del paso cebra seleccionado
   * @returns Observable
   */
  getLicensePlatesZebraCrossing( dateInit: Moment, idZebraCrossing: number ): Observable<{ result: RespuestaSimulacionPasoCebra[] }> {
    return this.http.get<any>(`${this.baseUrl}/getLicensePlatesZebraCrossing/${dateInit}/${idZebraCrossing}`);
  }

  /**
   * Obtener certificado
   * @param name nombre del certificado
   * @returns 
   */
  getCertificados(name){
    const data = { name: name}
    return this.http.post<any>(`${this.baseUrl}/DescargarCertificacion`, data);
  }

  addContacts(IdMunicipio, nombre_contacto, numero_contacto, activo, alarma_telemetria, alarma_infracciones, alarma_lista){
    const data = { IdMunicipio, nombre_contacto, numero_contacto, activo, alarma_telemetria, alarma_infracciones, alarma_lista}
    return this.http.post<any>(`${this.baseUrl}/addContacts`, data);
  }

  updateContacts(idContacto, nombre_contacto, numero_contacto, activo, alarma_telemetria, alarma_infracciones, alarma_lista){
    const data = { idContacto, nombre_contacto, numero_contacto, activo, alarma_telemetria, alarma_infracciones, alarma_lista}
    return this.http.post<any>(`${this.baseUrl}/updateContacts`, data);
  }

  /**
   * Trae los contacto del municipio actual (si no se le pasa id) o los del municipio especificado
   * 
   * @param IdMunicipio Id d el municipio
   * @param pageIndex Index de la página a mostrar
   * @param pageSize Tamaño de la página
   * @param alarmaLista Incluir contactos con alarmas de lista (Negra y Blanca)
   * @param alarmaIntrusion Incluir contactos con alarmas de intrusión
   * @param alarmaTelemetria Incluir contactos con alarmas de telemetría
   */
  getAllContacts(IdMunicipio, pageIndex, pageSize){   
    let data: any = {pageIndex, pageSize};

    if (IdMunicipio){
      data = {...data, IdMunicipio };
    }
    
    return this.http.post<any>(`${this.baseUrl}/getAllContacts`, data);
  }

  /**
   * Trae los contacto disponibles para listas de interés del municipio actual (si no se le pasa id) o los del municipio especificado
   * 
   * @param IdMunicipio Id d el municipio
   * @param pageIndex Index de la página a mostrar
   * @param pageSize Tamaño de la página
   * @param alarmaLista Incluir contactos con alarmas de lista (Negra y Blanca)
   * @param alarmaIntrusion Incluir contactos con alarmas de intrusión
   * @param alarmaTelemetria Incluir contactos con alarmas de telemetría
   */
  getAllContactsLists(IdMunicipio, pageIndex, pageSize){   
    let data: any = {pageIndex, pageSize};

    if (IdMunicipio){
      data = {...data, IdMunicipio };
    }
    
    return this.http.post<any>(`${this.baseUrl}/getAllContactsLists`, data);
  }

  verifyInputsCamara(Ip: string, id_camara: string, nombre: string, cameraId: number){
    const data = {Ip, id_camara, nombre, cameraId}
    return this.http.post<any>(`${this.baseUrl}/verifyInputsCamara`, data);
  }

  /**
   * Crea un nuevo grupo
   * @param groupName nombre del grupo 
   * @param cameras lista de camaras del grupo
   * @param idMunicipality id de municipio donde pertenece el grupo
   * @returns Observable
   */
  createGroup( groupName: string, cameras: string, idMunicipality: number ): Observable<any> {
    const data = { 
      nombre_grupo: groupName, 
      camaras: cameras, 
      idMunicipio: idMunicipality
    }
    return this.http.post(`${this.baseUrl}/createGroup`, data);
  }
  
  /**
   * Edita Grupo y Grupo de camaras
   * @param idGroup id de grupo
   * @param groupName nombre de grupo
   * @param cameras array en formato json de camaras
   * @returns Observable
   */
  editGroup( idGroup: number, groupName: string, cameras: string ): Observable<any> {
    const data = { 
      idGrupo: idGroup, 
      nombre_grupo: groupName, 
      camaras: cameras 
    };
    return this.http.put(`${this.baseUrl}/editGroup`, data);
  }

  /**
   * Obtiene grupos por municipio
   * @param idMunicipality id de municipio
   * @param search coincidencia por nombre del grupo
   * @param pageIndex indice de pagina actual
   * @param pageSize cantidad de registros a obtener
   * @returns Observable
   */
  getGroupsByMunicipality( idMunicipality: number, search: string, pageIndex: number = null, pageSize: number = null ): Observable<Group[]> {
    const data = {
      idMunicipio: idMunicipality,
      search,
      pageIndex,
      pageSize
    }
    return this.http.post<any>(`${this.baseUrl}/getGroupsByMunicipality`, data);
  }

  /**
   * Retorna los grupos de camara por id del grupo
   * @param idGroup id de grupo
   * @returns Observable
   */
  getCameraGroupsByMunicipality( idGroup: number ): Observable<CamerasFilteredByGroup[]> {
    return this.http.get<CamerasFilteredByGroup[]>(`${this.baseUrl}/getCameraGroupsByMunicipality/${ idGroup }`);
  }

  /**
   * Obtener grupo por nombre
   * @param nameGroup nombre a comparar
   * @returns Observable
   */
  getGroupByName( nameGroup: string, idMunicipality: number ): Observable<Group[]> {
    return this.http.get<any>(`${this.baseUrl}/getGroupByName/${ nameGroup }/${ idMunicipality }`);
  }

  /**
   * Guarda la configuracion de un grupo en el visor rotativo
   * @param idGroup id de grupo
   * @param settings configuraciones
   * @returns Observable
   */
  saveSettingsViewerRotating( idGroup: number, settings: SettingsViewerRotating ): Observable<any> {
    const data = { idGroup, settings: JSON.stringify( settings ) };
    return this.http.put(`${this.baseUrl}/saveSettingsViewerRotating`, data);
  }

  /**
   * Obtiene la configuracion de un grupo
   * @param idGroup id de grupo
   * @returns Observable
   */
  getSettingsViewerRotatingInGroup( idGroup: number ): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}/getSettingsViewerRotatingInGroup/${ idGroup }`);
  }

  /**
   * Obtiene informacion de la tabla "marcas"
   * @returns Observable
   */
  getBrands(): Observable<Brand[]> {
    return this.http.get<Brand[]>(`${this.baseUrl}/getBrands`);
  }

  /**
   * Obtiene informacion de la tabla colores
   * @returns Observable
   */
  getColors(): Observable<Color[]> {
    return this.http.get<Color[]>(`${this.baseUrl}/getColors`);
  }

  getinfoSoporte(IdSoporte: number) {
    const data = {IdSoporte: IdSoporte}
    return this.http.post(`${this.baseUrl}/getinfoSoporte`, data);
  }

  /**
   * Obtiene consumo de router por su id
   * @param idRouter id de router
   * @param idMunicipality id de municipio
   * @param initialDate fecha inicial de busqueda
   * @param finalDate fecha final de busqueda
   * @param pageIndex indice de la pagina actual 
   * @param pageSize numero de resultados a obtener en la busqueda
   * @returns Observable
   */
   getRouterConsumption( 
    idRouter: number, 
    idMunicipality: number,
    initialDate: string, 
    finalDate: string,
    pageIndex: number,
    pageSize: number 
  ): Observable<RouterConsumption[]> {
    const data = { idRouter, idMunicipality, initialDate, finalDate, pageIndex, pageSize, zonaHoraria: this.fsession.optenerValor("dato41"),
  };
    return this.http.post<any>(`${this.baseUrl}/getRouterConsumption`, data);
  }

  /**
   * Obtiene datos de alimentacion y bateria de los routers de un municipio
   * @param idMunicipality id de municipio
   * @param startDate fecha de inicio para el filtro de fecha_ultima_conexion
   * @param endDate fecha final para el filtro de fecha_ultima_conexion
   * @param routerId id del router a filtrar
   * @param pageIndex indice de la pagina actual 
   * @param pageSize numero de resultados a obtener en la busqueda
   * @returns Observable
   */
  getPowerBatteryDataFromRouters( idMunicipality: number, startDate: string, endDate: string, routerId: number, pageIndex: number, pageSize: number ): Observable<RoutersDataPowerAndBattery[]> {
    const data = { idMunicipality, startDate, endDate, routerId, pageIndex, pageSize };
    return this.http.post<any>(`${this.baseUrl}/getPowerBatteryDataFromRouters`, data);
  }
  
  /**
   * Guarda la configuracion de campos de matricula
   * @param configuracion Lista de campos
   * @returns 
   */
  updateConfigurationMatr(configuracion: string) {
    const data = { configuracion};
    return this.http.post<any>(`${this.baseUrl}/updateConfigurationMatr`, data);
  }

  /**
   * Guarda un nuevo registro en la tabla "DescargasPendientesImg" y actualiza la propiedad "estado_imagen" del elemento -
   * en la tabla "EventosCam"
   * @param idEventsCam id que representa un registro en la tabla "EventosCam"
   * @param idUser id del usuario
   * @returns Observable
   */
  saveDownloadsPendingsImg( idEventsCam: number, idUser: number ): Observable<any> {
    const data = { idEventsCam, idUser };
    return this.http.post(`${this.baseUrl}/saveDownloadsPendingsImg`, data);
  }

  /**
   * Actualiza la propiedad "estado" de la tabla "DescargasPendientesImg" y la propiedad "estado_imagen" de la tabla "EventosCam" 
   * @param idEventsCam id que representa un registro en la tabla "EventosCam"
   * @returns Observable
   */
  saveUpdateStatusInPendingDownloadsImgAndCamEvents( idEventsCam: number ): Observable<any> {
    const data = { idEventsCam };
    return this.http.put(`${this.baseUrl}/saveUpdateStatusInPendingDownloadsImgAndCamEvents`, data);
  }

  /**
   * Obtiene varios registro de la tabla "EventosCam"
   * @param idsEventsCam ids de eventos cam 
   * @returns Observable
   */
  getEventsCamLogs( idsEventsCam: number[] ): Observable<Pick<EventosCam, 'id' | 'id_camara' | 'estado_imagen'>[]> {
    const data = { idsEventsCam };
    return this.http.post<any>(`${this.baseUrl}/getEventsCamLogs`, data);
  }

  /**
   * Trae las aplicaciones hablitadas desde azure para el usuario
   * @param azure_oid Id de Usuario en Azure
   * @returns Observable
   */
  getEnabledApps( azure_oid: string ): Observable<any> {
    const data = { azure_oid };
    return this.http.post<any>(`${this.baseUrl}/getEnabledApps`, data);
  }

    /**
   * Guarda las aplicaciones habilitadas para el usuario
   * @param azure_oid Id de Usuario en Azure
   * @param data Arreglo de aplicaciones
   * @returns Observable
   */
    saveEnabledApp( azure_oid: string, apps: string[] ): Observable<any> {
      const data = { azure_oid, apps };
      return this.http.post<any>(`${this.baseUrl}/saveEnabledApps`, data);
    }

  /**
   * Actualiza campo de matricula en vehiculos db
   * @param idEventCam ids de eventos cam
   * @param newMatricula nueva matricula
   * @param matriculaAnterior matricula a cambiar
   * @returns Observable
   */
  updateMatricula( idEventCam, newMatricula, matriculaAnterior ){
    const data = { idEventCam: idEventCam, newMatricula: newMatricula, matriculaAnterior: matriculaAnterior};
    return this.http.post<any>(`${this.baseUrl}/updateMatricula`, data);
  }

  /**
   * Obtener matricula
   * @param idEventCam ids de eventos cam 
   * @returns Observable
   */
  getMatricula( idEventCam ){
    const data = { idEventCam: idEventCam };
    return this.http.post<any>(`${this.baseUrl}/getMatricula`, data);
  }

  /**
   * Envia notificacion utilizando el api de notificaciones 
   * @param channel canal a enviar el mensaje al momento soporta telegram como medio 
   * @param body cuerpo del mensaje a enviar 
   * @returns Observable
   */
  sendTestNotification( channel: 'telegram', content: NotificationTestBody ): Observable<ResponseNotificationTest> {
    return this.http.post<ResponseNotificationTest>(`${this.baseUrl}/enviarTestNotificacion`, { channel, content });
  }

  /**
   * Envia notificacion de prueba de matricula
   * @param idUser id de usuario al cual se le enviara la notificacion
   * @returns 
   */
  sendTestNotificationPlate( idUser: string ): Observable<ResponseNotificationTest> {
    return this.http.post<ResponseNotificationTest>(`${this.baseUrl}/sendTestNotificationPlate`, { idUser }); 
  }

  /**
   * Obtiene usuarios por municipio
   * @param userName nombre de usuario
   * @param idMunicipality id de municipio
   * @param viewUsersB2C bandera que determina si se deben mostrar solo los usuario que tengan el acceso desde el B2C
   * @param pageIndex indice de la pagina actual 
   * @param pageSize numero de resultados a obtener en la busqueda
   * @returns Observable
   */
  getUsersByMunicipality( 
    userName: string, idMunicipality: number, viewUsersB2C: boolean, pageIndex: number, pageSize: number 
  ): Observable<UsersByMunicipality[]> {

    const data = { userName, idMunicipality, viewUsersB2C, pageIndex, pageSize };
    return this.http.post<any>(`${this.baseUrl}/getUsersByMunicipality`, data); 
  }

  traerParkingsMunicipio() {
    return this.http.post(`${this.baseUrl}/traerParkingsMunicipio`, {
      idMunicipio: this.fsession.optenerValor("dato4"),
    });
  }

  traerGraficosParking(idParking, tipo, fecha, titulo, fechaFin = null) {
    var idioma = this.fsession._currentLang.value;
    return this.http.post(`${this.baseUrl}/traerGraficosParking`, {
      idParking,
      tipo,
      fecha,
      idioma,
      titulo,
      fechaFin,
      idMunicipio: this.fsession.optenerValor("dato4"),
      zonaHoraria: this.fsession.optenerValor("dato41"),
    });
  }

  /**
   * Obtiene el último registro de metadatos de una cámara
   * @param id_camara Id de cámara
   * @returns Observable
   */
  getCameraMetadata( 
    id_camara: string
  ): Observable<any[]> {

    const data = { id_camara };
    return this.http.post<any>(`${this.baseUrl}/getCameraMetadata`, data);
    
  }

  /**
   * Obtiene el último registro de metadatos de una cámara
   * @param id_camara Id de cámara
   * @returns Observable
   */
  TraerCamarasMetadato(): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}/TraerCamarasMetadato`);
    
  }

  

  downloadPhotosBackground(data) {
    let Fechalimite = new Date();
    Fechalimite.setDate(Fechalimite.getDate() + 7);
    return this.http.post(`${this.baseUrl}/AgregarDescargaPendienteFotos`, {
      data,
      Fechalimite,
    });
  }

  getTramosByMunicipio(idMunicipio) {
    const data = {idMunicipio : idMunicipio}
    return this.http.post<any>(`${this.baseUrl}/getTramosByMunicipio`, data);
  }

 getColorsByIdioma(idioma): Observable<ColorByLanguage[]> {
    const data = {idioma : idioma}
    return this.http.post<any>(`${this.baseUrl}/getColorsByIdioma`, data);
  }
  
  /**
   * Crear o actualiza la configuracion del usuario por municipio
   * @param data datos para crear o actualizar
   * @returns Observable
   */
  createOrUpdateMunicipalityConfigurationByUser( data: Omit<MunicipalityConfigurationByUser, "fecha_alta"> ): Observable<{ok: boolean}> {
    return this.http.post<{ok: boolean}>(`${this.baseUrl}/createOrUpdateMunicipalityConfigurationByUser`, data); 
  }

  /**
   * Crear o actualiza la configuracion del usuario por municipio
   * @param data datos para crear o actualizar
   * @returns Observable
   */
  UpdateNoplateMunicipalityByUser( data ): Observable<{ok: boolean}> {
    return this.http.post<{ok: boolean}>(`${this.baseUrl}/UpdateNoplateMunicipalityByUser`, data); 
  }

  /**
   * Obtiene la configuracion de determinado municipio por usuario
   * @param idUser id de usuario
   * @param idMunicipality id de municipio
   * @returns Observable
   */
  getMunicipalityConfigurationsByUser( idUser: string, idMunicipality: string ): Observable<MunicipalityConfigurationByUser[]> {
    return this.http.get<MunicipalityConfigurationByUser[]>(`${this.baseUrl}/getMunicipalityConfigurationsByUser/${ idUser }/${ idMunicipality }`); 
  }

  /**
   * Obtiene la configuracion de determinado municipio por usuario
   * @param idUser id de usuario
   * @param idMunicipality id de municipio
   * @returns Observable
   */
  getAllMunicipalityConfigurationsByUser( idUser: string, idMunicipality: string ): Observable<MunicipalityConfigurationByUser[]> {
    return this.http.get<MunicipalityConfigurationByUser[]>(`${this.baseUrl}/getAllMunicipalityConfigurationsByUser/${ idUser }/${ idMunicipality }`); 
  }

  exportUserToB2C(user: Usuario) {
    return this.http.post(`${this.baseUrl}/exportUserToB2C`, user)
  }
  
  VerificarUsuarioB2C(usuario, mail) {
    const data = {
      Usuario: usuario,
      Mail: mail
    };

    return this.http.post(`${this.baseUrl}/verifyIfUserExistInB2C`, data);
  }

  /**
   * Guardar un nuevo color
   * @param color color a guardar
   * @returns Observable
   */
  saveColor( color: Omit<Color, 'id'> ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/saveColor`, { color });
  }

  /**
   * Modifica un color
   * @param color color a modificar
   * @returns Observable
   */
  modifyColor( color: Color ): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`${this.baseUrl}/modifyColor`, { color });
  } 

  /**
   * Obtiene informacion de la tabla "paisVehiculos"
   * @returns Observable
   */
  getCountryVehicles(): Observable<VehicleCountry[]> {
    return this.http.get<VehicleCountry[]>(`${this.baseUrl}/getCountryVehicles`);
  }

  /**
   * Guardar un nuevo color
   * @param color color a guardar
   * @returns Observable
   */
  saveVehicleCountry( vehicleCountry: Omit<VehicleCountry, 'id_pais'> ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/saveVehicleCountry`, { vehicleCountry });
  }

  /**
   * Modifica un registro en la tabla "paisVehiculos"
   * @param vehicleCountry registro a modificar
   * @returns Observable
   */
  modifyVehicleCountry( vehicleCountry: VehicleCountry ): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`${this.baseUrl}/modifyVehicleCountry`, { vehicleCountry });
  }

  /**
   * Obtiene informacion de la tabla "deteccionVehiculos"
   * @returns Observable
   */
  getVehiclesDetection(): Observable<VehicleDetection[]> {
    return this.http.get<VehicleDetection[]>(`${this.baseUrl}/getVehiclesDetection`);
  }

  /**
   * Guardar nuevo registro en la tabla "deteccionVehiculos"
   * @param vehicleDetection registro a guardar
   * @returns Observable
   */
  saveVehicleDetection( vehicleDetection: Omit<VehicleDetection, 'id_deteccion'> ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/saveVehicleDetection`, { vehicleDetection });
  }

  /**
   * Modifica un registro en la tabla "deteccionVehiculos"
   * @param vehicleDetection registro a modificar
   * @returns Observable
   */
  modifyVehicleDetection( vehicleDetection: VehicleDetection ): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`${this.baseUrl}/modifyVehicleDetection`, { vehicleDetection });
  }

  /**
   * Guardar nuevo registro en la tabla "marcas"
   * @param brand registro a guardar
   * @returns Observable
   */
  saveBrand( brand: Omit<Brand, 'id'> ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/saveBrand`, { brand });
  }

  /**
   * Modifica un registro en la tabla "marcas"
   * @param brand registro a modificar
   * @returns Observable
   */
  modifyBrand( brand: Brand ): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`${this.baseUrl}/modifyBrand`, { brand });
  }

  /**
   * Trae los detalles de un release en Github
   * @param brand registro a modificar
   * @returns Observable
   */
  getLatestRelease(version): Observable<any> {
    return this.http.get(`${this.baseUrl}/getLatestRelease/${version}`);
  }

  /**
   * Trae el listado de la plataforma
   * @param brand registro a modificar
   * @returns Observable
   */
  getVersionHistory(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getVersionHistory`);
  }

  /**
   * Seleccionar parkings segun el municipio
   * @param municipio municipio
   * @returns Observable
   */
  getInfoParkings( municipio: number, pageIndex: number, pageSize: number, sort: any ): Observable<Parking[]> {
    const data = { municipio, pageIndex, pageSize, sort };
    return this.http.post<any>(`${this.baseUrl}/getInfoParkings`, data);
  }

  /**
   * Actualizar los datos de un parking
   * @param Infodata info del parking
   * @returns Observable
   */
  UpdateParking( Infodata: any ): Observable<any[]> {
    const data = { Infodata: Infodata};
    return this.http.post<any>(`${this.baseUrl}/UpdateParking`, data);
  }

  /**
   * Crear un nuevo parking y asignar múltiples cámaras
   * @param Infodata info del parking
   * @param camaras Lista de cámaras con id y entrada_adelante
   * @returns Observable
   */
  createParking( Infodata: any, camaras: any ): Observable<any[]> {
    const data = { Infodata: Infodata, camaras: camaras};
    return this.http.post<any>(`${this.baseUrl}/createParking`, data);
  }

  /**
   * Actualizar cámaras según el parking
   * @param Infodata info del parking
   * @param camaras Lista de cámaras con id y entrada_adelante
   * @returns Observable
   */
  UpdateCamarasPorParking( Infodata: any, camaras: any ): Observable<any[]> {
    const data = { Infodata: Infodata, camaras: camaras};
    return this.http.post<any>(`${this.baseUrl}/UpdateCamarasPorParking`, data);
  }

  /**
   * Obtener parkings segun el id_parking
   * @param id_parking id_parking
   * @returns Observable
   */
  getCamarasPorParking( id_parking: number ): Observable<any[]> {
    const data = { id_parking };
    return this.http.post<any>(`${this.baseUrl}/getCamarasPorParking`, data);
  }

  /**
   * Obtener regularaciones del parking
   * @param id_parking id_parking
   * @returns Observable
   */
  getRegularizacionesPorParking( id_parking: number ): Observable<any[]> {
    const data = { id_parking };
    return this.http.post<any>(`${this.baseUrl}/getRegularizacionesPorParking`, data);
  }

   /**
   * Insertar regularización en el parking
   * @param Infodata info de la regularizacion
   * @returns Observable
   */
   insertRegularizacion( Infodata: Regularizacion ): Observable<Regularizacion[]> {
    const data = { Infodata: Infodata };
    return this.http.post<any>(`${this.baseUrl}/insertRegularizacion`, data);
  }

   /**
   * Insertar cámaras en la tabla parking_cam
   * @param Infodata info de las camaras
   * @param id_parking id_parking
   * @returns Observable
   */
   insertCameraParking( id_parking: number, Infodata: InfoCamerasParking[] ): Observable<InfoCamerasParking[]> {
    const data = { id_parking: id_parking, Infodata: Infodata };
    return this.http.post<any>(`${this.baseUrl}/insertCameraParking`, data);
  }
  
  /**
   * Obtiene datos del tráfico de peatones
   * @param idMunicipio idMunicipio
   * @param tipo number
   * @param fecha fecha del evento
   * @param origen origen de datos puede ser wifi, linea de detección y metadatos (futuro)
   * @returns Observab con la informacion
   */
  getPedestrianTrafficInMap(idMunicipio: number, tipo: number, fecha: string, origen: number): Observable<TrafficPedestrian[]> {
    const data = { idMunicipio, tipo, fecha, origen };
    return this.http.post<TrafficPedestrian[]>(`${this.baseUrl}/getPedestrianTrafficInMap`, data);
  }
  
  /**
   * Obtiene los municipios asignados
   * @param idUsuario id del usuario
   * @returns Observable
   */  
  getAssignedMunicipalities(idUsuario: any): Observable<any> {
    const data = { idUsuario };
    return this.http.post<any>(`${this.baseUrl}/getAssignedMunicipalities`, data);
  }

  /**
   * Obtiene variables de traduccion en formato JSON de determinado idioma
   * @param language idioma
   * @returns Observable
   */
  getTranslations(language: SupportedLanguages): Observable<{[key: string]: string}> {
    return this.http.get<any>(`${ configuracion.translations_URL }${ language }.json?v=${ Date.now() }`);
  }

  /**
   * Sube al storage determinado archivo de traducción
   * @param formData archivo a subir
   * @returns Observable
   */
  uploadTranslationFile( formData: FormData ): Observable<{ ok: boolean, message: string }> {
    return this.http.post<{ ok: boolean, message: string }>(`${this.baseUrl}/translation/uploadTranslationFile`, formData);
  }

  /**
   * Obtiene la configuracion del panel de turismo por municipio
   * @param idMunicipio id municipio
   * @returns Observable
   */
  getTourismPanelConfigurationByMunicipality( idMunicipio: number ): Observable<ConfigurationTourismPanel[]> {
    return this.http.get<ConfigurationTourismPanel[]>(`${this.baseUrl}/getTourismPanelConfigurationByMunicipality/${ idMunicipio }`);
  }

  /**
   * Guarda o modifica configuracion del panel de turismo por municipio
   * @param data datos de la configuracion
   * @param groupCameras grupos de camaras (accesos - opcional)
   * @returns Observable
   */
  saveOrModifyTourismPanelConfigurationByMunicipality( 
    data: OmitDataInConfigurationTourismPanel, 
    groupCameras: GroupCamerasInConfigurationTourismPanel[] = [] 
  ): Observable<any> {
    const body = { ...data, groupCameras };
    return this.http.post<any>(`${this.baseUrl}/saveOrModifyTourismPanelConfigurationByMunicipality`, body);
  }
  
  /**
   * Establece datos de ejecucion en la configuracion del panel de turismo por municipio
   * @param id_municipio id municipio
   * @param id_configuracion_panel_turismo id de registro
   * @returns Observable
   */
  setExecutionDataInTourismPanelConfiguration( id_municipio: number, id_configuracion_panel_turismo: number ): Observable<any> {
    const data = { id_municipio, id_configuracion_panel_turismo };
    return this.http.post<any>(`${this.baseUrl}/setExecutionDataInTourismPanelConfiguration`, data);
  }

  /**
   * Obtiene cámaras de peatones por municipio
   * @param id_municipio id municipio
   * @param [onlyAsigned=false] Si solo se traen las cámaras que tengan definida la variable de zona
   * @returns Observable
   */
  getPedestrianCamerasByMunicipality( idMunicipio: number, onlyAsigned: boolean = false): Observable<PedestrianZone[]> {
    return this.http.get<PedestrianZone[]>(`${this.baseUrl}/getPedestrianCamerasByMunicipality/${idMunicipio}/${onlyAsigned}`);
  }

  /**
   * Crear nueva zona 
   * @param name nombre de la zona
   * @param ids_cameras id (registro en base de datos) de las camaras seleccionadas
   * @returns Observable
   */
  createZone( name: string, ids_cameras: number[] ): Observable<{ok: boolean, message: string}> {
    const data = { name, ids_cameras };
    return this.http.post<any>(`${this.baseUrl}/createZone`, data);
  }

  /**
   * Crear nueva zona 
   * @param name nombre de la zona
   * @param ids_cameras id (registro en base de datos) de las camaras seleccionadas
   * @returns Observable
   */
  createZoneGroup( name: string, ids_cameras: number[] ): Observable<{ok: boolean, message: string}> {
    const data = { name, ids_cameras };
    return this.http.post<any>(`${this.baseUrl}/createZoneGroup`, data);
  }

  /**
   * Crear nueva zona 
   * @param name nombre de la zona
   * @param ids_cameras id (registro en base de datos) de las camaras seleccionadas
   * @returns Observable
   */
  setZoneCoefficient( coefficient: number, id_camera: number ): Observable<{ok: boolean, message: string}> {
    const data = { coefficient, id_camera };
    return this.http.post<any>(`${this.baseUrl}/setZoneCoefficient`, data);
  }

  /**
   * Obtiene los registros de la tabla controlbarrera filtrados por el IdMunicipio con ordenamiento.
   * @param idMunicipio Id del municipio
   * @param sort Campo por el que se ordena
   * @returns Observable
   * @param activo Estado del filtro activo
   */
  getControlBarrierByMunicipality(idMunicipio: number, activo: boolean, sort: any): Observable<ConfiguracionParking[]> {
    const data = {idMunicipio, activo, sort}
    return this.http.post<any>(`${this.baseUrl}/getControlBarrierByMunicipality`, data);
  }

  /**
   * Obtiene los registros de la tabla controlbarrera filtrados por el idrouter
   * @param idrouter Id del router
   * @param activo Estado del filtro activo
   * @returns Observable
   */
  getControlBarrierByRouter(idrouter: number, activo: boolean): Observable<ConfiguracionParking[]> {
    const data = {idrouter, activo}
    return this.http.post<any>(`${this.baseUrl}/getControlBarrierByRouter`, data);
  }

  /**
   * Crea un nuevo registro en la tabla controlbarrera.
   * @param controlBarrierData Datos para crear un nuevo registro en controlbarrera
   * @returns Observable
   */
  createControlBarrier(controlBarrierData: ConfiguracionParking): Observable<ConfiguracionParking> {
    return this.http.post<any>(`${this.baseUrl}/createControlBarrier`, controlBarrierData);
  }

  /**
   * Actualiza un registro en la tabla controlbarrera.
   * @param idrouter Id del router a actualizar
   * @param campos Campos a actualizar en el registro
   * @returns Observable
   */
  updateControlBarrier(idrouter: number, campos: ConfiguracionParking): Observable<ConfiguracionParking> {
    const data = { idrouter, ...campos };
    return this.http.post<any>(`${this.baseUrl}/updateControlBarrier`, data);
  }

  /**
   * Obtiene la lista de interes filtrada por id lista
   * @param idlista id de la lista de interes
   * @param idMunicipality id de municipio
   * @returns Observable
   */
  getListsInterestById( idlista: number, idMunicipality: number ): Observable<ConfiguracionParking[]> {
    return this.http.get<any[]>(`${this.baseUrl}/getListsInterestById/${ idlista }/${ idMunicipality }`); 
  }

  /**
   * Obtiene tickets
   * @param id_municipio arreglo de ids de municipios
   * @param estado estado del ticket
   * @param busqueda parametro de busqueda
   * @param id_usuario id de usuario 
   * @param pageIndex indice de la pagina actual 
   * @param pageSize pageSize numero de resultados a obtener en la busqueda
   * @param isPaginationActive boolean que determina si la paginacion se debe activar o no (esto en caso de obtener todos los registros)
   * @returns Observable
   */
  getTickets( 
    ids_municipios: number[], 
    estado: EnumStateTicket | 'all', 
    busqueda: string, 
    id_usuario: number | 'all',
    pageIndex: number, 
    pageSize: number,
    isPaginationActive = true 
  ): Observable<Ticket[]> {
    const data = { 
      ids_municipios, 
      estado: estado === 'all' ? null : estado, 
      busqueda,
      id_usuario: id_usuario === 'all' ? null : id_usuario,
      pageIndex,
      pageSize,
      isPaginationActive
    };
    return this.http.post<Ticket[]>(`${this.baseUrl}/getTickets`, data);
  }

  /**
   * Obtiene usuarios por uno o mas municipios
   * @param ids_municipios arreglo de ids de municipios
   * @returns Observable
   */
  getUsersByMunicipalities( ids_municipios: number[] ): Observable<Pick<Usuario, 'IdUsuario'| 'Nombre'>[]> {
    const data = { ids_municipios };
    return this.http.post<Pick<Usuario, 'IdUsuario'| 'Nombre'>[]>(`${this.baseUrl}/getUsersByMunicipalities`, data);
  }

  /**
   * Guarda un nuevo ticket
   * @param ticket informacion de ticket a guardar
   * @returns Observable
   */
  createTicket( ticket: FormData ): Observable<{ ok: boolean, message: string }> {
    return this.http.post<{ ok: boolean, message: string }>(`${this.baseUrl}/createTicket`, ticket);
  }

  /**
   * Edita un ticket
   * @param ticket informacion de ticket a modificar
   * @returns Observable
   */
  editTicket( ticket: FormData ): Observable<{ ok: boolean, message: string }> {
    return this.http.put<{ ok: boolean, message: string }>(`${this.baseUrl}/editTicket`, ticket);
  }

  /**
   * Obtiene el archivo de un ticket
   * @param data contiene los campos id_usuario, id_ticket y archivo de un ticket
   * @returns un observable que devuelve si se obtuvo el archivo correctamente y el archivo como string
   */
  getFileOfTicket( data: Pick<Ticket, 'id_usuario' | 'id_ticket' | 'archivo'> ): Observable<{ ok: boolean, file: string }> {
    return this.http.post<{ ok: boolean, file: string }>(`${this.baseUrl}/getFileOfTicket`, data);
  }


  /**
   * Envía datos de control para la barrera al backend.
   * @param data Objeto que contiene los datos necesarios para el control de la barrera.
   * @returns Observable con la respuesta del backend
   */
  sendDataControlBarrera(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/sendDataControlBarrera`, data);
  }

  /**
 * Envía datos de la lista de interes para agregar esa lista a una camara
 * @param data Objeto que contiene los datos de la camara y lista
 * @returns Observable con la respuesta del backend
 */
  sendMessageBlackListCameras(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/sendMessageBlackListCameras`, data);
  }

  /**
   * Actualizar regularización del parking
   * @param datosAnteriores Datos antes de editar
   * @param datosEditados Datos ya editados
   * @returns Observable
   */
  updateRegularizacion( datosAnteriores: Regularizacion, datosEditados: Regularizacion ): Observable<any> {
    const data = { datosAnteriores, datosEditados };
    return this.http.post<any>(`${this.baseUrl}/updateRegularizacion`, data);
  }

  /**
   * Borrar regularización del parking
   * @param datosAnteriores Datos que se borraran
   * @returns Observable
   */
  deleteRegularizacion( datosAnteriores: Regularizacion ): Observable<any> {
    const data = { datosAnteriores };
    return this.http.post<any>(`${this.baseUrl}/deleteRegularizacion`, data);
  }
  
  /**
   * Obtiene los grupos de camaras relacionados con una configuración del panel de turismo
   * @param id_configuracion_panel_turismo id de configuración del panel de turismo
   * @returns Observable
   */
  getGroupsOfCamerasByTourismPanelConfiguration( id_configuracion_panel_turismo: number ): Observable<GroupCamerasInConfigurationTourismPanel[]> {
    return this.http.get<any>(`${this.baseUrl}/getGroupsOfCamerasByTourismPanelConfiguration/${id_configuracion_panel_turismo}`);
  }

  /**
   * Agrega un nuevo grupo de camaras a una configuracion de panel de turismo por municipio
   * @param data datos de grupo a guardar
   * @returns Observable
   */
  addNewGroupsOfCamerasByTourismPanelConfiguration( 
    data: Omit<GroupCamerasInConfigurationTourismPanel, 'nombre_camara_entrada' | 'nombre_camara_salida'> 
  ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/addNewGroupsOfCamerasByTourismPanelConfiguration`, data);
  }

  /**
   * Edita un grupo de camaras ya existente en una configuracion de panel de turismo por municipio
   * @param data datos del grupo de camaras a editar sin incluir nombre_camara_entrada y nombre_camara_salida
   * @returns Observable con un objeto que contiene un booleano "ok" que es true si la edición fue exitosa
   */
  editNewGroupsOfCamerasByTourismPanelConfiguration(
    data: Omit<GroupCamerasInConfigurationTourismPanel, 'nombre_camara_entrada' | 'nombre_camara_salida'>
  ): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`${this.baseUrl}/editNewGroupsOfCamerasByTourismPanelConfiguration`, data);
  }

  /**
   * Elimina un grupo de camaras de una configuracion de panel de turismo por municipio
   * @param data datos a verificar para eliminar
   * @returns Observable
   */
  removeGroupOfCamerasByTourismPanelConfiguration(
    data: Omit<GroupCamerasInConfigurationTourismPanel, 'nombre_camara_entrada' | 'nombre_camara_salida'>
  ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/removeGroupOfCamerasByTourismPanelConfiguration`, data);
  }

  /**
   * Obtiene informacion de las camaras de matricula y ubicacion de soporte por municipio
   * @param idMunicipality id de municipio
   * @returns Observable
   */
  getLicensePlateCameraInformationAndSupportLocation( idMunicipality: number ): Observable<InfoCameraAndSupportLocation[]> {
    return this.http.get<InfoCameraAndSupportLocation[]>(
      `${this.baseUrl}/getLicensePlateCameraInformationAndSupportLocation/${idMunicipality}`
    );
  }

  /**
   * Exporta los datos procesados del panel de turismo por municipio
   * @param acceso Número del Acceso
   * @param tipo 1 - Dia, 2 - Semana, 3 - Mes, 4 - Año, 5 - Acumulado, 6 - Personalizado
   * @param fecha Fecha de inicio de la consulta
   * @param fechaFin Fecha de fin de la consulta
   * @returns Observable
   */
  exportProcessedTourismPanelData( 
    acceso: number, 
    tipo: number, 
    fecha: string, 
    fechaFin: string = null ): Observable<{data: processedTourismPanelData[]}> {

    const data = {
      idioma: this.fsession._currentLang.value,
      acceso,
      fecha,
      tipo,
      fechaFin,
      idMunicipio: this.fsession.optenerValor("dato4"),
      zonaHoraria: this.fsession.optenerValor("dato41")
    }
    return this.http.post<{data: processedTourismPanelData[]}>(`${this.baseUrl}/exportProcessedTourismPanelData`, data)

  }

  /**
   * Env a un correo electronico con las credenciales de un usuario
   * @param email correo electronico del usuario
   * @param usuario nombre del usuario
   * @param password contrase a del usuario
   * @param nameRol nombre del rol que se le asigna al usuario
   * @returns Observable con la respuesta del servidor, que contiene un booleano que indica si se envio el correo con exito y un mensaje adicional
   */
  sendEmailWithCredentialsUser( 
    email: string,
    usuario: string,
    password: string,
    nameRol: string 
  ): Observable<{ ok: boolean, message: string }> {
    const data = { email, usuario, password, nameRol };
    return this.http.post<{ ok: boolean, message: string }>(`${this.baseUrl}/sendEmailWithCredentialsUser`, data);
  }

   /**
   * Actualiza el campo terminoEns de un usuario
   * @param terminoEns Valor booleano de terminoEns
   * @returns Observable
   */
  updateTerminoEnsUsuario(terminoEns: boolean): Observable<any> {
    const data = { terminoEns };
    return this.http.post(`${this.baseUrl}/updateTerminoEnsUsuario`, data);
  }

  /**
   * Obtiene el valor de terminoEns de un usuario
   * @returns Observable con el valor de terminoEns
   */
  getTerminoEnsUsuario(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getTerminoEnsUsuario`);
  }
 
  /**
   * Verifica si existe el nombre de un acceso en una configuracion del panel de turismo
   * @param nombre nombre del acceso a verificar
   * @param id_configuracion_panel_turismo id de la configuracion del panel de turismo a verificar
   * @returns Observable
   */
  existAccessNameByTourismPanelConfiguration( 
    nombre: string, 
    id_configuracion_panel_turismo: number 
  ): Observable<{ exist: boolean, id_grupo_camara: number }[]> {
    const data = { nombre, id_configuracion_panel_turismo };
    return this.http.post<any>(`${this.baseUrl}/existAccessNameByTourismPanelConfiguration`, data);
  }

  /**
   * Envía datos filtrados para obtener tráfico de peatones
   * @param fecha Fecha del evento
   * @param municipio ID del municipio
   * @param zonaHoraria Zona horaria del municipio
   * @param idCamara ID de la cámara
   * @returns Observable con la información procesada
   */
  getFilteredPedestrianData(fecha: string, idCamara: number): Observable<any[]> {
    const idMunicipio = this.fsession.optenerValor("dato4");
    const zonaHoraria = this.fsession.optenerValor("dato41");

    const data = { fecha, idMunicipio, zonaHoraria, idCamara };
    return this.http.post<any[]>(`${this.baseUrl}/getFilteredPedestrianData`, data);
  }
  
  /**
   * Obtiene el seguimiento (tracking) de los routers en un municipio
   * @param idMunicipality id del municipio
   * @returns Observable
   */
  getTrackingByMunicipality( idMunicipality: number ): Observable<Tracking[]> {
    return this.http.get<Tracking[]>(`${this.baseUrl}/getTrackingByMunicipality/${idMunicipality}`);
  }

  /**
   * Obtiene el seguimiento de un router en un rango de fechas
   * @param idRouter El ID del router del cual se desea obtener el seguimiento
   * @param dateInit La fecha de inicio del rango de seguimiento
   * @param dateEnd La fecha final del rango de seguimiento
   * @param range El rango de tiempo (en minutos) del rango de seguimiento
   * @returns Observable
   */
  getTrackingByRouter( idrouter: number, dateInit: string, dateEnd: string, range: number ): Observable<Omit<Tracking, 'routerName'>[]> {
    const data = {
      idrouter,
      dateInit,
      dateEnd,
      range 
    }
    return this.http.post<any>(`${this.baseUrl}/getTrackingByRouter`, data);
  }

  /**
   * Agrega un punto de inters en el mapa
   * @param interestPoint datos del punto de interes sin incluir el idPuntoInteres
   * @returns Observable
   */
  addPointOfInterestOnMap( interestPoint: Omit<InterestPoint, 'idPuntoInteres'> ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/addPointOfInterestOnMap`, interestPoint);
  }

  /**
   * Obtiene los puntos de interes en el mapa de un municipio
   * @param idMunicipality El ID del municipio del cual se desean obtener los puntos de inter s
   * @returns Observable con los puntos de interes
   */
  getPointOfInterestOnMapByMunicipality( idMunicipality: number ): Observable<InterestPoint[]> {
    return this.http.get<InterestPoint[]>(`${this.baseUrl}/getPointOfInterestOnMapByMunicipality/${idMunicipality}`);
  }
  
  /**
   * Elimina uno o varios puntos de interes en el mapa.
   * @param idsInterestPoint Un arreglo con los id de los puntos de interes a eliminar
   * @returns Observable
   */
  deletePointOfInterest( idsInterestPoint: number[] ): Observable<{ ok: boolean }> {
    const data = { idsInterestPoint } 
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/deletePointOfInterest`, data);
  }

  /**
   * Actualiza un punto de interés en la base de datos.
   * 
   * @param interestPoint Los datos del punto de interés a actualizar
   * @returns Observable
   */
  updatePointOfInterest( interestPoint: InterestPoint ): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/updatePointOfInterest`, interestPoint);
  }

  /**
   * Obtener
   * @param idMunicipio ID del municipio
   * @returns Observable con la información procesada
   */
  getdataCamerasSentilo(): Observable<any[]> {
    const idMunicipality = this.fsession.optenerValor("dato4");
    return this.http.get<any[]>(`${this.baseUrl}/getdataCamerasSentilo/${idMunicipality}`);
  }

  /**
   * Modificar información de cámaras con Sentilo
   * @param idCamara ID de la cámara a modificar
   * @param datos Datos a modificar
   * @returns Observable
   */
  modificarDatosCamerasSentilo( idCamara: number, datos: any ): Observable<any[]> {
    const data = { idCamara, datos };
    return this.http.post<any[]>(`${this.baseUrl}/modificarDatosCamerasSentilo`, data);
  }

  /**
   * Obtener las categorías de seguimiento por id de municipio
   * @param idMunicipality ID del municipio
   * @returns Observable con las categorías de seguimiento
   */
  getCategoriesByMunicipality( idMunicipality: number ): Observable<TrackingCategory[]> {
    return this.http.get<TrackingCategory[]>(`${this.baseUrl}/getCategoriesByMunicipality/${idMunicipality}`);
  }

  /**
   * Agrega una categoría de seguimiento para un municipio
   * @param category La categoría de seguimiento a agregar, sin incluir el idCategoria
   * @returns Observable
   */
  saveCategoryByMunicipality( category: Omit<TrackingCategory, 'idCategoria'> ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.baseUrl}/saveCategoryByMunicipality`, category);
  }

  /**
   * Modifica una categoría de seguimiento para un municipio
   * @param category La categoría de seguimiento a modificar, sin incluir el idMunicipio
   * @returns Observable con resultado de la operación
   */
  modifyCategoryByMunicipality( category: Omit<TrackingCategory, 'idMunicipio'> ): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`${this.baseUrl}/modifyCategoryByMunicipality`, category);
  }
  
  /**
   * Modificar información de cámaras con Sentilo
   * @param idCamara ID de la cámara a modificar
   * @returns Observable
   */
  
  resetearDatosCamerasSentilo( idCamara: number): Observable<any[]> {
    const data = { idCamara };
    return this.http.post<any[]>(`${this.baseUrl}/resetearDatosCamerasSentilo`, data);
  }

  /**
   * Actualiza la API de un vehículo
   * @param id ID del vehículo a actualizar
   * @param api Nombre API
   * @param apiUrl URL de la API
   * @param apiPeriodicity periodicidad de la API
   * @returns Observable con el resultado de la actualización
   */
  updateListVehicleApi(id: number, api: string, apiUrl: string, apiPeriodicity: number): Observable<{ ok: boolean, message: string }> {
    const data = { id, api, apiUrl, apiPeriodicity };
    return this.http.post<{ ok: boolean, message: string }>(`${this.baseUrl}/updateListVehicleApi`, data);
  }

  /**
   * Traer soportes clima
   * @param activado bandera
   * @param IdMunicipio id de municipio
   * @param callback Callback de la función
   */
  traerSoportesCLima(activado, IdMunicipio) {
    const data = {
      activado: activado,
      IdMunicipio: IdMunicipio,
    };
    return this.http.post(`${this.baseUrl}/traerSoportesCLima`, data);
  }

  /**
   * Actualiza los campos credencialesApi y esquemaJsonApi en la tabla ListaVehiculos
   * @param idLista ID de la lista de vehículos a actualizar
   * @param credencialesApi Credenciales API
   * @param esquemaJsonApi Esquema JSON
   * @returns Observable
   */
  actualizarCredencialesListaVehiculos(idLista: number, credencialesApi: string, esquemaJsonApi: string) {
    const data = {
      idLista,
      credencialesApi,
      esquemaJsonApi
    };
    
    return this.http.post(`${this.baseUrl}/actualizarCredencialesListaVehiculos`, data);
  }
  
  /**
   * Obtiene el inventario de trackings (router con tracking activado) por municipio
   * @param idMunicipality id del municipio
   * @returns Observable
   */
  getInventoryTrackingByMunicipality( idMunicipality: number ): Observable<InventoryTracking[]> {
    return this.http.get<InventoryTracking[]>(`${this.baseUrl}/getInventoryTrackingByMunicipality/${idMunicipality}`);
  }

  /**
   * Obtiene los routers de un municipio que tienen el tracking deshabilitado.
   * @param idMunicipality ID del municipio
   * @returns Observable
   */
  getRouterByMunicipalityByTrackingDisabled( idMunicipality: number ): Observable<RouterTrackingDisabled[]> {
    return this.http.get<RouterTrackingDisabled[]>(`${this.baseUrl}/getRouterByMunicipalityByTrackingDisabled/${idMunicipality}`);
  }

  /**
   * Modifica un router relacionado con el inventario de trackings
   * @param data Objeto que contiene los datos del router
   * @returns Observable
   */
  modifyInventoryTrackingRouter( data: RouterTrackingDisabled ): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`${this.baseUrl}/modifyInventoryTrackingRouter`, data);
  }

  /**
   * Obtiene los registros de la tabla Registros_VehiculosDB_Camara_Inactiva
   * @returns Observable
   */
  obtenerRegistrosCamaraInactiva() {
    return this.http.get(`${this.baseUrl}/obtenerRegistrosCamaraInactiva`);
  }

  /**
   * Obtiene los registros de la tabla Registros_VehiculosDB_Huerfanos_UltimaSemana
   * @returns Observable
   */
  obtenerRegistrosHuerfanosUltimaSemana() {
    return this.http.get(`${this.baseUrl}/obtenerRegistrosHuerfanosUltimaSemana`);
  }

  /**
   * Filtra los datos por id de eventoscam y obtener imagenes
   * @param ids IDs de los registros separados por comas
   * @returns
   */
  cameraImageRecordsById(ids) {
    const data = { ids };
    return this.http.post(`${this.baseUrl}/cameraImageRecordsById`, data );
  } 

}