import { environment } from './environments/environment';

export const configuracion = {
    production: environment.production,
    servidor_api:environment.servidor_api,
    servidor_api_Modem:environment.servidor_api_Modem,
    servidor_Img:environment.servidor_Img,
    servidor_Stream:environment.servidor_Stream,
    ws_Stream:environment.ws_Stream,
    ws_Stream_new:environment.ws_Stream_new,
    claveEncriptacion:environment.claveEncriptacion,
    ApiKey:environment.ApiKey,
    partialContentSize:environment.partialContentSize,
    rutaPhp:environment.rutaPhp,
    apiIpPublica:environment.apiIpPublica,
    key_cifrado_respuesta: environment.key_cifrado_respuesta,
    translations_URL: 'https://plataformavigilanciadisk.blob.core.windows.net/traducciones-plataformas/' 
};
