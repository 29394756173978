import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class MessageService {

  constructor(
    private _matSnackBar: MatSnackBar
  ) { }

  public SwalMessage(icon: SweetAlertIcon, title: string, showConfirmButton = false, timer = 4000) {
    return Swal.fire({
      icon,
      title,
      heightAuto: false,
      showConfirmButton,
      timer,
    });
  }

  public dialogMessage(icon: SweetAlertIcon, title:string, options?:SweetAlertOptions) {
    return Swal.fire({
      icon,
      title,
      heightAuto: false,
      ...options
    })
  }

   /** Muestra un mensaje mediante un MatSnackBar de angular material */
  snackBarMessage( 
    message: string, 
    action: string, 
    configuration: { 
      duration?: number, 
      verticalPosition?: 'top' | 'bottom', 
      horizontalPosition?: 'center' | 'end' | 'left' | 'right' | 'start' } 
  ) {

    this._matSnackBar.open( message, action, {
      duration: configuration.duration ?? 4000,
      verticalPosition: configuration.verticalPosition ?? 'bottom',
      horizontalPosition: configuration.horizontalPosition ?? 'center'
    });

  }

  /**
   * Muestra un mensaje de diálogo en una esquina
   * @param icon ïcono del Toast
   * @param title Titulo del Toast
   */
  swalToast(icon: SweetAlertIcon, title:string, options?:SweetAlertOptions) {
    Swal.fire({
      position: "top-end",
      heightAuto: false,
      title, icon, toast: true , ...options
    })
  }

}