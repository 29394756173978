import packageJson from '../../../../package.json';

import { SwUpdate } from "@angular/service-worker";
import { Injectable } from "@angular/core";
import { interval, Observable, of } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { DatabaseService } from "../database/database.service";
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class UpdateService {
  wordTraslate: string[] = [
    "nuevaVersion",
    "cancelar",
    "Boton_Actualizar",
    "ultVersion",
    "versionDescargada",
    "recargaActualizacion",
    "aceptar",
    "error",
    "errorDescarga",
    "errorDescarga",
    "buscandoActualizacines",
  ];

  translations: any;

  version: string = packageJson.version;
  versionInfo;
  versionHistory = [];

  firstSearch: boolean = false;

  constructor(
    private updates: SwUpdate,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private _databaseService: DatabaseService
  ) {
    this.translate
      .get(this.wordTraslate)
      .subscribe((wordTraslate) => (this.translations = wordTraslate));

    this.getUpdateChanges();
  }

  /**
   * Este método cumple varias funciones
   * Verifica si el Service Worker está activo
   * Verifica la primera vez si hay alguna actualización, si no, lo hace cada `2` horas.
   * De haber una actualización muestra una alerta
   */
  checkUpdates() {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe((event) => {
        switch (event.type) {
          case "VERSION_DETECTED":
            Swal.fire({
              icon: "success",
              title: this.translations.nuevaVersion,
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
            });
            break;
          case "VERSION_READY":
            const nuevaVersion = event.latestVersion.hash;

            // Agregar flag en localstorage para que mostrar resumen de nueva versión
            localStorage.setItem("show-new-version", "true");

            if (Swal.isVisible) {
              Swal.close();
            }
            Swal.fire({
              icon: "success",
              title: this.translations.versionDescargada,
              html: `<p>${this.translations.recargaActualizacion} </p>`,
              customClass: "font-size",
              confirmButtonText: this.translations.Boton_Actualizar,
              showCancelButton: false,
              cancelButtonText: this.translations.cancelar,
              confirmButtonColor: "#fb5858",
              allowEscapeKey: false,
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm() {
                window.location.reload();
              },
              footer: `<small>${nuevaVersion}</small>`,
            });
            break;
          case "VERSION_INSTALLATION_FAILED":
            if (Swal.isVisible) {
              Swal.close();
            }

            // Si falla se elimina
            this.updateMessageShown();
            
            Swal.fire({
              icon: "error",
              title: this.translations.error,
              html: `
                  <p>${this.translations.errorDescarga} </p>
                  <p><b>windows:</b> Ctrl + F5</p>
                  <p><b>OS X:</b> Option + ⌘ Command + E, ⌘ Command + R  </p>  
                `,
              customClass: "font-size",
              confirmButtonText: this.translations.aceptar,
              confirmButtonColor: "#fb5858",
              allowEscapeKey: false,
              allowOutsideClick: false,
              heightAuto: false,
            });
            break;
          default:
            if (this.firstSearch == false) {
              this.firstSearch = true;
              const snackBar = this.openSnackbar(this.translations.ultVersion);
              snackBar._dismissAfter(3000);
            }

            break;
        }
      });

      const everySixHours$ = interval(2 * 60 * 60 * 1000);

      everySixHours$.subscribe(async () => {
        try {
          await this.checkForUpdate();
        } catch (err) {
          console.error("Failed to check for updates:", err);
        }
      });
    } else {
    }
  }

  getUpdateChanges(): Observable<any> {
    if (this.versionInfo) {
      return of(this.versionInfo);
    }

    return this._databaseService.getLatestRelease(this.version)
      .pipe(tap((release) => {
        this.versionInfo = release;
      }))
  }

  
  getUpdateHistory(): Observable<any> {
    if (this.versionHistory.length > 0) {
      return of(this.versionHistory);
    }

    return this._databaseService.getVersionHistory()
      .pipe(tap((releases) => {
        this.versionHistory = releases;
      }))
  }


  /**
   * Define si se debe mostrar el mensaje con el resumen de nueva versión
   * @returns Booleano
   */
  shouldShowMessage() {
    const message = localStorage.getItem("show-new-version");

    if (message) {
      return Boolean(message);
    }

    return false;
  }

  /**
   * Verifica bajo demanda si hay una actualización disponible
   */
  async checkForUpdate() {
    if (this.updates.isEnabled) {
      if (true) {
        this.openSnackbar(
          this.translations.buscandoActualizacines
        )._dismissAfter(3000);

        const check = await this.updates.checkForUpdate();

        if (check) {
          // Agregar flag en localstorage para que mostrar resumen de nueva versión
          localStorage.setItem("show-new-version", "true");

          if (Swal.isVisible) {
            Swal.close();
          }
          Swal.fire({
            icon: "success",
            title: this.translations.versionDescargada,
            html: `<p>${this.translations.recargaActualizacion} </p>`,
            customClass: "font-size",
            confirmButtonText: this.translations.Boton_Actualizar,
            showCancelButton: false,
            cancelButtonText: this.translations.cancelar,
            confirmButtonColor: "#fb5858",
            allowEscapeKey: false,
            allowOutsideClick: false,
            heightAuto: false,
            preConfirm() {
              window.location.reload();
            },
          });
        } else {
          this.openSnackbar(this.translations.ultVersion)._dismissAfter(3000);
        }
      }
    }
  }

  /** Elimina la variable para mostrar el mensaje de actualización */
  updateMessageShown() {
    localStorage.removeItem("show-new-version");
  }

  /**
   * Abre un snackbar para mostrar
   * @param message mensaje a mostrar
   */
  openSnackbar(message: string) {
    this._snackBar.dismiss();
    return this._snackBar.open(message, null, { horizontalPosition: "start" });
  }
}
