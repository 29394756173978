import { InteractionType } from "@azure/msal-browser";

// const {
//   NG_APP_SERVIDOR_API,
//   NG_APP_SERVIDOR_API_MODEM,
//   NG_APP_CLAVEENCRIPTACION,
//   NG_APP_APIKEY,
//   NG_APP_PARTIALCONTENTSIZE,
//   NG_APP_RUTAPHP,
//   NG_APP_SERVIDOR_IMG,
//   NG_APP_SERVIDOR_STREAM,
//   NG_APP_WS_STREAM,
//   NG_APP_APIIPPUBLICA,
//   NG_APP_TIME_INACTIVITY_MS,
//   NG_APP_EXPIRATION_PASSWORD,
//   NG_APP_KEY_CIFRADO_RESPUESTA,
//   NG_APP_FRONTEND_URL,
//   NG_APP_B2C_CLIENTID_PRODUCTION,
//   NG_APP_B2C_SCOPES_PRODUCTION,
//   NG_APP_B2C_URI_PRODUCTION,
//   NG_APP_B2C_SIGNUPSIGNINNAMES_PRODUCTION,
//   NG_APP_B2C_EDITPROFILENAMES_PRODUCTION,
//   NG_APP_B2C_SIGNUPSIGNINAUTHORITIES_PRODUCTION,
//   NG_APP_B2C_EDITPROFILEAUTHORITIES_PRODUCTION,
//   NG_APP_B2C_CHANGEPASSWORDAUTHORITIES_PRODUCTION,
//   NG_APP_B2C_AUTHORITYDOMAIN_PRODUCTION,
//   NG_APP_DOMAIN_ALLOWED_NUMBER_ONE,
//   NG_APP_DOMAIN_ALLOWED_NUMBER_TWO,
//   NG_APP_DOMAIN_ALLOWED_NUMBER_THREE,
//   NG_APP_DOMAIN_ALLOWED_NUMBER_FOUR,
//   NG_APP_DOMAIN_ALLOWED_NUMBER_FIVE,
//   NG_APP_MAX_NUMERO_MATRICULA,
//   NG_APP_PRODUCTION
//   // Environments que se utlizan para ejecutar el b2c a traves del ngrok
//   /* 
//   NG_APP_B2C_CLIENTID_LOCAL,
//   NG_APP_B2C_SCOPES_LOCAL,
//   NG_APP_B2C_SIGNUPSIGNINNAMES_LOCAL,
//   NG_APP_B2C_SIGNUPSIGNINAUTHORITIES_LOCAL 
//   */
// } = import.meta.env;

export const environment = {
  production: process.env.NG_APP_PRODUCTION === 'true' ? true : false,
  whitelistedDomains: [ 
    process.env.NG_APP_DOMAIN_ALLOWED_NUMBER_ONE,
    process.env.NG_APP_DOMAIN_ALLOWED_NUMBER_TWO,
    process.env.NG_APP_DOMAIN_ALLOWED_NUMBER_THREE,
    process.env.NG_APP_DOMAIN_ALLOWED_NUMBER_FOUR,
    process.env.NG_APP_DOMAIN_ALLOWED_NUMBER_FIVE 
  ],
  servidor_api:           process.env.NG_APP_SERVIDOR_API,
  servidor_api_Modem:     process.env.NG_APP_SERVIDOR_API_MODEM,
  claveEncriptacion:      process.env.NG_APP_CLAVEENCRIPTACION,
  ApiKey:                 process.env.NG_APP_APIKEY,
  partialContentSize:     parseInt(process.env.NG_APP_PARTIALCONTENTSIZE),
  rutaPhp:                process.env.NG_APP_RUTAPHP,
  servidor_Img:           process.env.NG_APP_SERVIDOR_IMG,
  servidor_Stream:        process.env.NG_APP_SERVIDOR_STREAM,
  ws_Stream:              process.env.NG_APP_WS_STREAM,
  ws_Stream_new:          process.env.NG_APP_SERVIDOR_STREAM_NEW,
  apiIpPublica:           process.env.NG_APP_APIIPPUBLICA,
  time_inactivity_ms:     parseInt(process.env.NG_APP_TIME_INACTIVITY_MS),
  expiration_password :   parseInt(process.env.NG_APP_EXPIRATION_PASSWORD),
  key_cifrado_respuesta:  process.env.NG_APP_KEY_CIFRADO_RESPUESTA,
  frontend_url:           process.env.NG_APP_FRONTEND_URL,
  max_numero_matricula:   parseInt(process.env.NG_APP_MAX_NUMERO_MATRICULA),

  /** Configuraciones MSAL (Microsoft Authentication Library)  */
  msalConfig: {
    auth: {
      clientId: process.env.NG_APP_B2C_CLIENTID_PRODUCTION
      // clientId: process.en.NG_APP_B2C_CLIENTID_LOCAL // prueba
    },
    interactionType: InteractionType.Redirect as InteractionType.Redirect | InteractionType.Popup,
  },
  apiConfig: {
    scopes: [
      process.env.NG_APP_B2C_SCOPES_PRODUCTION
      // process.env.NG_APP_B2C_SCOPES_LOCAL // prueba
    ], // Permisos de API
    uri: process.env.NG_APP_B2C_URI_PRODUCTION // Url del servicio a proteger
  },
  b2cPolicies: {
    // FLujos de Usuario
    names: {
      signUpSignIn: process.env.NG_APP_B2C_SIGNUPSIGNINNAMES_PRODUCTION,
      // signUpSignIn: process.env.NG_APP_B2C_SIGNUPSIGNINNAMES_LOCAL // prueba
      editProfile: process.env.NG_APP_B2C_EDITPROFILENAMES_PRODUCTION
    },
    authorities: {
      signUpSignIn: {
        authority:
          process.env.NG_APP_B2C_SIGNUPSIGNINAUTHORITIES_PRODUCTION
          // process.env.NG_APP_B2C_SIGNUPSIGNINAUTHORITIES_LOCAL // prueba
      },
      editProfile: {
        authority:
          process.env.NG_APP_B2C_EDITPROFILEAUTHORITIES_PRODUCTION
      },
      changePassword: {
        authority:
          process.env.NG_APP_B2C_CHANGEPASSWORDAUTHORITIES_PRODUCTION 
      }
    },
    authorityDomain: process.env.NG_APP_B2C_AUTHORITYDOMAIN_PRODUCTION
  }
};