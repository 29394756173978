import { Component, OnInit, ViewChild } from "@angular/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import packageJson from '../../../../package.json';
import { DatabaseService } from "src/app/servicios/database/database.service";
import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { UpdateService } from "src/app/servicios/update/update.service";
import { FsessionService } from "src/app/servicios/session/fsession.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { LoaderService } from "src/app/component/loader-spinner/loader.service";

@Component({
  selector: "app-acerca-de",
  templateUrl: "./acerca-de.component.html",
  styleUrls: ["./acerca-de.component.scss"],
  providers: [
    CdkCopyToClipboard,
    LoaderService,
  ]
})
export class AcercaDeComponent implements OnInit {
  @ViewChild("aboutSwal") aboutSwal: SwalComponent;
  @ViewChild("versionsListSwal") versionsListSwal: SwalComponent;
  @ViewChild("changesSwal") changesSwal: SwalComponent;
  @ViewChild("terminos") terminos: SwalComponent;

  @ViewChild("releasePaginator", { static: true }) releasePaginator: MatPaginator;

  fileUrl =
    "https://www.ccn-cert.cni.es/amparo/API/public/certificaciones/3086/download/2077";

  swalConfirmButtonAndIconColor = '#294ADB';
  swalWidth = '510px';
  
  version: string = packageJson.version;
  versionInfo = null
  oldVersionInfo = null
  versionsList = [];

  fadeInClass = {
    popup: `
      animate__animated
      animate__fadeInDown
      animate__faster
    `
  }
  currentVersion = true;
  loading = true;

  dontShowAgain: boolean = false;
  ultimo_inicio_sesion: string;

  constructor(
    public readonly swalTargets: SwalPortalTargets,
    private db: DatabaseService,
    private clipboard: CdkCopyToClipboard,
    private updateService: UpdateService,
    private fse: FsessionService,
    private loaderService: LoaderService
  ) {
  }
  
  ngOnInit(): void {
    this.loaderService.isLoading$().subscribe(loading => this.loading = loading);

    this.db.getTerminoEnsUsuario().subscribe((res)=>{
      this.ultimo_inicio_sesion = res[0].ultimo_inicio_sesion;
    });

    this.updateService.getUpdateChanges().subscribe(
      (release) => {
        this.versionInfo = release;
      }
    )
  }

  getCurrentVersionChanges() {
    this.currentVersion = true;
    this.changesSwal.fire();
    this.updateService.checkForUpdate();
  }

  clearUpdateMessage() {
    if (!this.currentVersion) {
      this.oldVersionInfo = true;
    } else {
      this.updateService.updateMessageShown();
    }
  }

  copyReleaseNotes() {
    this.clipboard.text = this.versionInfo.body;
    this.clipboard.copy()
    return false;
  }

  updateHistory() {
    this.loaderService.showSpinner();
    this.updateService.getUpdateHistory().subscribe(
      (releases) => {
        this.versionsList = releases;
        this.loaderService.hideSpinner();
        this.versionsListSwal.fire();
      }
    )
  }

  getVersionDetails(tagName) {
    this.currentVersion = false;
    this.loaderService.showSpinner();
    this.db.getLatestRelease(tagName)
    .subscribe((release) => {
      this.oldVersionInfo = release;
      this.loaderService.hideSpinner();
      this.changesSwal.fire();
    })
  }

  /**
   * Confirma los terminos del servicio por parte del usuario
   */
  confirmarTerminos() {
    this.fse.agregarActualizar('terminos', 'true');
    if(this.dontShowAgain) {
      this.db.updateTerminoEnsUsuario(this.dontShowAgain).subscribe(() => {
        location.replace('/traffic');
      })
    }
  }

  /**
   * No volver a mostrar el mensaje de advertencia de terminos ENS
   * @param event event
   */
  onCheckboxChange(event: MatCheckboxChange) {
    this.dontShowAgain = event.checked;
  }
}
