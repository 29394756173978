export interface RouterG {
    IdRouter: number,
    SSID: string,
    Ip: string,
    IdSoporte:number,
    Soporte:String,
    Municipio:String,
    CDI:String,
    Telefono:String,
    totalRegistros?: number
}
export interface RouterFormulario {
    IdRouter: number,
    SSID: string,
    Ip: string,
    IdSoporte:number,
    CDI:String,
    Telefono:String,
    SerieRouter:String,
    MacRouter:String,
    Imei:String,
    IpRed:String,
    Ndispositivos:Number, 
    Entorno:String,
    Titulo:String,
    estadoDialogo:String,
    Modificar:boolean,
    DatosOriginales:any,
    Activo:any,
    tracking: boolean,
    tiene_clima: boolean,
    tracking_marca?: string;
    tracking_matricula?: string;
    tracking_tipo_vehiculo?: string;
}

export interface Routers {
    IdRouter: number;
    SSID: string;
    Ip: string;
    IdSoporte: number;
    Soporte: string;
    Municipio: string;
    CDI: string;
    Telefono: string;
    SerieRouter: string;
    MacRouter: string;
    Imei: string;
    IpRed: string;
    Ndispositivos: number;
    Activo: boolean;
}

export interface RouterConsumption {
    SSID: string;
    modelo: string;
    FechaHora_Local: string;
    totalRegistros?: number;
    Consumo_dia?: any;
    Consumo_semana?: any;
    Consumo_mes?: any;
}

export interface RoutersDataPowerAndBattery {
    SSID: string;
    modelo: string;                                                                                            
    Alimentacion: string;
    Bateria: string;
    totalRegistros?: number;
    startDate: string;
    endDate: string;
    routerId: string;
}