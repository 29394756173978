import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ValidateRolesDirective } from './directives/validateRoles/validate-roles.directive';
import { GenericTableComponent } from './shared/components/generic-table/generic-table.component';
import { configuracion } from 'src/configuracion';
import { GenericTableHeaderComponent } from './shared/components/generic-table-header/generic-table-header.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, configuracion.translations_URL);
}

@NgModule({
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatTableModule,
        MatTooltipModule,
        MatIconModule,
        MatSortModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        TranslateModule.forChild({
            extend: true,
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ],
    declarations: [
        ValidateRolesDirective,
        GenericTableComponent,
        GenericTableHeaderComponent
    ],
    exports: [
        ValidateRolesDirective,
        GenericTableComponent,
        GenericTableHeaderComponent
    ],
})
export class UtilsModule {}