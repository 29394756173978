export interface InfractionsCommunication {
    name: string;
    label: string;
    selected: boolean;
}

export interface TimecontrolTree {
    name: string;
    checked: boolean;
    expanded?: boolean;
    children?: ChildTimecontrolTree[];
}

export interface ChildTimecontrolTree extends TimecontrolTree {
    timeInit: string;
    timeEnd: string;
}