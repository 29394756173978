import { Component, Input } from '@angular/core';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { LoaderService } from './loader.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-loader-spinner',
  templateUrl: './loader-spinner.component.html',
  styleUrls: ['./loader-spinner.component.css'],
})
export class LoaderSpinnerComponent{
  @Input() title;
  @Input() error = false;
  @Input() loading: boolean;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe(v => {
      this.loading = v;
    });
  }

}

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
  declarations: [LoaderSpinnerComponent],
  exports: [LoaderSpinnerComponent],
})
export class LoaderSpinnerModule {}
