import { Pipe, PipeTransform } from '@angular/core';
import { Municipio } from 'src/app/models';

type SortingType = 'asc' | 'desc' | 'defect' | 'firstMunicipalitiesSelectedAndAsc';

@Pipe({
    name: 'sortListMunicipalities'
})
export class SortListMunicipalitiesPipe implements PipeTransform {

    transform( 
        municipalities: Municipio[], 
        selectedMunicipalities: number[] = [], 
        sortingType: SortingType = 'defect' 
    ): Municipio[] {

        if (!municipalities || municipalities.length === 0 || sortingType === 'defect') {
            return municipalities;
        }

        // Funcion que se reutiliza para ordenar
        const compareByName = (a: Municipio, b: Municipio) => a.Nombre.toLowerCase().localeCompare(b.Nombre.toLowerCase());

        let sortedMunicipalities: Municipio[] = [];

        switch (sortingType) {
            case 'asc':
                sortedMunicipalities = municipalities.slice().sort(compareByName);
                break;

            case 'desc':
                sortedMunicipalities = municipalities.slice().sort((a, b) => compareByName(b, a));
                break;

            case 'firstMunicipalitiesSelectedAndAsc':
                const selected = municipalities.filter(mun => selectedMunicipalities.includes(mun.IdMunicipio)).sort(compareByName);
                const rest = municipalities.filter(mun => !selectedMunicipalities.includes(mun.IdMunicipio)).sort(compareByName);
                sortedMunicipalities = [...selected, ...rest];
                break;

            default:
                sortedMunicipalities = municipalities;
        }

        return sortedMunicipalities;
    }
}
