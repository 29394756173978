import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-frequently-questions',
  templateUrl: './frequently-questions.component.html',
  styleUrls: ['./frequently-questions.component.scss']
})
export class FrequentlyQuestionsComponent {

  userIsLogged = this.msalService.instance.getActiveAccount() !== null;

  constructor(
    private msalService: MsalService
  ) {}

  redirecTo = ( url: string ) => window.open( url );

}
