import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DatabaseService } from "./servicios/database/database.service";
import { DrawService } from "./servicios/map/draw.service";
import { UpdateService } from "./servicios/update/update.service";
import { MessageService } from "src/app/servicios/message/message.service";
import { BnNgIdleService } from "bn-ng-idle";
import { FsessionService } from "./servicios/session/fsession.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage } from "@azure/msal-browser";
import { filter, map, tap } from "rxjs/operators";
import * as CryptoJS from 'crypto-js';
import { configuracion } from 'src/configuracion';
import { MenuHeader } from "./component/header/header.component";
import { ErrorsB2C } from "./models";

import { MAT_DATE_LOCALE } from "saturn-datepicker";
import * as moment from 'moment';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => translateService.currentLang
    }
  ]
})
export class AppComponent implements OnInit {
  title = "plantilla";
  keytranslate: string[] = ['error_empty_email'];
  variables: any;
  // private readonly _destroying$ = new Subject<void>();

  constructor(
    private db: DatabaseService,
    private bnIdle: BnNgIdleService,
    private router: Router,
    private fse: FsessionService,
    private messageService: MessageService,
    private draw: DrawService,
    private translate: TranslateService,
    private updateService: UpdateService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {
    this.restorePassAndChangesProfile();
    this.translate.get(this.keytranslate).subscribe(variables=> this.variables = variables); 

    this.translate.onLangChange.subscribe((event) => {
      this.fse.agregarActualizar('language', event.lang);
      moment.locale(event.lang);
    });
  }
  
  ngOnInit() {

    /** 
     * Valida si un usuario tiene la sesión de azure inciada y esta intentado ir a la ruta "auth#state"  
     * Alli no deberia ir ya que tiene la sesión abierta por ende llamamos el metodo "setRoutes" para establecer las rutas
    */
     if ( this.msalService?.instance?.getAllAccounts().length > 0 
          && window.location.href.includes('auth#state') 
          && this.router.url === '/' ) 
    {
      this.setRoutes();
    }

    this.updateService.checkUpdates();
    this.draw.colorcarVariables();
    this.bnIdle
      .startWatching(environment.time_inactivity_ms)
      .subscribe((res) => {
        // Cierra session por tiempo de inactividad
        if (res && this.fse.optenerValor("dato1") != false) {
          this.translate.get("inactividad").subscribe((message) => {
            this.messageService.SwalMessage("error", message).then(() => {
              this.db.Logout(this.fse.optenerValor("dato1")).subscribe(() => {
                this.fse.closeSession();
                this.router.navigate(["login"]);
              });
            });
          });
        }
      });
      this.getData();
  }

  /**
   * Obtener datos del usuario
   */
  getData(){
    this.msalBroadcastService.msalSubject$
      .pipe(
        tap((eventMessage: EventMessage)=>{

          if( eventMessage.eventType == "msal:loginFailure" ){
            let error = eventMessage.error.message.split(' ');
            let errorB2c = error[1].toString().slice(0, -1);
            this.fse.agregarActualizar('errorB2C', errorB2c);
          } else {
            if ( eventMessage.error !== null ) {
              const errorB2c = eventMessage.error.message.split(' ')[1].toString().slice(0, -1);
              if ( errorB2c === ErrorsB2C["B2C-User-Email-Invalid"] ) this.fse.agregarActualizar('errorB2C', errorB2c);
            }
          }

        }),
        filter(
          (eventMessage: EventMessage) =>
            eventMessage.eventType === "msal:loginSuccess"
        ),
        map((eventMessage: EventMessage) => eventMessage.payload)
      )
      .subscribe({
        next: (response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
          let {  extension_fvru, extension_lang, extension_fviu, extension_fvpu, extension_fvmod } = response.idTokenClaims as any

          var rol = CryptoJS.AES.decrypt(extension_fvru, configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);
          var permiso = CryptoJS.AES.decrypt(extension_fvpu, configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);
          var modulos = CryptoJS.AES.decrypt(extension_fvmod, configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);
          var id = CryptoJS.AES.decrypt(extension_fviu, configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);

          this.fse.agregarActualizar('paquetes', modulos.toString());
          this.fse.agregarActualizar('dato46', extension_fviu);
          this.fse.agregarActualizar('dato47', extension_fvru);
          this.fse.agregarActualizar('idusuario', id);
          this.fse.agregarActualizar('dato40', rol);
          this.fse.agregarActualizar('dato43', permiso);
          
          if (extension_lang) {
            this.fse.agregarActualizar('language', extension_lang);
          }
          
          if (response.account.idTokenClaims.emails && response.account.idTokenClaims.emails[0]) {
            this.fse.agregarActualizar('dato48', response.account.idTokenClaims.emails[0].toString());
          } else {
            this.messageService.SwalMessage('error', this.variables.error_empty_email);
            this.msalService.logout();
          }          
          this.setRoutes();
        },
        error: () => {
        },
      });
  }

  /**
   * Validar rutas
   */
  setRoutes(){
    const headers: MenuHeader[] = [
      { routerLink: 'traffic', type: 1, name: 'Trafico', permission: this.fse.optenerValor('paquetes') },
      { routerLink: 'management', type: 2, name: 'Gestion', permission: this.fse.optenerValor('paquetes') },
      { routerLink: 'telemetry', type: 4, name: 'Telemetría', permission: this.fse.optenerValor('paquetes')},
      { routerLink: 'solar-charger', type: 5,  name: 'telemetria-cargador-solar', permission: this.fse.optenerValor('paquetes') },
      { routerLink: 'weather', type: 6, name: 'Clima', permission: this.fse.optenerValor('paquetes')},
    ];

    const firstModule = headers.find((res)=>{
      let permission = res.permission;
      (permission as any) = permission.toString().split(',').includes(res.type.toString());
      return (permission as any) == true;
    });

    this.fse.agregarActualizar('defaulRoute', firstModule.routerLink);
    this.router.navigate([firstModule.routerLink]);
  }

  /**
   * Funcion para validar si se realizo un restaurar contraseña o un cambio en los datos de perfil
   */
  restorePassAndChangesProfile(){
    this.msalService.handleRedirectObservable().subscribe({
      next: (result) => {
        // Verificar si se existes las variables
        if(result){
          if(result.account.idTokenClaims){
            const res = result.account.idTokenClaims;
            const fviu = res.extension_fviu;
            const fvmod = res.extension_fvmod;
            const fvpu = res.extension_fvpu;
            const fvru = res.extension_fvru;
            const fvuser = res.extension_fvuser;
  
            if(!fviu || !fvmod || !fvpu || !fvru || !fvuser){
              this.fse.agregarActualizar('restorePass', 'true');
              this.msalService.logout();
              this.router.navigate(['/'], { replaceUrl: true });
            }
          }
        }
      }
    });
  }

}

